
const styles = theme => ({
  btn: {
    display: "inline-block",
    '&:hover': {
      transition: theme.transitions.create('', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen * 2,
      }),
    },
  },
  date: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    fontSize: '.8rem',
    fontFamily: "Roboto, Oswald",
  },
  alignBtn: {
    textAlign: 'center',
    '& a': {
      display: 'inline-block',
      width: '100%',
      maxWidth: '290px',
      '& >div':{
        '& >div': {
          '& img': {
            height: 'auto',
            objectFit: 'contain',
            width: '100%',
          }
        }
      }
    }
  }
});

export default styles;
