import React  from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { S3Image } from "aws-amplify-react";
import classNames from "classnames";

import styles from "./cardStyle-jss";
import { getUrlImage } from "../../service/s3Amplify";

const validateUrl = value =>
  value && /^(ftp|http|https):\/\/[^ "]+$/.test(value) ? "Invalid" : undefined;

class NewsCard extends React.Component {
  state = {
    url: ""
  };

  componentWillReceiveProps(newProps) {
    if (!validateUrl(newProps.image)) {
      getUrlImage(newProps.image).then(response => {
        this.setState({
          url: response
        });
      });
    } else {
      this.setState({
        url: newProps.image
      });
    }
  }

  getColorTitle(secColor){
    if(secColor&& (secColor.includes("#")&&secColor.toLowerCase()!="#ffffff")){
      return "#FFFFFF"
    }
  }

 getColorButton(secColor){
    if(secColor.includes("#")){
      return secColor
    }else{
      return "#FFFFFF"
    }
  }

 getColorDivider(secColor){
    if(secColor&& (secColor.includes("#")&&secColor.toLowerCase()!="#ffffff")){
      return '#ffffff'
    }
  }

  render() {
    const {
      classes,
      title,
      description,
      notes,
      image,
      attachment,
      busqueda,
      publish_date,
      horizontal,
      kicker,
      colorTitle,
      sendEvent,
      contentDivider,
      noDivider
    } = this.props;
    const namekicker = kicker && kicker.split(",")[0];
    const colorkicker = kicker && kicker.split(",")[1];
    return (
      <Card
        className={classNames(
          classes.card,
          classes.cardMedia,
          classes.h100,
          busqueda && classes.busqueda,
          horizontal && classes.horizontal
        )}
      >
        {image && (
          <div className={classes.mediaCardHorizontal}>
            <S3Image imgKey={image} className={classes.mediaHorizontal} />
          </div>
        )}

        <div className={classNames(
          horizontal && classes.w50responsive,
          contentDivider && classes.dividerLine,
          classes.dividerLineResponsive,
          noDivider && classes.dividerLineHidden,
          )} style={{height: "100%", borderBottomColor: this.getColorDivider(colorTitle)}}>
          <CardContent className={classNames(classes.contenidoMedia, classes.pushBtn)}>
            <div>
              {publish_date && (
                <Typography component="span" className={classes.datePicker} style={{color:this.getColorTitle(colorTitle)}}>
                  {publish_date}
                </Typography>
              )}
              <Typography
                className={classes.kicker}
                style={{ color: colorkicker }}
              >
                <i className={classNames(!namekicker && classes.transparentText)}>{namekicker ? namekicker : 'kicker'}</i>
              </Typography>
              <Typography className={classNames(classes.titleWrap2)} style={{color:this.getColorTitle(colorTitle)}}>
                {title}
              </Typography>
              <Typography component="p" className={classes.descriptionWrap8}>
                <span className={classes.textDescriptionWrap8} style={{color: colorTitle&&colorTitle.includes("#")&&colorTitle.toLowerCase()!="#ffffff"?"white":""}}
                  dangerouslySetInnerHTML={{ __html: `${description}` }}
                />
              </Typography>
            </div>

            <div>
              <Button
                onClick={sendEvent}
                href={`/multimedia/${encodeURIComponent(attachment)}`}
                target="_blank"
                className={classNames(classes.colorPrimary, classes.button, classes.mt1)}
                style={{backgroundColor:this.getColorTitle(colorTitle), color:this.getColorButton(colorTitle),}}
              >
                {notes} {notes > 1 ? "notas" : "nota"}
              </Button>
            </div>
          </CardContent>
        </div>
      </Card>
    );
  }
}
// NewsCard.contextType = ThemeContext
export default withStyles(styles)(NewsCard);
