import bg from '../../../static/images/bg-login.jpg';
import { fade } from '@material-ui/core/styles/colorManipulator';

const appFrame = {
    position: 'relative',
    display: 'flex',
    width: '100%',
    zIndex: 1,
};

const styles = theme => ({
    root: {
        // CH 08/07/2019 Se ajusta el desfasado al dar click en las anclas
        width: '100%',
        maxHeight: '100vh',
        height: '100vh',
        marginTop: 0,
        zIndex: 1,
        overflow: 'auto',
        overflowX: 'hidden',

    },
    // rootLoading: {
    //     width: '100%',
    //     minHeight: '100%',
    //     marginTop: 0,
    //     zIndex: 1,
    //     overflow: 'auto',
    //     overflowX: 'hidden',
    // },
    appFrameInner: {
        ...appFrame,
        height: '100%',
        display: 'block'
    },
    appFrameOuter: {
        ...appFrame,
        minHeight: '100%',
    },
    content: {
        // backgroundColor: theme.palette.background.paper,
        width: '100%',
        padding: 0,
        // CH 20/12/2019 Se elimina paddingX
        // paddingRight: theme.spacing.unit,
        // paddingLeft: theme.spacing.unit,

        // CH 08/07/2019 Se ajusta el desfasado al dar click en las anclas
        // minHeight: '100%',
        overflow: '-webkit-paged-x',

        // CH 20/12/2019 Se elimina paddingX
        // [theme.breakpoints.down('lg')]: {
        //     paddingLeft: theme.spacing.unit * 1.5,
        //     paddingRight: theme.spacing.unit * 1.5,
        // },
    },
    outerContent: {
        background: `url(${bg}) no-repeat ${theme.palette.primary.main} left bottom`,
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        backgroundSize: 'cover',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '20px 0'
        },
    },
    outerEmptyContent:{
        // background: "#dcdcdc",
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
          padding: '20px 0'
        },
        background: 'none',
        '& div':{
          width: '100%',
          height: '100%'
        }
      },
      outerEmptyContentMail:{
        // background: "#dcdcdc",
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
          padding: '20px 0'
        },
        background: 'none',
        '& > div':{
          width: '100%',
          height: '100%'
        }
      },
    bgbar: {
        backgroundColor: '#fff',
        // background: `url(${bgHeader2}) no-repeat bottom`,
        backgroundSize: 'cover',
        width: '100%',
        position: 'absolute',
        height: 184,
        top: 0,
        left: 0,
        // nuevos
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        '& img': {
            paddingTop: 15,
            width: 200,
        }
    },
    mainWrap: {
        position: 'relative',
        //marginTop: theme.spacing.unit * 6,
        height: '100%',
        '& > div:first-child': {
            // paddingBottom: 30,
            willChange: 'inherit !important' // hack for floating form issue whne expaded
        }
    },
    preloader: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1000,
        background: 'transparent',
        height: 3,
    },
    materialBg: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        opacity: 0.5
    },
    contentPadding: {
        paddingLeft: 80,
    },
    hideApp: {
        display: 'none'
    },
    circularProgress: {
        position: 'absolute',
        top: 'calc(50% - 100px)',
        left: 'calc(50% - 100px)',
    },
    brand: {
        height: 54,
        display: 'flex',
        padding: '10px 10px 5px',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: 20
        },
        '& h3': {
            margin: 0,
            fontSize: 16,
            fontWeight: 500,
            paddingLeft: 10,
            color: theme.palette.common.white,
        }
    },
    brandMovil: {
        height: 139,
        display: 'flex',
        padding: '10px 10px 5px',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: 150,
        },
        '& h3': {
            margin: 0,
            fontSize: 16,
            fontWeight: 500,
            paddingLeft: 10,
            color: theme.palette.common.white
        }
    },
    btn: {},
    icon: {},
    btnPicker: {
        position: 'fixed',
        zIndex: 2,
        right: 0,
        top: 200,
        background: fade(theme.palette.background.paper, 0.8),
        borderRadius: '30px 0 0 30px',
        padding: '4px 8px 4px 4px',
        overflow: 'hidden',
        border: `1px solid ${theme.palette.grey[300]}`,
        '& $btn': {
            background: theme.palette.secondary.main,
            borderRadius: 30,
            padding: 8,
            boxShadow: theme.shadows[4],
            display: 'flex',
            alignItems: 'center',
            width: 40,
            height: 40,
            textCenter: 'cener',
            overflow: 'hidden',
            color: 'transparent',
            transition: 'all 0.3s ease',
            '& $icon': {
                color: theme.palette.background.paper,
            },
            '&:hover': {
                color: theme.palette.background.paper,
                width: 90
            }
        }
    }
});

export default styles;