import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';

import { _getDate, _todayDate } from '../../../utils/dateFormat'
import { fetchCall } from '../../../service/service_base';
import Loader from '../../../components/Loader/Loader';
import styles from '../../../../app/components/Header/SintesisMenu/sintesisMenu-jss';
import '../../../styles/normalize.scss';
import '../../../styles/layout/_base.scss';
import SliderCard from '../../../components/Sliders/SliderCard';
import SliderEditoriales from '../../../components/Sliders/SliderEditoriales';
import SliderCartones from '../../../components/Sliders/SliderCartones';
import SliderRadio from '../../../components/Sliders/SliderRadio';
import Event from '../../../components/Event/Event';
import Portadas from '../../../components/Portadas/Portadas';
import CardButton from '../../../components/CardButton/CardButton';
import Banner from '../../../components/Banner';
import SliderTap from '../../../components/Sliders/SliderTap';
import PapperBlock from '../../../components/PapperBlock/PapperBlock';
import { ThemeContext } from '../../../utils/ThemeContext';
import TapMenu from '../../../components/Header/TapMenu';
import logotipo from '../../../../static/images/logotipo.svg'

class Preview extends React.Component {
  state = {
    isLoading: true,
    sections: [],
    contentSection: [],
    date: "",
    Header: null,
    Footer: null
  }
  _content = [];
  cardButton = []
  componentDidMount() {
    var d;
    if (decodeURIComponent(this.props.match.params.date) == 0) {
      d = new Date()
      d.setHours(9, 0, 0)
    } else {
      d = new Date(decodeURIComponent(this.props.match.params.date).split('T')[0]);
      d.setHours(new Date(decodeURIComponent(this.props.match.params.date).split('T')[0]).getUTCHours() + 24);
    }
    this.setState(
      {
        date: d,
        isLoading: true
      }, () => {
        this._content = [];
        this.setState({
          isLoading: true,
          sections: [],
          contentSection: []
        }, () => {
          this.context.toggleTheme({ menu_description: decodeURIComponent(this.props.match.params.color) })
          if (this.props.match.params.id !== 0) {
            fetchCall("POST", "/synthesis/getsection", {
              menuId: this.props.match.params.id
            }).then(response => {
              this.setState(
                {
                  sections: response.data
                }, () => {
                  this._getPublishContent(this.state.sections, response.data.length, 0);
                }
              );
            }).catch(err => {
              console.log(err);
            });
          } else {
            alert("La síntesis en esta fecha no está disponible")
          }
        })

      }
    )
  }

  _getPublishContent = (section, tam, index) => {
    if (section.length === index) {
      if (this.state.contentSection.length > 0) {
        // let section = [];
        // section = this.state.sections.map(item => (
        //   {
        //     sec_icono: item.sec_icono,
        //     sec_iconoUrl: item.sec_iconoUrl,
        //     sec_id: item.sec_id,
        //     sec_name: item.sec_name,
        //     sec_orden: item.sec_orden,
        //     sec_tipo: item.sec_tipo,
        //     sec_color: item.sec_color,
        //     content: [...new Set(this.state.contentSection.filter(x => x.sec_id === item.sec_id))]
        //   }
        // ));

        this.setState({ isLoading: false })

      } else {
        this.setState({
          openNotification: true,
          messageNotification: "No se encontró una síntesis con esta fecha",
          typeNotification: 'warning',
          isLoading: false
        });
      }
    } else {
      fetchCall("POST", "/synthesis/getcontent", {
        sectionId: section[index].sec_id,
        isPublish: parseInt(this.props.match.params.ps) === 0 ? 0 : 1,
        idPublish: parseInt(this.props.match.params.ps)
      }).then(response => {
        if (response) {
          if (response.status === 200) {
            if (response.data != null) {
              response.data.map(item => {
                item.publish_date = null;
                item.cont_attachment = item.cont_attachmentKey;
                this._content.push(item);
              });
              if (response.data.length > 0 && ["Header", "Footer"].includes(section[index].sec_tipo)) {
                response.data[0].sec_color = section[index].sec_color;
                this.setState({ [section[index].sec_tipo]: response.data[0] })
              }
              this.setState({
                contentSection: this._content
              }, () => {
                this._getPublishContent(section, tam, index + 1)
              });
            }
          }
        } else {
          this._getPublishContent(section, tam, index + 1)
        }
      }).catch(err => {
        console.log("error contenido", err)
      });
    }
  }


  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            {
              this.state.Header && <div style={{ padding: "5px", backgroundColor: this.state.Header && this.state.Header.sec_color }}>
                <Hidden xsDown>
                  <img src={this.state.Header.cont_imagePath} alt="Header" className={classes.logo} />
                </Hidden>
                <Hidden smUp>
                  <img src={this.state.Header.cont_imagePath} alt="Header" className={classes.logoM} />
                </Hidden>
              </div>
            }
            {!this.state.Header && <div style={{ padding: "5px" }}>
              <Hidden xsDown>
                <img src="https://moosendimages.imgix.net/21594a2e-abf5-4317-a678-430e0f2c55c6/e74eceaae7964e5283778877927f0c2aimg001.jpg?dpr=1&fit=clip&ixjsv=2.2.4&w=200" alt="header" className={classes.logo} />
              </Hidden>
              <Hidden smUp>
                <img src="" alt="header" className={classes.logoM} />
              </Hidden>
            </div>}
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              className={classes.demo}
              alignItems="center"
              direction="row"
              justify="center"
            >
              <Grid item align="center">
                <a href="#web" className={classNames(classes.link, classes.active)} style={{ color: decodeURIComponent(this.props.match.params.color) }}>
                  {decodeURIComponent(this.props.match.params.name)}
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} align="center" style={{fontSize:14,marginBottom:10}}>
            <span>{this.state.date === 0 ? _getDate() : _getDate(this.state.date)}</span>
          </Grid>
        </Grid>
        <TapMenu state={{}} availableSection={this.state.sections} />

        {
          this.state.isLoading ? <Loader text="Cargando vista previa..." /> :
            this.state.sections.map((item, key) => {
              item.content = this.state.contentSection.filter(x => x.sec_id === item.sec_id);
              if (item.sec_tipo === "NotasEditoriales" || item.sec_tipo === "Card"|| item.sec_tipo === "EditorialCard") {
                if (item.content.length > 0) {
                  this.cardButton = [...this.cardButton, ...item.content]
                }
                if (item.sec_tipo === "Card"|| item.sec_tipo === "EditorialCard") {
                  item.content = this.cardButton;
                  item.sec_tipo = "EditorialCard";
                  this.cardButton=[]
                }
              }
              switch (item.sec_tipo) {
                case "Banner":
                  return <Banner section={item} idMenuSelected={this.props.match.params.id} key={key} isSearching />
                case "Relevante":
                  return <SliderTap section={item} idMenuSelected={this.props.match.params.id} isVisible={item.content.length > 0} key={key} isSearching />

                case "Portada":
                  return (
                    <div id={item.sec_name ? item.sec_name.toString().trim() : ""} style={{ display: item.sec_totalContent > 0 ? 'block' : 'none' }} key={key}>
                      <div style={{ backgroundColor: item.sec_color, paddingBlock: 25, }}>
                        <PapperBlock secName={item.sec_name.toString().trim()} img={item.sec_icono} isVisible={item.sec_totalContent > 0} title={item.sec_name} colorTitle={item.sec_color} colorMode>
                          <Portadas section={item} idMenuSelected={this.props.match.params.id} key={key} isSearching />
                        </PapperBlock>
                      </div>
                    </div>
                  )
                case "Notas":
                  return (
                    <div id={item.sec_name ? item.sec_name.toString().trim() : ""} style={{ display: item.content.length > 0 ? 'block' : 'none' }} key={key}>
                      <div style={{ backgroundColor: item.sec_color, paddingTop: 25 }}>
                        <PapperBlock secName={item.sec_name.toString().trim()} img={item.sec_icono} isVisible={item.content.length > 0} title={item.sec_name} colorTitle={item.sec_color} colorMode desc="">
                          <SliderCard section={item} idMenuSelected={this.props.match.params.id} isSearching />
                        </PapperBlock>
                      </div></div>
                  )
                case "NotasColumnas":
                  return (
                    <div id={item.sec_name ? item.sec_name.toString().trim() : ""} style={{ display: item.content.length > 0 ? 'block' : 'none' }} key={key}>
                      <div style={{ backgroundColor: item.sec_color, paddingTop: 25 }}>
                        <PapperBlock secName={item.sec_name.toString().trim()} img={item.sec_icono} isVisible={item.content.length > 0} title={item.sec_name} colorTitle={item.sec_color} colorMode desc="" >
                          <SliderEditoriales section={item} idMenuSelected={this.props.match.params.id} autor isSearching />
                        </PapperBlock>
                      </div></div>
                  )
                case "EditorialCard":
                  return (
                    <div id="EditorialCard" key={key}>
                      <div style={{ backgroundColor: item.sec_color, paddingTop: 25 }}>
                        <div className={classNames('container', classes.paddingContent, classes.WrapperButton)}>
                          <CardButton section={item} idMenuSelected={this.state.idMenuSelected} key={key} isSearching />
                        </div>
                      </div>
                    </div>
                  )

                case "Radio":
                  return (
                    <div id={item.sec_name ? item.sec_name.toString().trim() : ""} style={{ display: item.content.length > 0 ? 'block' : 'none' }} key={key}>
                      <div style={{ backgroundColor: item.sec_color, paddingTop: 25 }}>
                        <PapperBlock secName={item.sec_name.toString().trim()} img={item.sec_icono} isVisible={item.content.length > 0} title={item.sec_name} colorTitle={item.sec_color} colorMode desc="" >
                          <SliderRadio section={item} idMenuSelected={this.props.match.params.id} isSearching />
                        </PapperBlock>
                      </div></div>
                  )
                case "Evento":
                  return (
                    <div id={item.sec_name ? item.sec_name.toString().trim() : ""} style={{ display: item.content.length > 0 ? 'block' : 'none' }} key={key}>
                      <div style={{ backgroundColor: item.sec_color, paddingTop: 25 }}>
                        <PapperBlock secName={item.sec_name.toString().trim()} img={item.sec_icono} isVisible={item.content.length > 0} title={item.sec_name} colorTitle={item.sec_color} colorMode desc="" >
                          <Event section={item} idMenuSelected={this.props.match.params.id} isSearching />
                        </PapperBlock>
                      </div></div>
                  )
                default:
                  return ""
              }
            })
        }

        <div className={classNames("Footer", classes.mt5)}  style={{ backgroundColor: this.state.Footer && this.state.Footer.sec_color }}>
          <div className={classNames('sintesisContent',classes.bgAlpha30)}>
            <a href={`#web`}>{decodeURIComponent(this.props.match.params.name)}</a>
          </div>
          <div className={classNames("subMenu", classes.bgAlpha15)}>
            {
              this.state.sections.map((item, index) => {
                if (!["Header", "Banner", "Footer"].includes(item.sec_tipo))
                  if (parseInt(item.sec_totalContent) > 0) {
                    if (item.sec_tipo === "NotasEditoriales" || item.sec_tipo === "Card" || item.sec_tipo === "EditorialCard") {
                      item.sec_link = "EditorialCard"
                    }else{
                      item.sec_link = item.sec_name
                    }
                    return (
                      <a href={`#${item.sec_link}`} key={index.toString()}>
                        {item.sec_name}
                      </a>
                    )
                  }
                  else
                    return null
              })
            }
            {!this.state.Footer && <div className="copy">
              <img src={logotipo} alt="Televisa" title="Televisa" className="logo" />
              <div className="textCopy" style={{ color: "white" }}>
                Derechos Reservados @ Televisa S.A. de C.V. TELEVISA y el logotipo de TELEVISA son marcas registradas
              </div>
            </div>}
          </div>
          {
            this.state.Footer && <div className='customFooter' style={{ background: this.state.Footer && this.state.Footer.sec_color }}><div className="copy">
              <img src={this.state.Footer.cont_imagePath} alt="Televisa" title="Televisa" className="logo" />
              <div className="textCopy">
                <span style={{ color: this.state.Footer && (this.state.Footer.sec_color.includes("#") && this.state.Footer.sec_color.toLowerCase() != "#ffffff") ? "white" : "" }}
                  dangerouslySetInnerHTML={{ __html: `${this.state.Footer.cont_description}` }} />
              </div>
            </div></div>
          }
        </div>

      </React.Fragment>
    );
  }
}
Preview.contextType = ThemeContext;
export default withStyles(styles)(Preview);
