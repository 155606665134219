import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Input, InputLabel } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { Hub } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import ls from 'local-storage';

import PapperBlock from '../PapperBlock/PapperBlock';
import styles from './user-jss';
import { cognitoSignIn, cognitoLogOut, apiPOST } from '../../service/cognitoService';
import CustomNotification from '../Notification/CustomNotification';
import { ContentDivider } from '../Divider';
import OAuthButton from './OAuthButton';
import { cognitoCurrentUser, cognitoInfoCurrentUser, cognitoUpdateUserAttributes } from '../../service/cognitoService';
import { fetchCall, gaInit } from '../../service/service_base';
// import { Alphanumeric } from '../../validator/Regex';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import app from '../../../../package.json'

let retryInvalidPass = 1;

class LoginForm extends React.Component {

  state = {
    showPassword: false,
    email: '',
    password: '',
    openNotification: false,
    requireChangePassword: false,
    FORCE_CHANGE_PASSWORD: false,
    messageNotification: '',
    typeNotification: 'default',
    isLoading: false,
    userData: [],
    formLogin: false,
  }

  _isMounted = false;

  constructor(props) {
    super(props);
    this.onHubCapsule = this.onHubCapsule.bind(this);
    Hub.listen('auth', this);
  }

  handleFormLogin = () => {
    this.setState({ formLogin: !this.state.formLogin });
  }


  componentDidMount() {
    this.setState({ isLoading: true })
    Promise.all([cognitoCurrentUser(), cognitoInfoCurrentUser()])
      .then(response => {
        this._syncUserWithDB(response[0], response[1].attributes);
      })
      .catch(e => {
        this.setState({ isLoading: false })
        console.log("current user ", e);
      });
  }

  onHubCapsule(capsule) {
    const { channel, payload } = capsule;
    if (channel === 'auth') {
      switch (payload.event) {
        case 'signIn':
          // if(this.state.password!=`Televisa*${new Date().getFullYear()}`){
          Promise.all([cognitoCurrentUser(), cognitoInfoCurrentUser()])
            .then(response => {
              this.setState({ isLoading: true })
              this._syncUserWithDB(response[0], response[1].attributes);
              this._isMounted = true;
            })
            .catch(e => {
              console.log("current user ", e);
            });
          //  }
          break;
        case 'signIn_failure':
          console.log('not signed in');
          break;
        default:
          break;
      }
    }
  }

  _syncUserWithDB = (user, attributtes) => {
    const username = user.signInUserSession.idToken.payload["identities"] === undefined ?
      attributtes.email : `${user.username}`.replace(process.env.REACT_APP_cognito_identity_provider + "_", "");
      if(user.signInUserSession.idToken.payload["identities"] === undefined){
        if(!attributtes["custom:passwordUpdatedAt"]){
          cognitoUpdateUserAttributes(user,{'custom:passwordUpdatedAt': new Date().getTime().toString()})
        }else{
          const d = new Date(parseInt(attributtes["custom:passwordUpdatedAt"]));
          d.setHours(24 * 30);
          if (d.getTime() < new Date().getTime()) {
            this.setState({ email: username, requireChangePassword: true, isLoading: false });
              return false;
              // fetchCall("POST", "/user/resetPassword", {
              //   type: "setUserPassword",
              //   username: this.state.email.replace("@","_"),
              //   pw: btoa(this.state.password)
              // });
              // this.setState({ userData: user });
          }
        }
    }
    apiPOST("/login", {
      "username": username,
      "password": username
    }).then(response => {
      if (response.status === 200) {
        if (response.data[0].status_handler === 0) {
          /*User have permissions to access to synthesis but does not exists in BD */
          let userdata = {};
          if (user.signInUserSession.idToken.payload["identities"] === undefined) {
            cognitoLogOut();
            this.setState({
              messageNotification: "El usuario no está registrado",
              typeNotification: 'error',
              openNotification: true,
              isLoading: false
            });
          } else {
            userdata = {
              username: username,
              cred_username: username,
              cred_password: username,
              email: attributtes.email,
              profileActive: attributtes ? attributtes.profile : '',
              name: attributtes.given_name ? attributtes.given_name : `${attributtes.email}`.split("@")[0],
              lastname: attributtes.family_name ? attributtes.family_name : '',
              id_empleado: attributtes["custom:employeeid"] ? attributtes["custom:employeeid"] : ''
            }
            fetchCall("POST", "/user/add", userdata).then(response => {
              if (response.status === 200) {
                if (response.data[0].status_handler === 1) {
                  fetchCall("POST", "/report/addlogmovements", {
                    username: username,
                    action: "Nuevo Usuario",
                    description: `Se dio de alta el usuario ${username}`,
                    userId: response.data[0].user_id
                  })
                  this._initUser(response.data[0], username);
                } else {
                  this.setState({
                    messageNotification: "Ocurrió un error al realizar la solicitud de acceso. No se puede establecer conexión con los servicios, intente más tarde",
                    typeNotification: 'error',
                    openNotification: true,
                    isLoading: false
                  });
                }
              }
            }).catch(err => console.log("ADD USER ERROR", err));
          }
        } else if (response.data[0].status_handler === 1) {
          this._initUser(response.data[0], username);
        }
      }
    }).catch(err => console.log(err));
  }

  _initUser = (user, userLogin) => {
    if (user.user_status === 1) {
      if (user.availableSynthesis) {
        let parse = JSON.parse(`[${user.availableSynthesis}]`);
        if (parse.length > 0) {
          fetchCall("POST", "/report/addlogmovements", {
            username: userLogin,
            action: "Inicio de sesión",
            description: `El usuario ${`${userLogin}`.split("@")[0]} inició sesión en el portal`,
            userId: user.user_id
          })
          let somePublish = [];
          fetchCall("GET", "/synthesis/get").then(response => {
            
            if (response) {
              if (response.data) {
                response.data.forEach(
                  item => {
                    if (item.lastPublish&& item.menu_public===0&&item.menu_visible===1) {
                      somePublish.push(item)
                    }
                  }
                )
              }
            }
            if (somePublish.length > 0) {
              ls.set("session_menu", somePublish);
              gaInit(userLogin);
              ls.set("current_user", user);
              window.location.href = "/app";
            } else {
              this.setState({
                messageNotification: "No se encontraron síntesis publicadas",
                typeNotification: 'error',
                openNotification: true,
                isLoading: false
              });
            }

          }).catch(err => console.log("Menu", err));
        } else {
          this.setState({
            messageNotification: "Tu usuario no cuenta con privilegios para ver síntesis publicadas",
            typeNotification: 'error',
            openNotification: true,
            isLoading: false
          });
        }
      } else {
        this.setState({
          messageNotification: "Tu usuario no cuenta con privilegios para ver síntesis publicadas",
          typeNotification: 'error',
          openNotification: true,
          isLoading: false
        });
      }

    } else if (user.user_status === 0) {
      cognitoLogOut();
      this.setState({
        messageNotification: "Usuario no registrado, favor de contactar al administrador",
        typeNotification: 'error',
        openNotification: true,
        isLoading: false
      });
    } else {
      cognitoLogOut();
      this.setState({
        messageNotification: <span>Su usuario se encuentra bloqueado, <br></br>favor de contactar al administrador</span>,
        typeNotification: 'error',
        openNotification: true,
        isLoading: false
      });
    }
  }

  handleCloseNotification = () => {
    this.setState({ openNotification: false });
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleChange = (event, c) => {
    if (c > 0) {
      if (event.target.value.toString().split('').length <= c)
        this.setState({ [event.target.name]: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if(!this.state.email.includes("@")) {
      this.setState({ open: true, messageNotification: "Formato de correo electrónico no válido" });
      return false;
    }
    this.setState({ isLoading: true });
    cognitoSignIn(this.state.email.replace("@","_"), this.state.password).then(response => {
      if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.setState({ requireChangePassword: true, isLoading: false });
        console.log("challengeName", "NEW_PASSWORD_REQUIRED")
      }
    }).catch(err => {
      console.log(err)
      if (retryInvalidPass === 3) {
        // fetchCall("POST", "/user/resetPassword", {
        //   type: "resetPassword",
        //   username:this.state.email.replace("@","_")
        // }).then(() => {
        //   window.location.href = `/reset_password/reset?id=${this.state.email}&blocked=true`
        // })
      } if (err.code === "PasswordResetRequiredException") {
        window.location.href = `/reset_password/reset?id=${this.state.email}`
      } if (err.message === "Temporary password has expired and must be reset by an administrator.") {
              fetchCall("POST", "/user/resetPassword", {
                type: "setUserPassword",
                username: this.state.email.replace("@","_"),
                pw: btoa(this.state.password)
              });
              this.setState({
                messageNotification: 'Tu usuario fue reestablecido. Vuelve a iniciar sesión.',
                typeNotification: 'warning',
                openNotification: true,
                isLoading: false
              });
      }else {
        if (err.code === "NotAuthorizedException")
          retryInvalidPass++;
        this.setState({
          messageNotification: err.message === "User does not exist." ? "El usuario no existe" :
            err.message === `Incorrect username or password.` ? 'Usuario y/o contraseña incorrecta' :
              err.message === "User is not confirmed." ? <span>Este usuario no ha confirmado su registro, <br></br> consulta tu bandeja de correo electrónico o de clic en el siguiente enlace: <a href={`/reset_password/new?id=${this.state.email}`}>Confirmar registro</a></span> :
                'Ocurrió un error, intente más tarde',
          typeNotification: 'error',
          openNotification: true,
          isLoading: false
        });
      }
    })
  }

  handleNewPassword = () => {
    this.setState({ requireChangePassword: false })
    window.location.href = `/reset_password/updatePassword?id=${this.state.email}`
    console.log(this.state.cognitoUser)
    //this.props.handleChangePassword(this.state.cognitoUser, this.state.FORCE_CHANGE_PASSWORD)
  }

  render() {
    const {
      classes,
      pristine,
      submitting
    } = this.props;
    return (
      <div className={classes.formWrap}>

        {
          this.state.isLoading ?
            <div className={classes.centerProgress}>
              <div className={classes.centerProgress}>
                <CircularProgress style={{ color: 'white' }}
                  size={50}
                />
              </div>
            </div>
            :
            <PapperBlock whiteBg title="Iniciar sesión" desc="">
             { this.state.formLogin ? <React.Fragment>
                  <form onSubmit={this.handleSubmit}>
                    <Typography className={classes.subtitle}>
                      Accede con tu correo electrónico
                    </Typography>
                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="email">Usuario/Correo electrónico</InputLabel>
                        <Input
                          name="email"
                          id="email"
                          value={this.state.email}
                          required
                          onChange={event => this.handleChange(event, 50)}
                          type="mail"
                        />
                      </FormControl>
                    </div>
                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="password">Contraseña</InputLabel>
                        <Input
                          id="password"
                          name="password"
                          value={this.props.password}
                          type={this.state.showPassword ? 'text' : 'password'}
                          autoComplete="off"
                          required
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton aria-label="Toggle password visibility" onClick={() => this.handleClickShowPassword('current')}>
                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>

                          }
                          onChange={event => this.handleChange(event, -1)}
                        />
                      </FormControl>
                    </div>
                    <div className={classes.btnArea}>
                      <Button onClick={() => this.handleFormLogin()} variant="raised" color="primary" type="submit" size="small" className={classes.grayBtn}>
                        <ArrowBack className={classNames(classes.leftIcon, classes.iconSmall)} disabled={submitting || pristine} />
                        Regresar
                      </Button>
                      <Button variant="raised" color="primary" type="submit" size="small" className={classes.blueBtn}>
                        Continuar
                          <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine} />
                      </Button>
                    </div>
                  </form>
                  <Typography className={classes.help}>
                    Si tienes problemas para ingresar comunícate al CAT extensión 12345
                  </Typography>
                  <div className={classes.footer}>
                    <Button variant="raised"  style={{ width: '90%' }} className={classes.grayBtn}
                      href={`/reset_password/reset`}
                    >OLVIDÉ MI CONTRASEÑA</Button>
                  </div>
                  <center><Typography className={classes.version}>v{app.version}</Typography></center>
              </React.Fragment> : <div className={classes.wrapperLogins}>
                  <Typography className={classes.subtitleNoP}>
                    Si tu correo es <strong>@televisa.com.mx</strong>
                  </Typography>
                  <OAuthButton /> 
                  
                  {/* Directorio Activo */}
                  <ContentDivider content="O" />

                  <Typography className={classes.subtitleNoP}>
                    Si tu correo es de <strong>otro dominio</strong>
                  </Typography>
                  
                  <div className={classes.footer}>
                  <Button onClick={() => this.handleFormLogin()} variant="raised" color="secondary" type="submit" style={{ width: '80%' }} className={classes.blueBtn}>
                    Ingresar Aqui
                    <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine} />
                  </Button>
                  </div>
                </div>}
            </PapperBlock>
        }
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>

        {/* Modal */}

        <ConfirmDialog
          title={"Actualizar contraseña"}
          message={"Tu contraseña ha caducado, es necesario que la actualices"}
          textAccept={"Actualizar"}
          textCancel={"Cancelar"}
          funcAccept={this.handleNewPassword}
          funcCancel={() => this.setState({ requireChangePassword: false })}
          open={this.state.requireChangePassword}
        />

      </div>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoginForm);
