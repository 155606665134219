import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '../../../utils/brand';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './../../../components/Forms/user-jss';
import PapperBlock from '../../../components/PapperBlock/PapperBlock';
import FormControl from '@material-ui/core/FormControl';
import { Input, InputLabel } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import classNames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../components/Notification/CustomNotification';
import {  Password } from '../../../validator/Regex';
import { cognitoConfirmSignUp, cognitoForgotPassword, cognitoForgotPasswordSubmit, cognitoSignIn, cognitoChangePassword, cognitoResendSignUp,cognitoCurrentUser,cognitoUpdateUserAttributes,cognitoInfoCurrentUser } from '../../../service/cognitoService';
import { fetchCall } from '../../../service/service_base';

class ResetPassword extends React.Component {

  state = {
    email: "",
    showPassword: false,
    code: "",
    password: "",
    confirmPassword: "",
    oldPassword: "",
    showconfirmPassword: "",
    showOldPassword: "",
    requireChangePassword: false,
    open: false,
    messageNotification: "",
    param: "reset",
    title: "Restablecer contraseña",
    description: "Ingresa tu cuenta de correo te enviaremos un código",
    cognitoUser: {}
  }

  componentDidMount() {
    try {
      const param = this.props.match.params.param;
      this.setState({
        email: new URLSearchParams(window.location.search).get("id"),
        param,
        requireChangePassword: (param === "reset" && new URLSearchParams(window.location.search).get("id")) || param === "updatePassword" || param === "new",
        title: param === "new" ? "Confirmación de usuario" : param === "reset"?"Restablecer contraseña": "Actualizar contraseña",
        description: param === "new" ? "Ingresa el código que llegó a tu correo electrónico" : 
        (param === "reset" && new URLSearchParams(window.location.search).get("id"))? "Ingresa el código de verificación para la nueva contraseña" : 
        param === "reset" ? "Ingresa tu cuenta de correo te enviaremos un código" : 
        ""
      })
      if(new URLSearchParams(window.location.search).get("blocked")){
        this.setState({
          messageNotification: 'El usuario se ha bloqueado. Se envió un código a tu correo electrónico para restaurar la contraseña',
          typeNotification: 'error',
          open: true,
        });
      }
    } catch (e) {

    }
  }

  resendCode = () => {
    switch (this.state.param) {
      case 'new':
        cognitoResendSignUp(this.state.email.replace("@","_")).then(user => {
          this.setState({ open: true, messageNotification: `Se ha enviado una notificación al correo electrónico <${this.state.email}> con el nuevo código` })
        }).catch(e => {
          console.log(e);
          if (e.code === "UserNotFoundException") {
            this.setState({ open: true, messageNotification: "El correo capturado no se encuentra registrado en el sistema, favor de validar" })
          } else
            this.setState({ open: true, messageNotification: "Ocurrió un error al realizar la solicitud" })
        });
        break;
      case 'reset':
        this.handleSubmitEmail();
        break;
      default: return "Parámetro no identificado"
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.password.length < 8) {
      this.setState({ open: true, messageNotification: "Contraseña incorrecta, mínimo 8 caracteres con combinación de minúsculas, mayúsculas, números y caracteres especiales" });
      return false;
    }
    if (this.state.password === this.state.confirmPassword) {
      switch (this.state.param) {
        case 'reset':
            cognitoForgotPasswordSubmit(this.state.email.replace("@","_"), this.state.code, this.state.password).then(user => {
              fetchCall("POST", "/user/resetPassword", {
                type: "passwordUpdatedAt",
                username: this.state.email.replace("@","_")
              }).then(e=>window.location.href = "/");
              
            }).catch(e => {
              if (e.code === "CodeMismatchException") {
                this.setState({ open: true, messageNotification: "El código no es válido, vuelva a intentarlo" });
              } else if (e.code === "ExpiredCodeException") {
                this.setState({ open: true, messageNotification: <span>El código ya no es válido. Para generar uno nuevo haga clic en: <a href="#resendcode" onClick={this.resendCode}>Reenviar código</a></span> });
              } else if (e.code === "InvalidPasswordException") {
                this.setState({ open: true, messageNotification: "Contraseña incorrecta, mínimo 8 caracteres con combinación de minúsculas, mayúsculas, números y caracteres especiales" });
              } else {
                this.setState({ open: true, messageNotification: "Ocurrió un error al realizar la solicitud" });
              }
              console.log(e);
            });
          break;
        case 'updatePassword':
          cognitoCurrentUser()
          .then(res=>{
            cognitoChangePassword(res,this.state.oldPassword,this.state.password).then(user => {
              cognitoUpdateUserAttributes(res,{'custom:passwordUpdatedAt': new Date().getTime().toString()})
              .then(e=>{
                window.location.href = "/"
                // cognitoInfoCurrentUser()
                // .then(newres=>console.log("newres",newres))
              })
            }).catch(e => {
              if (e.code === "InvalidPasswordException") {
                this.setState({ open: true, messageNotification: "Contraseña incorrecta, mínimo 8 caracteres con combinación de minúsculas, mayúsculas, números y caracteres especiales" });
              } else if (e.message === `Incorrect username or password.`){
                this.setState({ open: true, messageNotification: "Contraseña anterior incorrecta" });
              }else{
                this.setState({ open: true, messageNotification: "Ocurrió un error al realizar la solicitud" });
              }
              console.log(e);
            });
          }).catch(err=>console.log("not authenticated",err))
          break;
        case 'new':
          if (!Password(this.state.password)) {
            this.setState({ open: true, messageNotification: "Contraseña incorrecta, mínimo 8 caracteres con combinación de minúsculas, mayúsculas, números y caracteres especiales" });
            return false;
          }
          cognitoConfirmSignUp(this.state.email.replace("@","_"), this.state.code).then(() => {
            cognitoSignIn(this.state.email.replace("@","_"), `7313vI54*${new Date().getFullYear()}`).then(response => {
              cognitoChangePassword(response, `7313vI54*${new Date().getFullYear()}`, this.state.password).then(() => {
                window.location.href = "/";
              }).catch(e => {
                if (e.code === "InvalidPasswordException") {
                  this.setState({ open: true, messageNotification: "Contraseña incorrecta, mínimo 8 caracteres con combinación de minúsculas, mayúsculas, números y caracteres especiales" });
                } else {
                  this.setState({ open: true, messageNotification: "Ocurrió un error al realizar la solicitud" });
                }
                console.log(e);
              });
            }).catch(err => {
              console.log(err)
            })
          }).catch(e => {
            if (e.code === "CodeMismatchException") {
              this.setState({ open: true, messageNotification: "El código no es válido, vuelva a intentarlo" });
            } else if (e.code === "ExpiredCodeException") {
              this.setState({ open: true, messageNotification: <span>El código ya no es válido. Para generar uno nuevo haga clic en: <a href="#resendcode" onClick={this.resendCode}>Confirmar registro</a></span> });
            } else {
              this.setState({ open: true, messageNotification: "Ocurrió un error al realizar la solicitud" });
            }
            console.log(e)
          }); break;
        default: return "Parámetro no identificado"
      }
      // window.location.href = "/";
    } else {
      this.setState({ open: true, messageNotification: "La confirmación de contraseña no coincide" });
    }

  }

  handleSubmitEmail = (event) => {
    event.preventDefault();
    if (this.state.email.includes("@")) {//Validate(this.state.email)
      cognitoForgotPassword(this.state.email.replace("@","_")).then(user => {
        console.log(user)
        this.setState({ requireChangePassword: true })
      }).catch(e => {
        console.log(e);
        if (e.code === "UserNotFoundException") {
          this.setState({ open: true, messageNotification: "Este usuario/correo electrónico no se encuentra registrado" })
        } else
          this.setState({ open: true, messageNotification: "Ocurrió un error al realizar la solicitud" })
      });
    } else {
      this.setState({ open: true, messageNotification: "Formato de correo electrónico no válido" })
    }
  }

  handleChange = (event, c) => {
    if (c > 0) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  handleShowPassword = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  render() {
    const title = brand.name + ' - ' + this.state.title;
    const description = brand.desc;
    const { classes, pristine,
      submitting } = this.props;
    return (
      <div className={classes.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <div className={classes.container}>
          <Grid container spacing={24} alignItems="center" direction="row" justify="center">
            <Grid item xl={4} lg={5} md={6} xs={11}>
              <PapperBlock whiteBg title={this.state.title}
                desc={this.state.description}>
                {this.state.requireChangePassword ?
                  <form onSubmit={this.handleSubmit}>
                    {["reset","new"].includes(this.state.param)&&<div>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="code">Código</InputLabel>
                        <Input
                          name="code"
                          id="code"
                          value={this.state.code}
                          required
                          onChange={event => /^[0-9\b]*$/.test(event.target.value) ? this.handleChange(event, 6) : null}
                          inputProps={{
                            maxLength: 6
                          }}
                        />
                      </FormControl>
                    </div>}
                    {this.state.param==="updatePassword"&&<FormControl className={classes.formControl}>
                        <InputLabel htmlFor="oldPassword">Contraseña</InputLabel>
                        <Input
                          id="oldPassword"
                          name="oldPassword"
                          value={this.state.oldPassword}
                          type={this.state.showOldPassword ? 'text' : 'password'}
                          autoComplete="off"
                          required
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton aria-label="Toggle password visibility" onClick={() => this.handleShowPassword('showOldPassword')}>
                                {this.state.showOldPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>

                          }
                          onChange={event => this.handleChange(event, -1)}
                        />
                      </FormControl>
                      }
                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="password">Nueva contraseña</InputLabel>
                        <Input
                          id="password"
                          name="password"
                          value={this.state.password}
                          type={this.state.showPassword ? 'text' : 'password'}
                          autoComplete="off"
                          required
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton aria-label="Toggle password visibility" onClick={() => this.handleShowPassword('showPassword')}>
                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>

                          }
                          onChange={event => this.handleChange(event, -1)}
                        />
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="confirmPassword">Confirma contraseña</InputLabel>
                        <Input
                          id="confirmPassword"
                          name="confirmPassword"
                          value={this.state.confirmPassword}
                          type={this.state.showconfirmPassword ? 'text' : 'password'}
                          autoComplete="off"
                          required
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton aria-label="Toggle password visibility" onClick={() => this.handleShowPassword('showconfirmPassword')}>
                                {this.state.showconfirmPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>

                          }
                          onChange={event => this.handleChange(event, -1)}
                        />
                      </FormControl>
                    </div>
                    <div className={classes.btnArea}>
                      <Button variant="raised" color="secondary" type="submit">
                        Continuar
                      <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine} />
                      </Button>
                    </div>
                  </form>
                  : <form onSubmit={this.handleSubmitEmail}>
                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="email">Correo electrónico*</InputLabel>
                        <Input
                          name="email"
                          id="email"
                          value={this.state.email}
                          required
                          onChange={event => this.handleChange(event, 50)}
                          type="mail"
                        />
                      </FormControl>
                    </div>
                    <div className={classes.btnArea}>
                      <Button variant="raised" color="secondary" type="submit">
                        MANDAR CÓDIGO
                      <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine} />
                      </Button>
                    </div>
                  </form>}
              </PapperBlock>
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={this.state.open}
                autoHideDuration={6000}>
                <CustomNotification
                  onClose={() => this.setState({ open: false })}
                  variant="error"
                  message={this.state.messageNotification} />
              </Snackbar>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResetPassword);
