import React from 'react';
import classNames from 'classnames';
import { renderRoutes } from 'react-router-config'; 
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import styles from './appStyles-jss';
import './estilos.css'

class ViewWeb extends React.Component {
  state = {
    pageLoaded: false,
  };
  componentDidMount(){
    // Scroll content to top
    const mainContent = document.getElementById('mainContent');
    this.loadTransition(true);

    // Execute all arguments when page changes
    this.unlisten = this.props.history.listen(() => {
      mainContent.scrollTo(0, 0);
      setTimeout(() => {
        this.loadTransition(true);
      }, 500);
    });
  }

  loadTransition = () => {
    this.setState({pageLoaded : true}); 
  }

  render() {
    const {
      classes,
      route,
    } = this.props;
    const { pageLoaded} = this.state;   
    return (
      <React.Fragment>
        <main className={classNames(classes.content)} id="mainContent">
          <section className={classes.mainWrap}>
          {/* <div>
              
          </div> */}
             <Fade
              in={pageLoaded}
              mountOnEnter
              unmountOnExit
              {...(pageLoaded ? { timeout: 700 } : {})}
            >
              <div className={!pageLoaded ? classes.hideApp : ''}>
                {renderRoutes(route.routes)}
              </div>
            </Fade>
          </section>
        </main>
      </React.Fragment>
    );
  }
}
 
export default (withStyles(styles)(ViewWeb));
