import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_ga_tracking_id);
const service = process.env.REACT_APP_local==1?process.env.REACT_APP_aws_apigateway_endpoint:
window.location.origin + process.env.REACT_APP_aws_apigateway_behavior;

export const fetchCall = (method,api, data, avoidRefresh) => {
    return new Promise(
        function (resolve, reject) {
            var myHeaders = new Headers();

            var myInit = {
                method: method,
                headers: myHeaders,
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)
            };

            var myRequest = new Request(service + api, myInit);
            if(!avoidRefresh)
                localStorage.setItem("last_activity", new Date().getTime());
            
            fetch(myRequest)
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson);
                }).catch((error) => {
                    reject(error);
                });
        }
    );
}

export const gaInit=(username)=>{
    let dateexp = new Date();  
    dateexp.setFullYear(dateexp.getFullYear()+2); 
    ReactGA.set({clientId: username});
    ReactGA.set({ userId: username });
    document.cookie =`_ga=GA1.1.${username}; expires=${dateexp}; path=/`;
    console.log(`Google Analytics user ${username} expire ${dateexp}`)
}

export const gaPageHit =(page)=>{ 
    ReactGA.set({ title: page });
    ReactGA.set({ dimension1: "WEB" });
    ReactGA.pageview(`/${page}`);
}

export const gaEventHit = (category, action, label) => {
    ReactGA.set({ title: category });
    ReactGA.set({ dimension1: "WEB" });
    ReactGA.event({
      category: category,
      action: action
    });
  };