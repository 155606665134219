
import App from '../components/App/App';
import Dashboard from '../components/App/Dashboard';
import Outer from '../components/App/Outer';
import ViewMultimedia from '../components/App/ViewMultimedia';
import Viewemail from '../components/App/Viewemail';
import ViewWeb from '../components/App/ViewWeb';
// Pages
import Login from './Pages/Users/Login';
import ResetPassword from './Pages/Users/ResetPassword';
import LoginDedicated from './Pages/Standalone/LoginDedicated';
import FederatedLogin from './Pages/Standalone/FederatedLogin';
import NotFound from './NotFound/NotFound';
import NotFoundDedicated from './Pages/Standalone/NotFoundDedicated';
import Multimedia from './Pages/Multimedia'; 
import PreviewHTML from './Pages/Preview/html';
import PreviewWEB from './Pages/Preview/web';
import Search from './Pages/Search'; 
import Home from './Pages/Home'; 

const routes = [{ 
  component:App,
  routes :[ {
    component: App,
    routes: [
      {
        component: LoginDedicated,
        path: '/',
        exact: true,
      },
      {
        component: Outer,
        path: '/login',
        routes: [
          {
            path: '/login',
            component: Login,
            exact: true,
          },
          {
            path: '*',
            component: NotFound
          }
        ]
      },
      {
        component: Outer,
        path: '/reset_password/:param',
        routes: [
          {
            path: '/reset_password/:param',
            component: ResetPassword,
            exact: true,
          }
        ]
      },
      {
        component: ViewMultimedia,
        path: '/multimedia/:param',
        routes: [
          {
            path: '/multimedia/:param',
            component: Multimedia,
            exact: true,
          },
          {
            path: '*',
            component: NotFoundDedicated
          }
        ]
      },
      {
        component: Viewemail,
        path: '/preview/:param',
        routes: [
          {
            path: '/preview/:param',
            component: PreviewHTML,
            exact: true,
          },
          {
            path: '*',
            component: NotFoundDedicated
          }
        ]
      },
      {
        component: ViewWeb,
        path: '/previewWeb',
        routes: [
          {
            path: '/previewWeb/:id/:date/:name/:color/:ps',
            component: PreviewWEB,
            exact: true,
          },
          {
            path: '*',
            component: NotFoundDedicated
          }
        ]
      },
      {
        component: Dashboard,
        path: '/busqueda',
        routes: [
          {
            path: '/busqueda',
            component: Search,
            exact: true,
          },
        ]
      },
      {
        component: Dashboard,
        path: '/app',
        routes: [
          {
            path: '/app',
            component: Home,
            exact: true,
          }
        ]
      },
      {
        component: ViewWeb,
        path: '/federatedLogin',
        routes: [
          {
            path: '/federatedLogin',
            component: FederatedLogin,
            exact: true,
          }
        ]
      },
      {
        path: '*',
        component: NotFoundDedicated
      }
    ]
  }
]
}];

export default routes;