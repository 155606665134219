module.exports = {
  sintesisTheme: {
    palette: {
      primary: {
        light: '#a0b9c6',
        main: '#000000',
        dark: '#113654',
        contrastText: '#fff',
      },
      secondary: {
        light: '#add2d1',
        main: '#008089',
        dark: '#006466',
        contrastText: '#fff',
      },
    },
    configuration: {
      blue: '#174a7c',
      teal: '#00808a',
      green: '#66d8d1',
      orange: '#FF5E3E',
    },
  },
  // Azul
  sintesisTheme1: {
    palette: {
      primary: {
        light: '#254d85',
        main: '#1a365d',
        // main: '#0026b3',
        dark: '#142a49',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FD9160',
        main: '#FD7F47',
        dark: '#FC5C15',
        contrastText: '#fff',
      },
    },
      typography: {
          fontFamily: [
            "Roboto",
            "Oswald",
          ],
          // fontSize: "10px"
      }
    },
    // Rojo
  sintesisTheme2: {
    palette: {
      primary: {
        light: '#e75c5c',
        main: '#e12f30',
        dark: '#c11c1d',
        contrastText: '#fff',
      },
      secondary: {
        light: '#5ca2e7',
        main: '#2f89e1',
        dark: '#1963ab',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: [
        "Roboto",
        "Oswald",
      ],
      // fontSize: "10px"
  }
  },
  // Naranja
  sintesisTheme3: {
    palette: {
      primary: {
        light: '#ff8165',
        main: '#FF5E3E',
        dark: '#ff4219',
        contrastText: '#fff',
      },
      secondary: {
        light: '#3167b3',
        main: '#254d85',
        dark: '#1a365d',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: [
        "Roboto",
        "Oswald",
      ],
      // fontSize: "10px"
  }
  },
  // Verde
  sintesisTheme4: {
    palette: {
      primary: {
        light: '#1eb86f',
        main: '#1aa262',
        dark: '#137647',
        contrastText: '#fff',
      },
      secondary: {
        light: '#e7b723',
        main: '#e7ae00',
        dark: '#cc9a00',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: [
        "Roboto",
        "Oswald",
      ],
      // fontSize: "10px"
  }
  },
  // Morado
  sintesisTheme5: {
    palette: {
      primary: {
        light: '#9176b4',
        main: '#7757A0',
        dark: '#5e457f',
        contrastText: '#fff',
      },
      secondary: {
        light: '#99b476',
        main: '#80a057',
        dark: '#667f45',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: [
        "Roboto",
        "Oswald",
      ],
      // fontSize: "10px"
  }
  },
  // Gris
  sintesisTheme6: {
    palette: {
      primary: {
        light: '#a0a0a0',
        main: '#8c8c8c',
        dark: '#6e6e6e',
        contrastText: '#fff',
      },
      secondary: {
        light: '#6e6e6e',
        main: '#8c8c8c',
        dark: '#a0a0a0',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: [
        "Roboto",
        "Oswald",
      ],
      // fontSize: "10px"
  }
  },
  purpleRedTheme: {
    palette: {
      primary: {
        light: '#EDE7F6',
        main: '#673AB7',
        dark: '#512DA8',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FCE4EC',
        main: '#EC407A',
        dark: '#C2185B',
        contrastText: '#fff',
      },
    },
  },
  greenTheme: {
    palette: {
      primary: {
        light: '#F1F8E9',
        main: '#689F38',
        dark: '#33691E',
        contrastText: '#fff',
      },
      secondary: {
        light: '#FFECB3',
        main: '#FF8F00',
        dark: '#E65100',
        contrastText: '#fff',
      },
    },
  },
  magentaTheme: {
    palette: {
      primary: {
        light: '#FCE4EC',
        main: '#EC407A',
        dark: '#D81B60',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E0F7FA',
        main: '#00BCD4',
        dark: '#0097A7',
        contrastText: '#fff',
      },
    },
  },
  purpleTheme: {
    palette: {
      primary: {
        light: '#EDE7F6',
        main: '#AB47BC',
        dark: '#8E24AA',
        contrastText: '#fff',
      },
      secondary: {
        light: '#F1F8E9',
        main: '#7CB342',
        dark: '#558B2F',
        contrastText: '#fff',
      },
    },
  },
  blueTheme: {
    palette: {
      primary: {
        light: '#E8EAF6',
        main: '#3F51B5',
        dark: '#283593',
        contrastText: '#fff',
      },
      secondary: {
        light: '#B3E5FC',
        main: '#03A9F4',
        dark: '#0277BD',
        contrastText: '#fff',
      },
    },
  },
  orangeTheme: {
    palette: {
      primary: {
        light: '#FFE0B2',
        main: '#EF6C00',
        dark: '#E65100',
        contrastText: '#fff',
      },
      secondary: {
        light: '#F3E5F5',
        main: '#9C27B0',
        dark: '#7B1FA2',
        contrastText: '#fff',
      },
    },
  },
  cyanTheme: {
    palette: {
      primary: {
        light: '#E0F7FA',
        main: '#0097A7',
        dark: '#00838F',
        contrastText: '#fff',
      },
      secondary: {
        light: '#F1F8E9',
        main: '#8BC34A',
        dark: '#33691E',
        contrastText: '#fff',
      },
    },
  },
  redTheme: {
    palette: {
      primary: {
        light: '#FFEBEE',
        main: '#EF5350',
        dark: '#E53935',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ECEFF1',
        main: '#607D8B',
        dark: '#455A64',
        contrastText: '#fff',
      },
    },
  },
  skyBlueTheme: {
    palette: {
      primary: {
        light: '#E3F2FD',
        main: '#2196F3',
        dark: '#1565C0',
        contrastText: '#fff',
      },
      secondary: {
        light: '#A7FFEB',
        main: '#00BFA5',
        dark: '#00796B',
        contrastText: '#fff',
      },
    },
  },
  greyTheme: {
    palette: {
      primary: {
        light: '#ECEFF1',
        main: '#607D8B',
        dark: '#455A64',
        contrastText: '#fff',
      },
      secondary: {
        light: '#F5F5F5',
        main: '#757575',
        dark: '#424242',
        contrastText: '#fff',
      },
    },
  },
  greenNatureTheme: {
    palette: {
      primary: {
        light: '#E8F5E9',
        main: '#43A047',
        dark: '#2E7D32',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E0F2F1',
        main: '#009688',
        dark: '#00796B',
        contrastText: '#fff',
      },
    },
  },
  yellowCyanTheme: {
    palette: {
      primary: {
        light: '#FFF3E0',
        main: '#FF8F00',
        dark: '#E65100',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E0F7FA',
        main: '#00BCD4',
        dark: '#006064',
        contrastText: '#fff',
      },
    },
  }
};
