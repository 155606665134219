
// OAuthButton.js
import { withOAuth } from 'aws-amplify-react';
import React from 'react';
import ArrowForward from '@material-ui/icons/ArrowForward';
import * as uuid from 'uuid/v1';

class OAuthButton extends React.Component {
  componentWillMount() {
    console.log(uuid());
  }
  handleActiveDirectory() {
    window.location.href = 'https://' + process.env.REACT_APP_aws_cognito_domain + '/oauth2/authorize?identity_provider='+process.env.REACT_APP_cognito_identity_provider+'&redirect_uri=' + process.env.REACT_APP_aws_content_delivery_url + '&response_type=TOKEN&client_id='+process.env.REACT_APP_aws_user_pools_web_client_id+'&state=' + uuid() + '&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile';
  }
  render() {
    return (
      <div className="blockCenter">
        <button onClick={this.handleActiveDirectory} className="btnActiveDirectory">
        Ingresar Aqui
          <ArrowForward className="iconSmall" />
        </button>
      </div>
      
    
    )
  }
}

export default withOAuth(OAuthButton);