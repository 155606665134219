const styles = theme => ({
  container: {
    maxWidth: 1200,
    margin: '0 auto',
    padding: '0 8px',
    '& div[class*="pagination"]': {
      '& a[class*="active"]':{
        backgroundColor: theme.palette.primary.main,
      }
    },
    '& div[class*="DayPickerInput-OverlayWrapperXXX"]': {
      '& > div > div > div > div > div > div:not(div:first-child, div:nth-child(2))':{
        '&:hover':{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
        '& div[class*="dayXXX"]':{
          color: theme.palette.primary.main,
        },
      }
    },
  },
});

export default styles;
