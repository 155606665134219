import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./portada-jss";
import { Grid } from "@material-ui/core";
import { fetchCall,gaEventHit } from "../../service/service_base";
import { S3Image } from "aws-amplify-react";
import classNames from "classnames";

class Portadas extends React.Component {
  state = {
    portadas: [],
    menuname:null
  };
  componentDidMount() {
    if (!this.props.isSearching) {
      this._load();
    } else {
      if (this.props.section) {
        this.setState(
          {
            portadas: []
          },
          () => {
            this.setState({
              portadas: this.props.section.content
            });
          }
        );
      }
    }
  }

  _load = () => {
    fetchCall("POST", "/synthesis/getcontentpublish", {
      sectionId: this.props.section.sec_id,
      menuId: this.props.idMenuSelected
    })
      .then(response => {
        let sm = JSON.parse(localStorage.getItem("session_menu"))
        const menuname = sm.find(x => x.menu_id === this.props.idMenuSelected);
        this.setState({ portadas: response.data , menuname:menuname.menu_name});
      })
      .catch(err => console.log(err));
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isSearching && nextProps.section) {
      this.setState({
        portadas: nextProps.section.content
      });
    }
  }

  sendEvent=()=>{
    if(this.state.menuname)
      gaEventHit(this.state.menuname,this.props.section.sec_name)
  }

  render() {
    const { classes, isSearching } = this.props;
    return (
      <div
        id={this.props.section.sec_name}
        className="container"
        justify="space-bettwen"
      >
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Grid container spacing={24} justify="space-around">
              {this.state.portadas.map((item, key) => {
                if (item.cont_key)
                  item.cont_imagePath = item.cont_key;
                return (
                  <Grid item xs={12} sm={6} md={6} key={key} className={classes.alignBtn}>
                    <a onClick={this.sendEvent}
                      target="_blank" rel="noopener noreferrer"
                      className={classes.contentPort}
                      href={
                        item.publish_date
                          ? `/multimedia/${encodeURIComponent(
                            item.cont_attachment
                          )}`
                          : `/multimedia/${encodeURIComponent(
                            item.cont_attachmentKey
                          )}`
                      }
                    >
                      <S3Image
                        imgKey={
                          isSearching ? item.cont_imagePath : item.cont_key
                        }
                        className={classNames(classes.btn, classes.styleImgBtn)}
                      />
                    </a>
                    {item.publish_date && (
                      <small className={classes.date}>
                        {item.publish_date}
                      </small>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Portadas);
