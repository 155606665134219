const styles = theme => ({
    item: {
      textAlign: 'left',
      '& img': {
        margin: '10px auto'
      }
    },
    itemPortada: {
        display: 'flex !important',
        justifyContent: 'center !important',
    },
    color1: {
        color: theme.palette.primary.main,
    },
    bgColor1: {
      backgroundColor: theme.palette.primary.main,
      '&:hover':{
          backgroundColor: theme.palette.primary.dark
      }
    },
    titleCartoon: {
      fontSize: 16,
      height: 18,
      overflow: 'hidden',
      color: theme.palette.primary.main,
      fontWeight: 500,
      marginBottom: 5,
    },
    titleRadio: {
        fontSize: 16,
        overflow: 'hidden',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        color: '#000',
        fontWeight: 500,
      },
    title: {
      fontSize: 16,
      minHeight: 48,
      overflow: 'hidden',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical',
      color: '#000',
      fontWeight: 500,
      marginBottom: 5,
    },
    description: {
      color: '#000',
      minHeight: 82,
      '& div span div':{
        color: theme.palette.primary.main,
      }
    },
    descriptionSmall: {
      color: '#000',
      overflow: 'hidden',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': '3',
      '-webkit-box-orient': 'vertical',
      '& div span div':{
        color: theme.palette.primary.main,
      }
    },
    loadPortadas: {
      backgroundColor: '#d8d7d5',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: 200,
      textAlign: 'center',
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      '& > div': {
        position: "initial"
      }
    }
  }); 

  export default styles;