import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '../../../utils/brand';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './../../../components/Forms/user-jss';
import PapperBlock from '../../../components/PapperBlock/PapperBlock';
import FormControl from '@material-ui/core/FormControl';
import { Input, InputLabel } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import classNames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../components/Notification/CustomNotification';
//import { Password } from '../../../validator/Regex';
import { fetchCall } from '../../../service/service_base';
import { cognitoCompletePassword, cognitoChangePassword } from '../../../service/cognitoService';

class ResetPassword extends React.Component {

  state = {
    showPassword: false,
    password: "",
    confirmPassword: "",
    showconfirmPassword: false,
    open: false,
    messageNotification: "",
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ open: true, messageNotification: "La confirmación de contraseña no coincide" });
    } else if(this.props.force){
      cognitoChangePassword(this.props.user, `7313vI54*${new Date().getFullYear()}`, this.state.password).then(user => {
        window.location.reload();
      }).catch(e => {
        if (e.code === "InvalidPasswordException") {
          this.setState({ open: true, messageNotification: "Contraseña incorrecta, mínimo 8 caracteres con combinación de minúsculas, mayúsculas, números y caracteres especiales" });
        } else {
          this.setState({ open: true, messageNotification: "Ocurrió un error al realizar la solicitud" });
        }
        console.log(e);
      });
    }else {
      cognitoCompletePassword(this.props.user, this.state.password, {profile:"Externo"}).then(user => {
        // const email = `${this.props.user.username}`.split("@");
        fetchCall("POST", "/user/resetPassword", {
          type: "passwordUpdatedAt",
          username:this.props.user.username
        });
        window.location.reload();
      }).catch(e => {
        if (e.code === "InvalidPasswordException") {
          this.setState({ open: true, messageNotification: "Contraseña incorrecta, mínimo 8 caracteres con combinación de minúsculas, mayúsculas, números y caracteres especiales" });
        } else {
          this.setState({ open: true, messageNotification: "Ocurrió un error al realizar la solicitud" });
        }
        console.log(e);
      });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleShowPassword = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  render() {
    const title = brand.name + ' - Actualizar Contraseña';
    const description = brand.desc;
    const { classes, pristine,
      submitting } = this.props;
    return (
      <div className={classes.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <div className={classes.container}>
          <Grid container spacing={24} alignItems="center" direction="row" justify="center">
            <Grid item xl={4} lg={5} md={6} xs={11}>
              <PapperBlock whiteBg title="Actualizar Contraseña" desc="">
                <form onSubmit={this.handleSubmit}>
                  {
                    // this.state.cognitoUser.challengeParam.requiredAttributes.map((item, index) => {
                    //   return (
                    //     <div key={index}>
                    //       <FormControl className={classes.formControl} required={true}>
                    //         <InputLabel htmlFor={item}>{String(item).replace('_', ' ')}</InputLabel>
                    //         <Input
                    //           id={item}
                    //           value={this.state.cognitoUser.challengeParam.userAttributes[index]}
                    //           type='text'
                    //           onChange={this.handleRequiredAtt}
                    //           autoComplete="off"
                    //         />
                    //       </FormControl>
                    //     </div>
                    //   );
                    // })
                  }
                  <div>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="password">Nueva contraseña</InputLabel>
                      <Input
                        id="password"
                        name="password"
                        value={this.state.password}
                        type={this.state.showPassword ? 'text' : 'password'}
                        autoComplete="off"
                        required
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="Toggle password visibility" onClick={() => this.handleShowPassword('showPassword')}>
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>

                        }
                        onChange={this.handleChange}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="confirmPassword">Confirma contraseña</InputLabel>
                      <Input
                        id="confirmPassword"
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        type={this.state.showconfirmPassword ? 'text' : 'password'}
                        autoComplete="off"
                        required
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="Toggle password visibility" onClick={() => this.handleShowPassword('showconfirmPassword')}>
                              {this.state.showconfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        onChange={this.handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className={classes.btnArea}>
                    <Button variant="raised" color="secondary" type="submit">
                      Continuar
                      <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine} />
                    </Button>
                  </div>
                </form>
              </PapperBlock>
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={this.state.open}
                autoHideDuration={6000}>
                <CustomNotification
                  onClose={() => this.setState({ open: false })}
                  variant="error"
                  message={this.state.messageNotification} />
              </Snackbar>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResetPassword);
