import React from 'react';

import './infinito.css'; 

class LoaderFull extends React.Component{
    render(){
        return(
            <div className="bodyLoader">
                <div className="loader-wrapper-infinite">
                    <div className="loader-infinite">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>

                    <div id="loader2" className="loader-infinite">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>

                    <div id="loader3" className="loader-infinite">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>

                    <div id="loader4" className="loader-infinite">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>

                    <div id="loader5" className="loader-infinite">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoaderFull; 