const styles = theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: '1.5rem',
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '.5rem',
        },
      },
      demo: {
        marginBottom: '1.2rem',
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
      item: {
          textAlign: 'center',
      },
      inlineFlex: {
        display: 'inline-flex',
        gap: '20px',
        marginBottom: 8,
      },
    link: {
        display: 'block',
        textDecoration: 'none',
        fontSize: 18,
        color: 'black',
        fontWeight: 600,
        cursor: 'pointer',
        fontFamily: "Roboto, Oswald",
        margin: 0,
        paddingBottom: 8,
        paddingTop: 8,
        '&:hover':{
            opacity: '.7'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
            display: 'inline-block',
            marginTop: 0
        },
    },
    active: {
        color: theme.palette.primary.main,
        borderBottom: '2px solid',
        borderColor: theme.palette.primary.main,
    },
    WrapperButton: {
      display: 'flex',
      flexDirection: 'row',
      '& div + div':{
          marginLeft: 24,
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        '& div + div':{
            marginLeft: 0,
            marginTop: 24,
        },
      },
    },
    bgAlpha15:{
      backgroundColor: 'rgba(0,0,0,.15)',
    },
    bgAlpha30: {
      backgroundColor: 'rgba(0,0,0,.30)',
    },
    mt5:{
      marginTop: '4rem',
    }
  });

  export default styles;
