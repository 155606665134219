import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './event-jss';
import EventCard from '../CardPaper/EventCard';
import Grid from '@material-ui/core/Grid';
import { fetchCall } from '../../service/service_base';

class Event extends React.Component {
  state = {
    eventos: []
  }
  componentDidMount() {
    if (!this.props.isSearching) {
      this._load();
    } else {
      if (this.props.section) {
        this.setState(
          {
            eventos: []
          }, () => {
            this.setState(
              {
                eventos: this.props.section.content
              }
            )
          }
        )
      }
    }
  }

  _load = () => {
    fetchCall("POST", "/synthesis/getcontentpublish", {
      sectionId: this.props.section.sec_id,
      menuId: this.props.idMenuSelected
    }).then(response => {
      this.setState({ eventos: response.data });
    }).catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isSearching && nextProps.section) {
      this.setState({
        eventos: nextProps.section.content
      });
    }
  }

  render() {
    const { isSearching, sec_name } = this.props;
    return (
      <div id={sec_name}>
        <Grid container spacing={24}>
          {this.state.eventos.map((item, key) => {
            if (item.cont_key)
              item.cont_imagePath = item.cont_key;
            let fecha = isSearching ? (item.cont_imagePath ? item.cont_imagePath.toString() : null) : item.cont_key.toString();
            let mes = "", dia = "";
            if (fecha) {
              mes = fecha.split("-")[1] === "01" ? "Ene" :
                fecha.split("-")[1] === "02" ? "Feb" :
                  fecha.split("-")[1] === "03" ? "Mar" :
                    fecha.split("-")[1] === "04" ? "Abr" :
                      fecha.split("-")[1] === "05" ? "May" :
                        fecha.split("-")[1] === "06" ? "Jun" :
                          fecha.split("-")[1] === "07" ? "Jul" :
                            fecha.split("-")[1] === "08" ? "Ago" :
                              fecha.split("-")[1] === "09" ? "Sep" :
                                fecha.split("-")[1] === "10" ? "Oct" :
                                  fecha.split("-")[1] === "11" ? "Nov" :
                                    fecha.split("-")[1] === "12" ? "Dic" : '';
              dia = fecha.split("-")[2];
            }
            const lastItem = key === this.state.eventos.length - 1;
            return <Grid item xs={12} md={6} lg={6} key={key}>
              <EventCard
                title={item.cont_name}
                description={item.cont_description}
                day={dia}
                month={mes}
                publish_date={item.publish_date}
                noDivider={lastItem}
              />
            </Grid>
          })}
        </Grid>
      </div>
    );
  }
}

Event.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Event);
