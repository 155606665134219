import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import styles from './reactiveBase-jss';

class ReactiveBaseStyle extends React.Component {

  render() {
    const { classes, url, app, children } = this.props;
    return (
      <ReactiveBase url={url} app={app} className={classes.container}>
        {children}
      </ReactiveBase>
    );
  }
}

ReactiveBaseStyle.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired, // Add any other necessary PropTypes here
  app: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(ReactiveBaseStyle);