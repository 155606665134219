import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import logo from '../../../static/images/logo-televisa-black.svg';
import logoM from '../../../static/images/logo-televisa-black-m.svg';
import brand from '../../utils/brand';
import UserMenu from './UserMenu';
import Search from '@material-ui/icons/Search';
import styles from './header-jss';
import { _getDate } from '../../utils/dateFormat'
import { ThemeContext } from '../../utils/ThemeContext';
import { Link } from 'react-router-dom';

class Header extends React.Component {

  state = {
    todayIs: _getDate()
  };

  render() {
    const {
      classes,
      photo
    } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ header }) => (
          <AppBar
            className={
              classNames(classes.root)
            }
          >
            <Toolbar disableGutters={!this.state.open} className={classes.column}>
              <div className={classNames(
                classes.container, 
                (header && header.sec_color) && header.sec_color === '#ffffff' ? classes.colorDark : classes.colorWhite)} 
              style={{ backgroundColor: (header && header.sec_color) && header.sec_color }}
              >
                {/* Logo */}
                <div className={classes.contentLogo}> 
                  {
                    header && header.map((item, key) => {
                      return <div key={key}>
                        <Hidden xsDown>
                          <img src={item.cont_imagePath} alt={brand.name} className={classes.logo} />
                        </Hidden>
                        <Hidden smUp>
                          <img src={item.cont_imagePath} alt={brand.name} className={classes.logoM} />
                        </Hidden>
                      </div>
                    })
                  }
                  {!header && <div>
                    <Hidden xsDown>
                      <img src={logo} alt={brand.name} className={classes.logo} />
                    </Hidden>
                    <Hidden smUp>
                      <img src={logoM} alt={brand.name} className={classes.logoM} />
                    </Hidden>
                  </div>}
                </div>
                
                {/* MENU */}
                <div>
                  {/* Fecha */}
                  <Hidden xsDown>
                    <div className={classes.datHeader}>
                      <span><strong>{this.state.todayIs.split(",")[0]}</strong> {this.state.todayIs.split(",")[1]}</span>
                    </div>
                  </Hidden>
                  <Tooltip title="Búsqueda de noticias">
                    <Link to="/busqueda">
                      <IconButton
                        aria-haspopup="true"
                        color={'inherit'}
                      >
                        <Search />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <UserMenu 
                    urlPhoto={photo} 
                    whiteHeader={(header && header.sec_color) && header.sec_color === '#ffffff'}
                    color={(header && header.sec_color &&(header.sec_color.includes("#")&&header.sec_color.toLowerCase()!="#ffffff"))?'inherit':''}/>
                </div>

              </div>
            </Toolbar>
          </AppBar>
        )}
      </ThemeContext.Consumer>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  photo: PropTypes.string.isRequired
};

export default withStyles(styles)(Header);
