import pink from '@material-ui/core/colors/pink';
import lightGreen from '@material-ui/core/colors/lightGreen';
import dark from '@material-ui/core/colors/blueGrey';

const styles = theme => ({
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`
  },
  card: {
    borderRadius: '0 !important',
    boxShadow: 'none !important',
    backgroundColor: 'transparent !important',
  },
  cardCartoon: {
    borderRadius: '0 !important',
    boxShadow: 'none !important',
    backgroundColor: 'transparent !important',
    '& :first-child': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  cardColumn: {
    borderRadius: '0 !important',
    boxShadow: 'none !important',
    backgroundColor: 'transparent !important',
    '& :first-child': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  horizontal: {
    display: "flex",
    flexDirection: "row-reverse",
    height: "100%",
    gap: "10px",
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& $mediaCardHorizontal': {
      [theme.breakpoints.down('xs')]: {
        minHeight: 200,
        width: '100%'
      },
    }
  },
  contenidoMedia: {
    padding: '0 !important',
  },
  pushBtn: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  contenidoCartoon: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '.7rem 0 !important',
  },
  liked: {
    color: pink[500]
  },
  shared: {
    color: lightGreen[500]
  },
  num: {
    fontSize: 14,
    marginLeft: 5
  },
  rightIcon: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center'
  },
  date: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    fontSize: '.8rem',
    fontFamily: "Roboto, Oswald",
  },
  datePicker: {
    display: 'inline-block',
    marginRight: 5,
    fontSize: '.8rem'
  },
  dateRelevante: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '.8rem',
    fontFamily: "Roboto, Oswald",
  },
  button: {
    marginRight: theme.spacing.unit,
    lineHeight: '1em',
    fontSize: '12px',
    minHeight: 'initial',
    marginTop: 5,
    padding: '7px 14px !important',
    fontWeight:400,
    fontFamily: "Roboto, Oswald",
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  w50responsive:{
    flex: 1
  },
  mediaHorizontal: {
    width: '50%',
    height: '100%',
    position: 'relative',
    backgroundColor: '#f2f2f2',
    overflow: 'hidden',
    '& div':{
      height: '100%',
    },
    '& img':{
      position: 'absolute',
      width: '100%'
    }
  },
  mediaCardHorizontal: {
    width: '200px',
    height: 'calc(100% - 10px)',
    position: 'relative',
    backgroundColor: '#f2f2f2',
    overflow: 'hidden',
    '& div':{
      height: '100%',
    },
    '& img':{
      position: 'absolute',
      width: '100%',
      objectFit: 'cover',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        objectFit: 'fill',
      },
    }
  },
  dividerLine: {
    paddingBottom: 8, 
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.main,
  },
  dividerLineResponsive: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 8, 
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.primary.main,
    }
  },
  dividerLineHidden: {
    paddingBottomg: 0,
    border: 'none !important'
  },
  autor:{
    fontWeight: 300,
    fontFamily: "Roboto, Oswald",
    fontSize: '1.1rem',
    marginBottom: 10,
    color: theme.palette.primary.main,
  },
  mediaColumn: {
    position: 'relative',
    backgroundColor: '#f2f2f2',
    margin: '0',
    height: 175,
    overflow: 'hidden',
    '& div':{
      height: '100%',
    },
    '& img':{
      height: '100%',
      width: '100%',
      objectFit: 'fill'
    }
  },
  mediaColumnSmall: {
    height: 100,
  },
  contenidoColumn: {
    padding: '.7rem 0 !important',
  },
  mediaEditoriales: {
    position: 'relative',
    backgroundColor: '#f2f2f2',
    margin: '0 24px',
    height: 175,
    overflow: 'hidden',
    '& div':{
      height: '100%',
    },
    '& img':{
      position: 'absolute',
      width: '100%'
    }
  },
  mediaEditorialesTexto: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f00',
    margin: '0 24px',
    height: 140,
    overflow: 'hidden',
    '& p':{
      color: '#fff',
      fontSize: 18,
      padding: '0 10px'
    },
    '& div':{
      height: '100%',
    },
    '& img':{
      position: 'absolute',
      width: '100%'
    }
  },
  mediaCartoon: {
    position: 'relative',
    backgroundColor: '#ffffff',
    margin: '0',
    height: 300,
    overflow: 'hidden',
    '& div':{
      height: '100%',
    },
    '& img':{
      position: 'absolute',
      width: '100%'
    }
  },
  mediaRelevante: {
    position: 'relative',
    height: 160,
    overflow: 'hidden',
    '& div':{
      height: '100%',
    },
    '& img':{
      position: 'absolute',
      width: '100%'
    }
  },
  cardRelevante:{
    backgroundColor: "transparent !important",
    borderRadius: "5px 5px 26px 5px !important",
    boxShadow: 'none !important',
    border: '3px solid white',
    textAlign: 'center !important',
    color: '#fff !important',
    height: '100%',
    '& p':{
      color: '#fff !important'
    },
    '& button':{
      backgroundColor: '#fff !important',
      lineHeight: '1em',
      fontSize: '.7rem',
      minHeight: 'initial',
      marginTop: 5,
      padding: '6px 14px',
      fontWeight:400,
      justifySelf: 'flex-end !important',
      alignSelf: 'center'
    }
  },
  contentRelevante: {
    marginBottom: 0,
    paddingBottom: '15px !important',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardPlayer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 150,
    height: 150,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  cardSocmed: {
    minWidth: 275,
  },
  cardProduct: {
    position: 'relative'
  },
  mediaProduct: {
    height: 0,
    paddingTop: '60.25%', // 16:9
  },
  rightAction: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& small': {
      fontSize: 12
    }
  },
  floatingButtonWrap: {
    position: 'relative',
    paddingTop: 50
  },
  buttonAdd: {
    position: 'absolute',
    right: 20,
    top: -20,
  },
  buttonAddList: {
    display: 'none',
    marginLeft: 10
  },
  title: {
    fontSize: 14,
    fontFamily: "Roboto, Oswald",
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  titleRadio: {
    fontSize: 20,
    fontFamily: "Roboto, Oswald",
    color: theme.palette.primary.main,
  },
  titleRelevant: {
    fontSize: 20,
    fontFamily: "Roboto, Oswald",
    color: theme.palette.primary.main,
    textAlign: 'left !important'
  },
  titleWrap2: {
    color: theme.palette.primary.main,
    fontSize: 14,
    whiteSpace: "initial !important",
    lineHeight: "1.2",
    fontWeight: 'bold',
    fontFamily: "Roboto, Oswald",
    minHeight: "36px !important",
  },
  ratting: {
    margin: '10px 0',
    '& button': {
      width: 24,
      height: 24
    }
  },
  status: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 10,
    '& > *': {
      margin: 5
    }
  },
  desc: {
    height: 45,
    overflow: 'hidden'
  },
  chipDiscount: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
  },
  chipSold: {
    background: dark[500],
    color: theme.palette.getContrastText(dark[500]),
  },
  contentProfle: {
    flex: '1 0 auto',
    textAlign: 'center'
  },
  mediaProfile: {
    height: 0,
    paddingTop: '36.25%', // 16:9
  },
  actions: {
    display: 'flex',
    backgroundColor: 'transparent !important',
    padding: '0 24px 0 !important',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px 0 !important',
    },
  },
  avatarBig: {
    width: 80,
    height: 80,
    margin: '-56px auto 10px',
    background: theme.palette.secondary.dark
  },
  name: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonProfile: {
    margin: 20
  },
  bottomLink: {
    width: '100%',
  },
  price: {
    paddingBottom: 20
  },
  contentProfile: {
    textAlign: 'center'
  },
  verified: {
    fontSize: 16,
    color: theme.palette.primary.main
  },
  cardList: {
    display: 'flex',
    justifyContent: 'space-between',
    '& $buttonAddList': {
      display: 'inline-block'
    },
    '& $floatingButtonWrap': {
      flex: 1,
    },
    '& $buttonAdd': {
      display: 'none'
    },
    '& $status': {
      right: 'auto',
      left: 0,
    },
    '& $mediaProduct': {
      width: 300,
      paddingTop: '21.25%'
    },
    '& $price': {
      flexDirection: 'column',
      justifyContent: 'center',
      '& button': {
        marginTop: 20
      }
    }
  },
  televisaContent: {
    textAlign: 'left',
  },
  textLeft: {
    textAlign: 'left',
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  color1: {
    backgroundColor: theme.palette.primary.main
  },
  color2: {
    backgroundColor: theme.palette.secondary.main
  },
  color3: {
    backgroundColor: '#006687'
  },
  btnContrast:{
    color: "#cacaca"
  },
  btnActive: {
    color: theme.palette.primary.contrastText
  },
  rightIconB: {
    fontSize: 18,
    marginLeft: 5
  },
  more: {
    marginTop: 5,
    '& a': {
      color: theme.palette.primary.main,
      display: 'flex',
      textDecoration: 'none',
      cursor: 'pointer'
    }
  },
  paddingBottom: {
    paddingBottom: 15
  },
  moreCarton: {
    marginTop: 5,
    '& a': {
      color: '#141414',
      display: 'flex',
      textDecoration: 'none',
      cursor: 'pointer',
    }
  },
  cardMediaRadio: {
    backgroundColor: 'transparent !important',
    borderRadius: '0 !important',
    boxShadow: 'none !important',
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '100%',
    minWidth: 275,
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  cardEvent: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0 !important',
    boxShadow: 'none !important',
    gap: '5px'
  },
  mediaRadio:{
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 70px',
    '@media (max-width: 360px)': {
      padding: '0 40px',
    },
    cursor:"pointer"
  },
  mediaRadio2:{
    backgroundColor: theme.palette.primary.main,
    '& div':{
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    '& img':{
      height: '100%',
      width: '300px',
      objectFit: 'none',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        height: '200px',
      },
    },
    cursor:"pointer"
  },
  mediaIcon:{
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    '@media (max-width: 360px)': {
      padding: '0 10px',
    },
  },
  headerEvent:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px'
  },
  iconBig:{
    width: 98,
    height: 98,
    color: '#fff',
    backgroundColor: 'rgba(255,255,255,0.2)',
    flexDirection: 'column',
    '@media (max-width: 360px)': {
      width: 50,
      height: 50,
    },
    '& p:first-child':{
      margin: 0,
      fontSize: '1.9rem',
      lineHeight: '1.9rem'
    },
    '& p':{
      margin: 0,
      fontSize: '1.6rem',
      lineHeight: '1.9rem'
    },
    '& svg':{
      fontSize: 32,
      '@media (max-width: 360px)': {
        fontSize: 25,
      },
    }
  },
  contenidoMediaIcon:{
    width: '100%',
    padding: '10px',
    backgroundColor: '#e6e6e6'
  },
  busqueda: {
    border: 'none !important',
    minWidth: 50,
    maxWidth: '100%',
    marginBottom: 15
  },
  programRadio: {
    color: theme.palette.primary.main,
    opacity: 0.7,
    fontFamily: "Roboto, Oswald",
  },
  descriptionRelevant: {
    fontFamily: "Roboto !important",
    textAlign: "left !important"
  },
  description: {
    fontFamily: "Roboto !important",
    wordBreak: "break-word",
  },
  descriptionWrap8: {
    fontFamily: "Roboto !important",
    whiteSpace: "initial !important",
    fontSize: "12px",
    lineHeight: "1.2",
    '& p':{
      margin: 0
    }
  },
  textDescriptionWrap8: {
    lineHeight: 'normal'
  },
  mb1: {
    margin: '0 0 0.5rem 0',
  },
  mt1: {
    margin: '1rem 0 0 0'
  },
  eventDescription: {
    color: theme.palette.secondary.main,
    fontFamily: "Roboto, Oswald",
  },
  kicker : {
    color: theme.palette.secondary.main,
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: "Roboto, Oswald",
    display: 'inline-block',
    marginRight: '5px',
    marginBottom: '0.4rem',

  },
  transparentText: {
    color: 'transparent',
    userSelect: 'none',
  },
  stylesTextEvent: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    padding: '1px 6px',
    fontSize: '14px !important',
    fontWeight: '500',
    textTransform: 'uppercase',
    textWrap: 'nowrap',
  },
  h100: {
    height: '100%'
  }
});

export default styles;
