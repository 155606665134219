import Amplify ,{ Storage, Auth } from 'aws-amplify';
import awsmobile from '../../aws-exports';

Amplify.configure(awsmobile);
Amplify.configure({
    API:{
        endpoints: [
            {
                name: "Synthesis API",
                endpoint: "https://admqsaj26k.execute-api.us-east-1.amazonaws.com/services",
                region: "us-east-1",
                custom_header: async () => {
                    return { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                  }
            },
            {
                name: "Synthesis Elasticsearch",
                endpoint: "https://d17r7udl34yeic.cloudfront.net/elasticsearch",
                region: "us-east-1"
            }
        ]
    }
})

export const getPhotoCurrentUser = (username) => {
    return new Promise(
        function(resolve, reject){
            Storage.get(username)
            .then(result => resolve(result))
            .catch(err => reject(err));
        }
    );
}

export const getKeys = (path) => {
    return new Promise(
        function(resolve, reject){
            Storage.list(path).then(response => resolve(response)).catch(err =>reject(err));
        }
    );
}

export const getKeysProtected = (path) => {
    return new Promise(
        function(resolve, reject){
            Storage.list(path).then(response => resolve(response)).catch(err =>reject(err));
        }
    );
}

export const getUrlImage = (userId) =>{
    return new Promise(
        function(resolve, reject){
            Storage.get(userId)
            .then(result => resolve(result))
            .catch(err => reject(err));
        }
    );
}

export const getUrlIconSynthesis = (userId) =>{
    return new Promise(
        function(resolve, reject){
            Storage.get(userId)
            .then(result => resolve(result))
            .catch(err => reject(err));
        }
    );
}

