import cyan from '@material-ui/core/colors/cyan';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    zIndex: 1,
    position: 'relative'
  },
  lowercase: {
    textTransform: 'lowercase !important',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    // [theme.breakpoints.down('md')]: {
    //   overflow: 'hidden'
    // },
  },
  wrapperLogins: {
    width: '100%',
    paddingBottom: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 3,
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing.unit * 2
  },
  loginWrap: {
    [theme.breakpoints.up('md')]: {
      width: 860
    },
  },
  formWrap: {
    [theme.breakpoints.up('md')]: {
      // marginTop: -24
    },
  },
  btnArea: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& button': {
        width: '100%',
        margin: 5
      }
    },
  },
  noMargin: {
    margin: 0
  },
  optArea: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '60%'
    },
  },
  redBtn: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  grayBtn: {
    color: 'rgba(100, 100, 100, 0.84)',
    // backgroundColor: indigo[500],
    backgroundColor: theme.palette.grey[100] + "!important",
    '&:hover': {
      backgroundColor: theme.palette.grey[300] + "!important",
    },
  },
  blueBtn: {
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: "#114477",
    '&:hover': {
      backgroundColor: "#113654",
    },
  },
  cyanBtn: {
    color: theme.palette.getContrastText(cyan[700]),
    backgroundColor: cyan[500],
    '&:hover': {
      backgroundColor: cyan[700],
    },
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 18,
  },
  footer: {
    textAlign: 'center',
    // padding: 5,
    // background: theme.palette.grey[100],
    fontSize: 14,
    position: 'relative'
  },
  welcomeWrap: {
    position: 'relative',
    '@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) ': {
      display: 'none'
    },
    '@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape)  ': {
      display: 'block'
    },
    '@media (max-width: 959px)': {
      display: 'none'
    },
  },
  welcome: {
    background: 'rgba(0, 128, 137, 0.6)',
    color: 'white',
    // position: 'absolute',
    width: '100%',
    height: 'calc(100% + 30px)',
    // height: '104%',
    padding: '24px',
    top: -15,
    left: 2,
    boxShadow: theme.shadows[5],
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    // overflow: 'hidden'
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    marginBottom: 20,
    '& img': {
      width: 32
    },
    '& h3': {
      fontSize: 18,
      margin: 0,
      paddingLeft: 10,
      fontWeight: 500,
      color: theme.palette.grey[700]
    }
  },
  brandText: {
    marginTop: 10,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  decoBottom: {
    fontSize: 480,
    position: 'absolute',
    left: 10,
    bottom: -190,
    opacity: 0.1,
    color: theme.palette.secondary.dark
  },
  tab: {
    marginBottom: 20,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing.unit * -3,
    },
  },
  link: {
    fontSize: 12,
    marginLeft: -30,
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  mb: {
    marginBottom: theme.spacing.unit * 1
  },
  socMedFull: {
    marginBottom: theme.spacing.unit * 2
  },
  lockWrap: {
    textAlign: 'center',
    padding: theme.spacing.unit * 3
  },
  avatar: {
    width: 150,
    height: 150,
    margin: '5px auto 30px',
    [theme.breakpoints.up('md')]: {
      margin: '-75px auto 30px',
    },
    boxShadow: theme.shadows[8]
  },
  userName: {
    marginBottom: theme.spacing.unit * 3
  },
  hint: {
    padding: theme.spacing.unit
  },
   progress: {
    margin: theme.spacing.unit * 2,
  },
  centerProgress:{
    paddingTop: 90,
    minHeight:270,
    textAlign:'center',
    '& div':{
      // color: '#174a7c !important'
    }
  },
  originPri:{
    backgroundColor: '#174a7c',
    color: '#fff'
  },
  subtitle: {
    textAlign: 'center',
    color: 'rgba(70, 70, 70, 0.84)',
    fontSize: 16,
    padding: '1.2rem 1.5rem 1.4rem'
  },
  subtitleNoP: {
    textAlign: 'center',
    color: 'rgba(70, 70, 70, 0.84)',
    fontSize: 16,
    padding: '0 1.5rem 1.2rem'
  },
  help: {
    textAlign: 'center',
    color: 'rgba(70, 70, 70, 0.84)',
    fontSize: 13,
    padding: '0 1.5rem 6px'
  },
  contentLogo: {
    width: '100%',
    textAlign: 'center',
    margin: '30px 0',
    '& img': {
      width: '135px'
    }
  },
  version:{
    color: 'whitesmoke',
    fontSize: '10px'
  }
});

export default styles;
