

const styles = theme => ({
  root: theme.mixins.gutters({
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
    margin: '0 auto',
    maxWidth: '1200px',
    '&$noMargin': {
      margin: 0
    },
  }),
  title: {
    marginBottom: 10,
    marginLeft: 10,
    paddingBottom: 8,
    position: 'relative',
    textTransform: 'capitalize',
    fontSize: 22,
    color: theme.palette.primary.main,
    lineHeight: '40px',
    fontFamily: "Roboto, Oswald",
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: 60,
      borderBottom: `4px solid #008089`
    }
  },
  description: {
    maxWidth: 960,
    fontSize: 16,
  },
  content: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit,
    backgroundColor: theme.palette.background.paper,
  },
  whiteBg: {
    backgroundColor: 'transparent',
    margin: 0,
  },
  noMargin: {},
  colorMode: {
    backgroundColor: 'transparent',
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    margin: 'auto',
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: '0 !important',
    '& $title': {
      marginBottom: 10,
      paddingBottom: 5,
      paddingLeft: 10,
      position: 'relative',
      textTransform: 'uppercase',
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '34px',
      display: 'inline-block',
      width: 'auto',
      '@media (max-width: 430px)': {
        width: '80%',
        paddingBottom: 25,
      },
      '@media (max-width: 375px)': {
        width: '100%',
      },
      '@media (max-width: 320px)': {
        fontSize: 18,
        fontWeight: 600
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: 200,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        display: 'none'
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        borderBottom: `1px solid #C4C4C4`
      },
    },
    '& $iconTitle': {
      display: 'inline-Block',
      marginRight: 10,
      marginTop: 5,
      width: 24,
      height: 24,
      float: 'left',
      marginBottom: -5,
      '& img':{
        aspectRatio: '1/1',
        objectFit: 'cover',
      }
    },
    '& $description': {
      color: theme.palette.grey[100],
    },
    '& $content': {
      backgroundColor: 'transparent !important'
    }
  },
  overflowX: {
    width: '100%',
    overflowX: 'auto',
  },
  iconTitle: {
    display: 'inline-Block',
    marginRight: 10,
    marginTop: 5,
    width: 24,
    height: 24,
    float: 'left',
    '& img':{
      aspectRatio: '1/1',
      objectFit: 'cover',
    }
  }
});

export default styles;
