import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { S3Image } from 'aws-amplify-react';

import styles from './papperStyle-jss';

function getImg(img){
  if(img === "" || img === null ||img === undefined){
    return (
      ""
    );
  }
  else
  {
    return(
      <S3Image imgKey={img} /> 
    );
  }
}

// function getButtons(deleted){
//   if(deleted !== undefined){
//     return(
//       <Button variant="fab" mini color="secondary" aria-label="add" onClick={()=>deleted()}>
//         <Delete />
//       </Button>
//     )
//   }
// }
function getColorTitle(secColor){
  if(secColor&& (secColor.includes("#")&&secColor.toLowerCase()!="#ffffff")){
    return "#FFFFFF"
  }else{
    return ""
  }
}

function PaperSheet(props) {
  const {
    classes,
    title,
    desc,
    img,
    children,
    whiteBg,
    noMargin,
    colorMode,
    overflowX,
    isVisible,
    colorTitle
  } = props;

  if(!isVisible){
    return <div></div>;
  }


  return ( 
      <Paper className={classNames(classes.root, noMargin && classes.noMargin, colorMode && classes.colorMode)} elevation={0}>
        <Typography variant="title" component="h2" className={classes.title} >
          {img && <div className={classes.iconTitle}>
              {getImg(img)}
          </div>}
          {colorTitle?<span style={{color:getColorTitle(colorTitle)}}>{title}</span>:title}
        </Typography>
        <Typography component="p" className={classes.description}>
          {desc}
        </Typography>
        <section className={classNames(classes.content, whiteBg && classes.whiteBg, overflowX && classes.overflowX)}>
          {children}
        </section>
      </Paper> 
  );
}

PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  whiteBg: PropTypes.bool,
  colorMode: PropTypes.bool,
  noMargin: PropTypes.bool,
  overflowX: PropTypes.bool,
  isVisible : PropTypes.bool,
  secName : PropTypes.string
  // ,
  // deleted : PropTypes.func
};

PaperSheet.defaultProps = {
  whiteBg: false,
  noMargin: false,
  colorMode: false,
  overflowX: false,
  isVisible : true
};

export default withStyles(styles)(PaperSheet);
