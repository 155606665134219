import React from 'react';
import './Footer.scss';
import PropTypes from 'prop-types';
import logotipo from '../../../static/images/logotipo.svg'
import { gaPageHit, gaEventHit } from '../../service/service_base';
import { ThemeContext } from '../../utils/ThemeContext';
import { withStyles } from '@material-ui/core';
import styles from './footer-jss';
import classNames from 'classnames';
import {ChromeReaderMode} from '@material-ui/icons';
import SpecialIcons from '../SpecialIcons';
import appJson from '../../../../package.json';
import { Hidden, Tabs, Tab, AppBar } from "@material-ui/core";

function Footer(props) {
  const { loadSynthesis, selected, menu, classes } = props;
  const menusel = menu ? menu.find(x => x.menu_id === selected) : null
  return (
    <ThemeContext.Consumer>
      {({ toggleTheme, footer }) => (
        <>
        <Hidden smUp><div className="spaceFooterMobil"></div></Hidden>
        <div className={classNames(classes.root, "Footer")} style={{ background: footer && footer.sec_color }}>
        <Hidden smUp>
          <AppBar
            position="static"
            elevation={0}
            className={`header`}
          >
            <Tabs
                variant="scrollable"
                scrollable
                scrollButtons="on"
                className={classes.autoScrollable}
              >
                {
                  menu.map((item, index) => {
                    const hash = window.location.hash.substring(1);
                    const isActive = item.menu_name === hash;
                    return (
                      
                      <Tab 
                        href={`#${item.menu_name}`}
                        key={index.toString()} 
                        icon={<SpecialIcons icon={item.menu_icono ? item.menu_icono : ''} />}
                        label={item.menu_name}
                        onClick={() => {
                          loadSynthesis(item);
                          gaPageHit(item.menu_name);
                          toggleTheme(item);
                        }}  
                        className={isActive ? classes.activeTab : ''}
                      /> 
                     
                    )
                  })
                }
            </Tabs>
          </AppBar>
        </Hidden>
        <Hidden xsDown>
          <div className="sintesisContent">
              {
                menu.map((item, index) => {
                  const hash = window.location.hash.substring(1);
                  const isActive = item.menu_name === hash;
                  return (
                    <span
                      id={item.menu_id}
                      key={index.toString()}
                      onClick={() => {
                        loadSynthesis(item);
                        gaPageHit(item.menu_name);
                        toggleTheme(item);
                      }}
                      href={`#${item.menu_name}`}
                      className={classNames(classes.link, props.selected === item.menu_id ? classes.active : '')}
                    >
                      {item.menu_name}
                    </span>
                  )
                })
              }
          </div>
        </Hidden>
          <div className="subMenu" >
            {
              props.section.map((item, index) => {
                if (!["Header", "Banner", "Footer"].includes(item.sec_tipo))
                  if (parseInt(item.sec_totalContent) > 0) {
                    if (item.sec_tipo === "NotasEditoriales" || item.sec_tipo === "Card") {
                      item.sec_link = "EditorialCard"
                    }else{
                      item.sec_link = item.sec_name
                    }
                    return (
                      <a href={`#${item.sec_link}`} key={index.toString()} onClick={() => gaEventHit(menusel.menu_name, item.sec_name)}>
                        {item.sec_name}
                      </a>
                    )
                  }
                  else
                    return null
              })
            }
          </div>

          {
              !footer && (
                <div className='customFooter'>
                  <div className="copy">
                    <img src={logotipo} alt="Televisa" title="Televisa" className="logo" />
                    <div className="textCopy" style={{ color: "white" }}>
                      Derechos Reservados @ Televisa S.A. de C.V. TELEVISA y el logotipo de TELEVISA son marcas registradas
                      <span style={{display: 'block', textAlign: 'center'}}>v{appJson.version}</span>
                    </div>
                  </div>
                </div>)
          }
          {
            footer && footer.map((item, key) => {
              return (
              <div className='customFooter' key={key} 
              style={{ background: footer && footer.sec_color }}
              >
                <div className="copy" >
                  <img src={item.cont_imagePath} alt="Televisa" title="Televisa" className="logo" />
                  <div className="textCopy">
                    <span style={{ color: footer && (footer.sec_color.includes("#") && footer.sec_color.toLowerCase() != "#ffffff") ? "white" : "" }}
                      dangerouslySetInnerHTML={{ __html: `${item.cont_description}` }} />
                    <span style={{ color: footer && (footer.sec_color.includes("#") && footer.sec_color.toLowerCase() != "#ffffff") ? "white" : "" }}>v{appJson.version}</span>
                  </div>
                </div>
              </div>)
            })
          }
        </div>
        </>
      )}
    </ThemeContext.Consumer>
  )

}

Footer.propTypes = {
  loadSynthesis: PropTypes.func.isRequired,
};


export default withStyles(styles)(Footer);
