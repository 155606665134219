
const styles = theme => ({
    WrapperButton: {
        display: 'flex',
        flexDirection: 'row',
        '& div + div':{
            marginLeft: 24,
        },
        [theme.breakpoints.down('xs')]: {
          flexWrap: 'wrap',
          '& div + div':{
              marginLeft: 0,
              marginTop: 24,
          },
        },
    },
    paddingContent: {
        padding: '8px'
    }
})

export default styles;
