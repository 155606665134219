import React from 'react';
import ThemePallete from './themePalette';

export const ThemeContext = React.createContext({
    theme: ThemePallete,
    toggleTheme: () => {},
    header:null,
    toggleHeader:() => {},
    footer:null,
    toggleFooter:() => {}
});