const avatars = [
  '/images/avatars/pp_girl.svg',
  '/images/avatars/pp_girl.svg',
  '/images/avatars/pp_girl2.svg',
  '/images/avatars/pp_girl3.svg',
  '/images/avatars/pp_girl4.svg',
  '/images/avatars/pp_girl5.svg',
  '/images/avatars/pp_boy.svg',
  '/images/avatars/pp_boy2.svg',
  '/images/avatars/pp_boy3.svg',
  '/images/avatars/pp_boy4.svg',
  '/images/avatars/pp_boy5.svg',
];

export default avatars;
