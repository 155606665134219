import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {gaEventHit} from '../../service/service_base';
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 0 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    top: 0,
    zIndex: 11,
  },
  headerTaps: {
      boxShadow: 'none !important',
      textAlign: 'center',
      '& span[class*="indicator"]':{
        backgroundColor: theme.palette.background.paper,
        opacity: .5,
        height: 3
      }
  },
  containerTabs: {
    margin: '0 auto',
    width: '100%',
    maxWidth: 'calc(1200px + 112px)',
    '& div[class*="scrollButtons"]':{
      [theme.breakpoints.down('xs')]: {
          flex: '0 0 24px',
      },
    },
    '& button[class*="scrollButtons"]':{
      [theme.breakpoints.down('xs')]: {
          flex: '0 0 32px',
      },
    },
  }
});

class TapMenu extends React.Component {

  state = {
    value: 0,
    availableSections : [],
    concatId : ''
  };

  componentWillReceiveProps(newProps){
    if(newProps.availableSection !== undefined && newProps.availableSection.length > 0){
        
      this.setState({
        availableSections : newProps.availableSection
      });
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes,state } = this.props;
    const { value } = this.state;
    let sm = state.synthesisAvailable
    const menuname = sm?sm.find(x => x.menu_id === state.idMenuSelected):null 
    return (
      <div className={classes.root}>
        <AppBar position="static" color="primary" className={classes.headerTaps}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            textColor="inherit"
            scrollable
            scrollButtons="on"
            className={classes.containerTabs}
          >
            {
              this.state.availableSections.map((item, index) => {
                if(!["Header", "Banner", "Footer"].includes(item.sec_tipo)) 
                  if(parseInt(item.sec_totalContent) > 0){
                    if (item.sec_tipo === "NotasEditoriales" || item.sec_tipo === "Card" || item.sec_tipo === "EditorialCard") {
                      item.sec_link = "EditorialCard"
                    }else{
                      item.sec_link = item.sec_name
                    }
                    return (
                      <Tab 
                        href={`#${item.sec_link}`} 
                        key={index.toString()} 
                        label={item.sec_name} 
                        onClick={()=>menuname?gaEventHit(menuname.menu_name,item.sec_name):null}  
                      />  
                    );
                  }else{
                    return "";
                  }
              })
            }
          </Tabs>
        </AppBar>
      </div>
    );
  }
}

TapMenu.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(TapMenu);