import React from 'react';
import { object, PropTypes } from 'prop-types';
import classNames from 'classnames';
import { renderRoutes } from 'react-router-config';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Header from '../Header/Header';
import styles from './appStyles-jss';
import './estilos.css'
import { cognitoCurrentUser } from '../../service/cognitoService';
import ls from 'local-storage';

class Dashboard extends React.Component {
  state = {
    transform: 0,
    sidebarOpen: true,
    pageLoaded: false,
    currentName: 'initial',
    currentProfile: 'initial',
    urlPhoto: '',
    addMenu: false,
    permissionAddMenu: true
  };
  MenuContent = [];
  componentDidMount = () => {

    cognitoCurrentUser().then(response => {
    }).catch(err => {
      if (err === "not authenticated") {
        ls.clear();
        window.location.href = "/";
      }
    });
    // Scroll content to top
    const mainContent = document.getElementById('mainContent');
    this.loadTransition(true);

    // Execute all arguments when page changes
    this.unlisten = this.props.history.listen(() => {
      mainContent.scrollTo(0, 0);
      setTimeout(() => {
        this.loadTransition(true);
      }, 500);
    });

    let currentUser = ls.get("current_user");
    if (ls.length === 0) {
      ls.clear();
      window.location.href = "/";
    }

    let addNewSynthesis = true;
    let permissionsCurrentUser = JSON.parse("[" + currentUser.menuSynthesis + "]");
    if (permissionsCurrentUser[0]) {
      let add = permissionsCurrentUser.filter(x => x.name === "Nuevo tipo de síntesis")[0];


      if (add) {
        let currentPermissions = ls.get("permissions");
        if (currentPermissions || currentPermissions) {
          let data = currentPermissions.filter(x => x.menu_id === add.id)[0];
          if (data !== undefined) {
            let configuration = JSON.parse(data.grant_configuration);
            if (configuration.chkPublicar) {
              addNewSynthesis = false;
            }
          }
        }
      }
    }

    this.setState({
      currentName: `${currentUser.user_name} ${currentUser.user_lastname}`,
      currentProfile: currentUser.pro_name,
      urlPhoto: currentUser.user_photo,
      permissionAddMenu: addNewSynthesis
    });

  }

  loadTransition = () => {
    this.setState({ pageLoaded: true });
  }

  toggleDrawer = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  render() {
    const {
      classes,
      route,
    } = this.props;
    const darker = true;
    const { sidebarOpen, pageLoaded } = this.state;
    return (
      <React.Fragment>

        {pageLoaded && <Header toggleDrawerOpen={this.toggleDrawer} turnDarker={this.state.transform > 30 && darker} margin={sidebarOpen} photo={this.state.urlPhoto} />}

        <main className={classNames(classes.content, !sidebarOpen && classes.contentPadding)} id="mainContent">

          <section className={classes.mainWrap}>

            <Fade
              in={pageLoaded}
              mountOnEnter
              unmountOnExit
              {...(pageLoaded ? { timeout: 700 } : {})}
            >
              <div className={!pageLoaded ? classes.hideApp : ''}>
                {renderRoutes(route.routes)}
              </div>
            </Fade>
          </section>
        </main>

      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  route: object
};

Dashboard.defaultProps = {
  route: object
};

export default (withStyles(styles)(Dashboard));
