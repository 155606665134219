import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import styles from './cardStyle-jss';
import Typography from '@material-ui/core/Typography';
class RelevanteCard extends React.Component {
  render() {
    const {
      classes,
      title,
      description,
      notes,
      attachment,
      publish_date
    } = this.props;
    return (
      <Card className={classNames(classes.cardRelevante)}>
        <CardContent className={classNames(classes.cardMedia, classes.contentRelevante)}>
          <div>
          {publish_date && <Typography component="small" className={classes.dateRelevante}>{publish_date}</Typography>}
          <Typography className={classes.titleRelevant}>{title}</Typography>
          <Typography component="p" className={classes.descriptionRelevant}><span style={{fontFamily:"sans-serif"}} dangerouslySetInnerHTML={{ __html: `${description}` }} /></Typography>
          </div>
          <Button onClick={()=> window.open(`/multimedia/${encodeURIComponent(attachment)}`,"_blank")}>{notes} {notes > 1 ? 'notas' : 'nota'}</Button>
          
        </CardContent>
      </Card>
    );
  }
}

RelevanteCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(RelevanteCard);
