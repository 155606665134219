import React from "react";
// import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import classNames from "classnames";
import styles from "./cardStyle-jss";
import Typography from "@material-ui/core/Typography";
import { S3Image } from "aws-amplify-react";

class RadioCard extends React.Component {
  getColorTitle(secColor){
    if(secColor&& (secColor.includes("#")&&secColor.toLowerCase()!="#ffffff")){
      return "#FFFFFF"
    }else{
      return ""
    }
  }
  render() {
    const {
      classes,
      busqueda,
      publish_date,
      image,
      title,
      description,
      program,
      attachment,
      colorTitle,sendEvent
    } = this.props;

    return (
      <Card
        className={classNames(
          classes.cardMediaRadio,
          busqueda && classes.busqueda
        )}
      >
        {image && (
          <a onClick={sendEvent}
            className={classes.mediaRadio2}
            style={{ lineHeight: "0" }}
            href={`/multimedia/${encodeURIComponent(attachment)}`}
            target="_blank" rel="noopener noreferrer"
          >
            <S3Image imgKey={image} />
          </a>
        )}
        <div className={classes.contenidoMediaIcon}>
          <CardContent className={classes.contenidoMedia}>
            <Typography className={classNames(classes.titleRadio, classes.mb1)} style={{color:this.getColorTitle(colorTitle)}}>
              {title}
            </Typography>
            <Typography className={classNames(classes.programRadio, classes.mb1)} style={{color:this.getColorTitle(colorTitle)}}>
              {program}
            </Typography>
            <Typography component="p" className={classes.description}>
              <span
                className={classes.textDescriptionWrap8}
                style={{color: colorTitle&&colorTitle.includes("#")&&colorTitle.toLowerCase()!="#ffffff"?"white":""}}
                dangerouslySetInnerHTML={{ __html: `${description}` }}
              />
            </Typography>
            {publish_date && (
              <Typography component="small" className={classes.date}>
                {publish_date}
              </Typography>
            )}
          </CardContent>
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(RadioCard);
