import React from 'react';
import ReactPlayer from 'react-player';
import awsConfig from '../../../../aws-exports';
import LoaderFull from '../../../components/Loader/LoaderFull';
let acceptedIMG = ["png", "jpg", "jpeg", "bmp", "gif", "svg", "ief"]
class Multimedia extends React.Component {

  state = {
    isLoading: true,
    numPages: null,
    pageNumber: 1,
    url: "",
    fileType: ""
  }

  componentDidMount() {
    let fileType = decodeURIComponent(this.props.match.params.param).split(".")[decodeURIComponent(this.props.match.params.param).split(".").length - 1];
    let file = decodeURIComponent(this.props.match.params.param);
    this.setState(
      {
        url: encodeURIComponent("https://" + awsConfig.aws_user_files_s3_bucket + ".s3.amazonaws.com/public/" + file),
        fileType: fileType,
        isLoading: false
      }
    )
  }

  render() {
    return (
      this.state.fileType !== "undefined" ?
        <div style={{ textAlign: 'center' }}>
          {
            this.state.isLoading ? <LoaderFull /> :
              this.state.fileType === "pdf" ?
                <object title="multimedia" data={decodeURIComponent(this.state.url)} type="application/pdf" width="100%" height="100%"></object>
                : acceptedIMG.includes(this.state.fileType.toLowerCase()) ?
                  <img
                    height="100%"
                    src={decodeURIComponent(this.state.url)} alt=""
                  />
                  : <ReactPlayer height={"100%"} width={"100%"} url={decodeURIComponent(this.state.url)} playing controls progressInterval={2000} />
          }
        </div> : <span style={{ textAlign: 'center' }}><label style={{ color: 'whitesmoke', fontSize: 80 }}>!</label><br></br><label style={{ color: 'whitesmoke', fontSize: 30 }}>No se ha podido abrir el archivo, intente más tarde.</label></span>
    );
  }
}

export default Multimedia;
