function _translateDay(str) {
    switch (str) {
        case "Mon": return "Lunes";
        case "Tue": return "Martes";
        case "Wed": return "Miércoles";
        case "Thu": return "Jueves";
        case "Fri": return "Viernes";
        case "Sat": return "Sábado";
        case "Sun": return "Domingo";
        default: return "";
    }
}

function _translateYear(str) {
    switch (str) {
        case "Jan": return "enero";
        case "Feb": return "febrero";
        case "Mar": return "marzo";
        case "Apr": return "abril";
        case "May": return "mayo";
        case "Jun": return "junio";
        case "Jul": return "julio";
        case "Aug": return "agosto";
        case "Sep": return "septiembre";
        case "Oct": return "octubre";
        case "Nov": return "noviembre";
        case "Dec": return "diciembre";
        default: return "";
    }
}

export const _getDate = (string) => {
    let date = string ? new Date(string) : new Date();
    let currentDay = _translateDay(date.toString().split(" ")[0]);
    let currentDate = _translateYear(date.toString().split(" ")[1]);
    return `${currentDay}, ${date.toString().split(" ")[2]} ${currentDate} ${date.toString().split(" ")[3]}`;
}

export const _getDateShort = (string) => {
    let date = string ? new Date(string) : new Date();
    let currentDate = _translateYear(date.toString().split(" ")[1]);
    return `${date.toString().split(" ")[2]} ${currentDate} ${date.toString().split(" ")[3]}`;
}

export const _todayDate = (string) => {
    return string ? new Date(string).setHours(new Date(string).getUTCHours() + 24) :
        new Date().setHours(new Date().getUTCHours() + 24)
}