const styles = theme => ({
    title: {
      fontSize: 18,
      height: 30,
    //   width: '78%',
    //   whiteSpace: 'nowrap',
    //   textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': '1',
      '-webkit-box-orient': 'vertical',
      color: '#000',
      fontWeight: 500,
    },
    description: {
      color: '#000',
      height: 23,
      maxHeight: 23,
    //   width: '70%',
    //   whiteSpace: 'nowrap',
    //   textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginBottom: 8
    },
    header: {
      paddingTop: 6,
      marginLeft: 12
    },
    today: {
      fontSize: 18,
      height: 30,
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    todayData: {
      fontSize: 30,
      fontWeight: 300,
    },
    week: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 24,
        marginLeft: 12,
        '& div': {
            width: 300
        },
        '& p':{
            padding: '10px 0'
        }
    },
    numberday: {
        fontWeight: 600,
        marginLeft: 3,
        cursor: 'pointer'
    },
    todayDay: {
        fontWeight: 600,
    },
    event: {
        position: 'relative',
        '&:before':{
            content: '""',
            position: 'absolute',
            width: 10,
            height: 10,
            borderRadius: 5,
            bottom:'-3px',
            left: 3,
            backgroundColor: theme.palette.primary.main
        }
    },
    haveEvents:{
      color : theme.palette.primary.main,
      fontWeight: 500,
    }
  });

  export default styles;