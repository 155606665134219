
export const Alphanumeric = (str) => {
    const rgx_alphanumeric = /[!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]/;
    return rgx_alphanumeric.test(String(str).toLowerCase())
}

export const ValidationContent = (str) => {
    const rgx_alphanumeric = /[']/;
    return rgx_alphanumeric.test(String(str).toLowerCase())
}
 
export const Validate = (email) => {
    const expression = /^([a-zA-Z0-9!#\$%*/?\|\^\{\}`~&'+\-=_])+(\.([a-zA-Z0-9!#\$%*/?\|\^\{\}`~&'+\-=_])+)*@((\[(((([0-1])?([0-9])?[0-9])|(2[0-4][0-9])|(2[0-5][0-5])))\.(((([0-1])?([0-9])?[0-9])|(2[0-4][0-9])|(2[0-5][0-5])))\.(((([0-1])?([0-9])?[0-9])|(2[0-4][0-9])|(2[0-5][0-5])))\.(((([0-1])?([0-9])?[0-9])|(2[0-4][0-9])|(2[0-5][0-5]))\]))|((([a-zA-Z0-9])+(([\-])+([a-zA-Z0-9])+)*\.)+([a-zA-Z])+(([\-])+([a-zA-Z0-9])+)*))$/;
    return expression.test(String(email).toLowerCase())
}

export const Password =(password)=>{
    const exp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#\$%*/?\|\^\{\}`~&'+\-=_@$!%.*?&#/()=?'¿¡_"'-])[A-Za-z\d!#\$%*/?\|\^\{\}`~&'+\-=_@$!%.*?&#/()=?'¿¡_"'-]{8,}$/;
    return exp.test(password)
}