import React, { Component } from 'react';
import { Router } from "react-router-dom";
import createHistory from 'history/createBrowserHistory';
import { renderRoutes } from 'react-router-config';
import routes from './app/routes/index';

import './app/styles/normalize.scss';
import './app/styles/layout/_base.scss';
import { ThemeContext } from './app/utils/ThemeContext';
import ThemePallete from './app/utils/themePalette';
import { createMuiTheme } from '@material-ui/core/styles';
import { fetchCall } from './app/service/service_base';

const history = createHistory();

class App extends Component {

  constructor(props) {
    super(props);

    this.toggleTheme = (item) => {
      this.setState({
        header: null,
        footer: null,
        headerid: 0,
        footerid: 0,
        theme: this.getTheme(item)
      });
    };

    this.toggleHeader = (item) => {
      if (this.state.headerid !== item.sec_id)
        this._load("header", item);
    };

    this.toggleFooter = (item) => {
      if (this.state.footerid !== item.sec_id)
        this._load("footer", item);
    };

    this.state = {
      theme: createMuiTheme(ThemePallete.sintesisTheme),
      toggleTheme: this.toggleTheme,
      header: null,
      toggleHeader: this.toggleHeader,
      footer: null,
      toggleFooter: this.toggleFooter,
      headerid: 0,
      footerid: 0
    };
  }

  getTheme = (item)=>{
    if (item && item.menu_description != '') {
      const colors = item.menu_description.split(",");
      return createMuiTheme({
            palette: {
              primary: {
                main: colors[0],
                contrastText: '#fff',
              },
              secondary: {
                main: colors[1] || colors[0],
                contrastText: '#fff',
              },
            },
            typography: {
                fontFamily: [
                  "Roboto",
                  "Oswald",
                ]
            },
            breakpoints: {
              values: {
                xs: 0,
                sm: 641,
                md: 960,
                lg: 1280,
                xl: 1920,
              },
            }
          })
    } else {
      return createMuiTheme(ThemePallete.sintesisTheme)
    }
  }
  
  _load = (section, item) => {
    fetchCall("POST", "/synthesis/getcontentpublish", {
      sectionId: item.sec_id,
      menuId: item.idMenuSelected
    }).then(response => {
      if(response&&response.data&&response.status==200&&response.data&&response.data.length>0){
        response.data.sec_color=item.sec_color;
        this.setState({ [section]: response.data, [section + "id"]: item.sec_id });
      }
      
    }).catch(err => console.log(err));
  }

  render() {
    return (
      <ThemeContext.Provider value={this.state}>
        <Router history={history}>
          {renderRoutes(routes)}
        </Router>
      </ThemeContext.Provider>
    );
  }
}

export default App;
