import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { fetchCall } from '../../../service/service_base';
import Loader from '../../../components/Loader/Loader';
import {_getDate} from '../../../utils/dateFormat'

const styles = theme => ({
  container: {
    backgroundColor: "#e0e0e0",
    '& > div': {
      height: '100%'
    }
  }
});

class Preview extends React.Component {

  state = {
    isLoading: true,
    numPages: null,
    pageNumber: 1,
    url: "",
    fileType: "",
    params: '',
    previewHTML: '',
    synthesis: [],
    section: [],
    sections: [],
    contentSection: []
  }
  _content = [];

  componentDidMount() {
    this.setState(
      {
        params: decodeURIComponent(this.props.match.params.param),
        isLoading: true
      }, () => {

        this._content = [];
        this.setState({ isLoading: true, previewHTML: '', synthesis: [], sections: [], contentSection: [] }, () => {
          if (this.state.synthesisSelected !== 0) {

            fetchCall("POST", "/synthesis/getsection", {
              menuId: parseInt(this.state.params.toString().split('_')[0])
            }).then(response => {
              this.setState(
                {
                  sections: response.data
                }, () => {
                  this._getPublishContent(this.state.sections, this.state.params, this.state.params.toString().split('_')[1], response.data.length, 0, true, [], []);
                }
              );
            }).catch(err => {
              console.log(err);
            });
          } else {
            this.setState({
              messageNotification: "Selecciona una síntesis",
              openNotification: true,
              typeNotification: 'warning',
              isLoading: false
            });
          }
        })

      }
    )
  }

  _getPublishContent = (section, menu, date, tam, index, preview, emails, listDist) => {
    if (section.length === index) {
      if (this.state.contentSection.length > 0) {
        let section = [];
        section = this.state.sections.map(item => (
          {
            sec_icono: item.sec_icono,
            sec_iconoUrl: item.sec_iconoUrl,
            sec_id: item.sec_id,
            sec_name: item.sec_name,
            sec_orden: item.sec_orden,
            sec_tipo: item.sec_tipo,
            sec_color: item.sec_color,
            content: [...new Set(this.state.contentSection.filter(x => x.sec_id === item.sec_id))]
          }
        ));

        let complete = {
          menu_id: parseInt(menu.toString().split('_')[0]),
          menu_name: menu.toString().split('_')[2],
          menu_description: menu.toString().split('_')[3],
          lastPublish: date.split('T')[0],
          section: [...new Set(section)]
        }

        let menuSelected = {
          menu_id: parseInt(menu.toString().split('_')[0]),
          menu_name: menu.toString().split('_')[2],
          menu_description: menu.toString().split('_')[3],
          lastPublish: date.split('T')[0]
        }
        this.setState(
          {
            synthesis: complete,
            isLoading: false
          }, () => {

            let auxEmail = emails;
            var d = new Date(date.split('T')[0]).setHours(new Date(date.split('T')[0]).getUTCHours() + 24); console.log(new Date(d))

            fetchCall("POST", "/distUserList/email", {
              emails: auxEmail,
              synthesis: this.state.synthesis,
              preview: preview,
              availableSynthesis: JSON.stringify(menuSelected),
              date: _getDate(d)
            }).then(
              response => {
                if (preview) {
                  this.setState({
                    previewHTML: response,
                    openModal: preview,
                    isLoading: false,
                    synthesis: null
                  });
                }
              }
            );
          });
      } else {
        this.setState({
          openNotification: true,
          messageNotification: "No se encontró una síntesis",
          typeNotification: 'warning',
          isLoading: false
        });
      }
    } else {
      fetchCall("POST", "/synthesis/getcontentpublish", {
        sectionId: section[index].sec_id,
        menuId: parseInt(menu.toString().split('_')[0]),
        publishDate: date.split('T')[0]
      }).then(response => {
        if (response) {
          if (response.status === 200) {
            if (response.data != null) {
              response.data.map(item => {
                this._content.push(item);
              });
              this.setState({
                contentSection: this._content
              }, () => {
                this._getPublishContent(section, menu, date, tam, index + 1, preview, emails, listDist)
              });
            }
          }
        } else {
          this._getPublishContent(section, menu, date, tam, index + 1, preview, emails, listDist)
        }
      }).catch(err => {
        console.log("error contenido", err)
      });
    }
  }

  render() {
    const { classes } = this.props;
    const file = new Blob([`${this.state.previewHTML}`], { type: 'text/html' });
    const fileURL = URL.createObjectURL(file);

    return (
      <div className={classes.container}>
        {this.state.isLoading ? <Loader text="Cargando vista previa..." /> : <object data={fileURL} type="text/html" width="100%" height="100%"></object>}
      </div>
    );
  }
}

export default withStyles(styles)(Preview);
