import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "../../styles/components/vendors/slick-carousel/slick-carousel.css";
import "../../styles/components/vendors/slick-carousel/slick.css";
import "../../styles/components/vendors/slick-carousel/slick-theme.css";
import "../../styles/components/vendors/react-animated-slider/react-animated-slider.css";
import { fetchCall } from "../../service/service_base";
import { Grid } from "@material-ui/core";
import NewsCard from "../CardPaper/NewsCard";
import { gaEventHit } from '../../service/service_base';
const styles = theme => ({
  gridCardsTitle: {
    width: '100%',
    padding: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px',
  },
  gridCardItem: {
    flex: 1,
    minWidth: 300,
  },
  wraper: {
    padding: "2rem 1rem 2rem 3rem",
    backgroundColor: theme.palette.secondary.main,
    position: "relative"
  },
  textRotate: {
    color: "white",
    position: "absolute",
    top: 90,
    left: -40,
    fontSize: 24,
    transform: "rotate(-90deg)"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "50px",
    "@media (max-width: 640px)": {
      marginBottom: 0
    }
  },
  item: {
    textAlign: "center",
    "& img": {
      margin: "10px auto"
    }
  },
  rightIcon: {
    fontSize: 18,
    marginLeft: 5
  },
  avatar: {
    color: "#fff",
    marginRight: "10px",
    backgroundColor: "rgba(255,255,255,.85)",
    "& img": {
      width: "30px !important",
      height: "auto !important",
      border: "none !important",
      borderRadius: "0 !important"
    }
  },
  rightIconB: {
    fontSize: 18,
    marginLeft: 5
  },
  more: {
    "& a": {
      color: theme.palette.primary.main,
      display: "flex",
      textDecoration: "none",
      cursor: "pointer"
    }
  }
});

class SliderCard extends React.Component {
  state = {
    notes: [],
    menuname: null
  };
  componentDidMount() {
    if (!this.props.isSearching) {
      this._load();
    } else {
      if (this.props.section) {
        this.setState(
          {
            contentElastic: []
          },
          () => {
            this.setState({
              notes: this.props.section.content
            });
          }
        );
      }
    }
  }

  _load = () => {
    fetchCall("POST", "/synthesis/getcontentpublish", {
      sectionId: this.props.section.sec_id,
      menuId: this.props.idMenuSelected
    })
      .then(response => {
        let sm = JSON.parse(localStorage.getItem("session_menu"))
        const menuname = sm.find(x => x.menu_id === this.props.idMenuSelected);
        this.setState({ notes: response.data, menuname: menuname.menu_name });
      })
      .catch(err => console.log(err));
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isSearching && nextProps.section) {
      this.setState({
        notes: nextProps.section.content
      });
    }
  }

  sendEvent = () => {
    if (this.state.menuname)
      gaEventHit(this.state.menuname, this.props.section.sec_name)
  }

  render() {
    const { isSearching, section, classes } = this.props;
    const notesImage = [], notesDescription = [], notesOnlyTitle = []
    this.state.notes.forEach((item) => {
      if (item.cont_imagePath !== '' && item.cont_key !== '') {
        item.cont_imagePath = item.cont_key;
        notesImage.push(item);
      } else if (item.cont_imagePath === '' && (item.cont_description !== '<p></p>' && item.cont_description.trim() !== '')) {
        notesDescription.push(item);
      } else {
        notesOnlyTitle.push(item);
      }
    }
    )

    return (
      <Grid container spacing={24}>
        {notesImage.length > 0 && <Grid item xs={12}>
          <Grid container spacing={24}>
            {this.state.notes.map((item, key) => {
              if (item.cont_imagePath !== "") {
                if (item.cont_key)
                  item.cont_imagePath = item.cont_key;
                return (
                  <Grid item lg={12} md={12} sm={12} xs={12} key={key}>
                    <NewsCard
                      horizontal
                      kicker={item.cont_program}
                      title={item.cont_name}
                      description={item.cont_description}
                      notes={item.cont_notas}
                      image={isSearching ? item.cont_imagePath : item.cont_key}
                      attachment={isSearching ? item.cont_attachment : item.cont_attachmentKey}
                      publish_date={item.publish_date}
                      colorTitle={section.sec_color}
                      sendEvent={this.sendEvent}
                      contentDivider
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        </Grid>}
        {notesDescription.length > 0 && <Grid item xs={12}>
          <Grid container spacing={24}>
            {notesDescription.map((item, key) => {
              return (
                <Grid item lg={12} md={12} sm={12} xs={12} key={key}>
                  <NewsCard
                    kicker={item.cont_program}
                    title={item.cont_name}
                    description={item.cont_description}
                    notes={item.cont_notas}
                    attachment={isSearching ? item.cont_attachment : item.cont_attachmentKey}
                    publish_date={item.publish_date}
                    colorTitle={section.sec_color}
                    sendEvent={this.sendEvent}
                    contentDivider
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>}
        {notesOnlyTitle && <div className={classes.gridCardsTitle}>
          {notesOnlyTitle.map((item, key) => {
            const lastItem = key === notesOnlyTitle.length - 1;
            return (
              <div className={classes.gridCardItem}>
                <NewsCard
                  kicker={item.cont_program}
                  title={item.cont_name}
                  description={item.cont_description}
                  notes={item.cont_notas}
                  attachment={isSearching ? item.cont_attachment : item.cont_attachmentKey}
                  publish_date={item.publish_date}
                  colorTitle={section.sec_color}
                  sendEvent={this.sendEvent}
                  noDivider={lastItem}
                />
              </div>
            );
          })}
        </div>}
      </Grid>
    );
  }
}

SliderCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SliderCard);
