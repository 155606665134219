import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './banner-jss';
import { Grid } from '@material-ui/core';
import { fetchCall } from '../../service/service_base';
class Banner extends React.Component {
  state = {
    banner: []
  }
  componentDidMount() {
    if (!this.props.isSearching) {
      this._load();
    } else {
      if (this.props.section) {
        this.setState(
          {
            banner: []
          },
          () => {
            this.setState({
              banner: this.props.section.content
            });
          }
        );
      }
    }
  }

  _load = () => {
    fetchCall("POST", "/synthesis/getcontentpublish", {
      sectionId: this.props.section.sec_id,
      menuId: this.props.idMenuSelected
    }).then(response => {
      this.setState({ banner: response.data });
    }).catch(err => console.log(err));
  }

  render() {
    const {
      classes, section
    } = this.props;
    return (
      this.state.banner[0] && this.state.banner[0].cont_description.includes("Web")? <div id={section.sec_name} className="container" style={{ paddingTop: 0 }}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Grid container spacing={16} justify="center">
                <Grid item xs={12}>
                  <a target="_blank" href={this.state.banner[0].cont_name} rel="noopener noreferrer">
                    <div className={classes.btn}>
                      <img src={this.state.banner[0].cont_imagePath} alt="img"/>
                    </div>
                  </a>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>:""
    );
  }
}


export default withStyles(styles)(Banner);
