import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./cardButton-jss";
import { fetchCall,gaEventHit } from "../../service/service_base";
import { Edit, Language, Image as IconImage } from "@material-ui/icons";
import { S3Image } from "aws-amplify-react";

class CardButton extends React.Component {
  state = {
    portadas: [],
    menuname:null
  };
  componentDidMount() {
    if (!this.props.isSearching) {
      this._load();
    } else {
      if (this.props.section) {
        this.setState(
          {
            portadas: []
          },
          () => {
            this.setState({
              portadas: this.props.section.content
            });
          }
        );
      }
    }
  }

  _load = () => {
    const content = []
    this.props.section.sec_id.forEach(element => {
      content.push(fetchCall("POST", "/synthesis/getcontentpublish", {
        sectionId: element,
        menuId: this.props.idMenuSelected
      })
      )
    });
    Promise.all(content)
      .then(response => {
        let sm = JSON.parse(localStorage.getItem("session_menu"))
        const menuname = sm.find(x => x.menu_id === this.props.idMenuSelected);
        let notes = []
        response.forEach((_, index) => {
          notes = [...notes, ...response[index].data]
        });
        this.setState({ portadas: notes, menuname: menuname.menu_name });
      }).catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isSearching && nextProps.section) {
      this.setState({
        portadas: nextProps.section.content
      });
    }
  }

  sendEvent=()=>{
    if(this.state.menuname)
      gaEventHit(this.state.menuname,this.props.section.sec_name)
  }

  render() {
    const { classes, isSearching } = this.props;

    return (
      <>
          {this.state.portadas.map((item,key) => {
            if (item.cont_key)
              item.cont_imagePath = item.cont_key;
            return (
              <div key={key} className={classes.alignBtn}>
                <a onClick={this.sendEvent}
                  target="_blank" rel="noopener noreferrer"
                  className={classes.btnCardX}
                  href={
                    item.publish_date
                      ? `/multimedia/${encodeURIComponent(
                        item.cont_attachment
                      )}`
                      : `/multimedia/${encodeURIComponent(
                        item.cont_attachmentKey
                      )}`
                  }
                >
                 <S3Image
                        imgKey={
                          isSearching ? item.cont_imagePath : item.cont_key
                        }
                        
                      />

                </a>
                {item.publish_date && (
                  <small className={classes.date}>
                    {item.publish_date}
                  </small>
                )}
              </div>
            );
          })}
      </>
    );
  }
}

export default withStyles(styles)(CardButton);
