const styles = theme => ({
  container: {
    maxWidth: 1200,
    margin: '0 auto',
    padding: '0 8px',
  },
  containerBack: {
    maxWidth: 1200,
    margin: '0 auto',
    padding: '14px 8px 0',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  button:{
    textTransform: 'initial'
  },
  leftIcon: {
    width: '.75em',
    height: '.75em',
    marginRight: '5px'
  },
  appSearch: {
    flexShrink: 'unset',
  },
  search:{
    width: 'auto',
    pointerEvents: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 10px 0 10px',
    color: '#424242'
  },
  inputSearch:{
    backgroundColor: '#E7E7E7',
    borderRadius: 4,
    padding: 0,
    minHeight: 40,
    height: 40,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: '#C8C8C8',
    },
  },
  wrapperForm:{
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  contentFilters:{
    display: 'flex',
    flexDirection: 'row',
    gap: '8px', 
    '@media (max-width: 386px)': {
      flexDirection: 'column',
    },
  },
  contentNull: {
    height: "calc(100vh - 506px)"
  },
  filtroBtn: {
    height: 40,
    backgroundColor: '#FFFFFF',
    border: '1px solid',
    borderColor: '#C8C8C8',
    minWidth: 100,
    textTransform: 'initial',
    fles: 1,
    '& svg': {
      marginRight: 10,
    }
  },
  contentRange: {
    padding: 0,
    position: 'relative',
    zIndex: 1101,
    display: 'flex',
    border: '1px solid',
    borderColor: '#C8C8C8',
    borderRadius: 4,
    minHeight: 40,
    height: 40, 
    minWidth: 315,
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      minWidth: 260,
    },
  },
  input: {
    font: 'inherit',
    // padding: `0 ${theme.spacing.unit}px 0 ${theme.spacing.unit}px`,
    border: 0,
    display: 'block',
    verticalAlign: 'middle',
    whiteSpace: 'normal',
    background: 'none',
    height: 40,
    margin: 0, // Reset for Safari
    color: 'inherit',
    width: '100%',
    '& input': {
      paddingRight: '10px !important'
    },
    '&:focus': {
      outline: 0,
    },
    '&:before': {
      border: 'none !important'
    },
    '&:after': {
      border: 'none !important'
    }
  },
  inputDate: {
      font: 'inherit',
      // padding: `0 ${theme.spacing.unit}px 0 ${theme.spacing.unit}px`,
      border: 0,
      display: 'block',
      verticalAlign: 'middle',
      whiteSpace: 'normal',
      background: 'none',
      margin: 0, // Reset for Safari
      color: 'inherit',
      width: '100%',
      '& input': {
        border: '0 !important'
      },
      '&:focus': {
        outline: 0,
      },
      '& > div':{
          height: 40,
          width: '100%',
          border: 'none !important',
          '&:before': {
            border: 'none !important'
          },
          '&:after': {
            border: 'none !important'
          }
      }
  },
  drawerHeader:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 1}px 0`
  },
  titleFil: {
    margin: `0 0 0 ${theme.spacing.unit * 3}px`,
    position: 'relative',
    textTransform: 'capitalize',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: 40,
    }
  },
  fixedTitle: {
    width: '100%',
    padding: '0 24px 15px 0',
    backgroundColor: '#ffffff',
    position: 'sticky',
    top: 0,
    right: 0,
    zIndex: 3000,
    '& h5': {
      display: 'inline-block !important',
    }
  },
  filterForm: {
    padding: theme.spacing.unit * 3
  },
  filterContainer:{
    minWidth: '317px'
  }, 
  // Estilos para cards
  
  item: {
    textAlign: 'left',
    '& img': {
      margin: '10px auto'
    }
  },
  itemPortada: {
      display: 'flex !important',
      justifyContent: 'center !important',
    //   '& img': {
    //     margin: '10px auto'
    //   }
  },
  color1: {
      color: theme.palette.primary.main,
  },
  bgColor1: {
    backgroundColor: theme.palette.primary.main,
    '&:hover':{
        backgroundColor: theme.palette.primary.dark
    }
  },
  titleCartoon: {
    fontSize: 18,
    height: 20,
    overflow: 'hidden',
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: 5,
  },
  titleRadio: {
      fontSize: 18,
      // height: 30,
      overflow: 'hidden',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': '1',
      '-webkit-box-orient': 'vertical',
      color: '#000',
      fontWeight: 500,
    },
  title: {
    fontSize: 18,
    // height: 57,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    color: '#000',
    fontWeight: 500,
    marginBottom: 5,
  },
  description: {
    color: '#000',
    minHeight: 82,
    // maxHeight: 82,
    // overflow: 'hidden'
  },
  wraper: {
    maxHeight: 'none !important'
  },
  count: {
    backgroundColor: theme.palette.primary.main,
    padding: '0px 10px',
    color: 'white !important',
    fontSize: 12,
    maxHeight: 20,
    borderRadius: 15
  },
  checkFilterInput: {
    '& + label::before':{
      minWidth: '16px !important',
      border: '1px solid #000000 !important',
      backgroundColor: 'transparent !important'
    },
    '&:checked + label::before':{
       backgroundColor: `${theme.palette.primary.main} !important`,
       borderColor:`${theme.palette.primary.main} !important`,
    }
  },
  checkFilterLabel: {
    color: 'rgba(0, 0, 0, 0.87) !important'
  }

});

export default styles;
