import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '../../../utils/brand';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import LoginForm2 from '../../../components/Forms/LoginForm';
import styles from './../../../components/Forms/user-jss';
import logo_televisa from '../../../../static/images/logo-televisa.svg';
import './Login.css'
import NewPassword from './NewPassword'

class Login extends React.Component {
  state = {
    requireChangePassword: false, cognitoUser: null, FORCE_CHANGE_PASSWORD: false
  }

  handleChangePassword = (cognitoUser, FORCE_CHANGE_PASSWORD) => {
    this.setState({ requireChangePassword: true, cognitoUser, FORCE_CHANGE_PASSWORD })
  }

  render() {
    const title = brand.name + ' - Login';
    const description = brand.desc;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        {this.state.requireChangePassword ? <NewPassword user={this.state.cognitoUser} force={this.state.FORCE_CHANGE_PASSWORD} /> :
          <div className={classes.container}>
            <Grid container spacing={24} alignItems="center" direction="row" justify="center">
              <Grid item container justify="center" spacing={0} className={classes.loginWrap}>
                <Grid item md={6} sm={8} xs={11} className={classes.welcomeWrap}>
                  <div className={classes.welcome}>
                    <img src={logo_televisa} alt={brand.name} className="centrarLogo" />
                  </div>
                </Grid>
                <Hidden xsUp={false}>
                  <Grid item md={6} sm={8} xs={11}>
                    <LoginForm2 handleChangePassword={this.handleChangePassword} />
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </div>}
      </div>
    );
  }
}

export default withStyles(styles)(Login); 
