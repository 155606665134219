
import React from 'react';
import { PropTypes } from 'prop-types';
import { Helmet } from 'react-helmet';
import brand from '../../../utils/brand';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import SintesisMenu from '../../../components/Header/SintesisMenu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import Today from '@material-ui/icons/Today';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import FilterList from '@material-ui/icons/Tune';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import KeyboardTab from '@material-ui/icons/KeyboardTab';
import styles from './search-jss'
import './searchs-css.css';
import SliderCard from '../../../components/Sliders/SliderCard';
import SliderEditoriales from '../../../components/Sliders/SliderEditoriales';
import SliderCartones from '../../../components/Sliders/SliderCartones';
import SliderRadio from '../../../components/Sliders/SliderRadio';
import Event from '../../../components/Event/Event';
import Portadas from '../../../components/Portadas/Portadas';
import SliderTap from '../../../components/Sliders/SliderTap';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PapperBlock from '../../../components/PapperBlock/PapperBlock';
import { fetchCall } from '../../../service/service_base';
import Footer from '../../../components/Footer/Footer';
import ls from 'local-storage';
import { ThemeContext } from '../../../utils/ThemeContext';
import ReactiveBaseStyle from './ReactiveBaseStyle';
import { DataSearch, SelectedFilters, ReactiveList, MultiDataList, DateRange } from '@appbaseio/reactivesearch';
import MomentLocaleUtils, {
  // formatDate
} from 'react-day-picker/moment';
import 'moment/locale/es';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {_getDateShort} from '../../../utils/dateFormat'
import { Link } from 'react-router-dom';
import LoaderFull from '../../../components/Loader/LoaderFull';
import CardButton from '../../../components/CardButton/CardButton';
import { Snackbar } from '@material-ui/core';
import CustomNotification from '../../../components/Notification/CustomNotification';

const noRender = ["Header", "Banner","Portadas", "Footer"];
const initDate = new Date();
initDate.setHours(initDate.getHours() + 24)
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idMenuSelected: ls.get('menuSelected'),
      synthesisAvailable: [],
      synthesisSections: [],
      isLoading: true,
      kicker: [],
      //Drawer
      isOpen: false,
      //datepicker
      anchorEl: null,
      currentMenu: '',
      availableSection: [],
      date: { start: new Date(), end: initDate },
      //reactivevalues
      searchValue:"",
      sectionValue:[],
      kickerValue:[],
      dateValue:null
    }
  }
  
  // PARA MENU DE SINTESIS

  componentDidMount() {
    this._loadSynthesis();
  }

  _loadSynthesis = () => {
    fetchCall("GET", "/synthesis/get").then(
      response => {
        let available = [];
        if (response.status === 200) {
          if (response.data.length > 0) {
            response.data.forEach(item => {
              let parse = JSON.parse(`[${ls.get('current_user').availableSynthesis}]`);
              if (parse !== undefined) {
                if (parse.filter(x => parseInt(x.id) === parseInt(item.menu_id))[0]) {
                  if (item.lastPublish) {
                    available.push(item);
                  }
                }
              }
            })
          }
        }

        this.setState({
          synthesisAvailable: available,
          currentMenu: available.filter(x => x.menu_id === this.state.idMenuSelected)[0]["menu_name"]
        }, () => {
          this.context.toggleTheme(available.filter(x => x.menu_id === this.state.idMenuSelected)[0]);
          this._loadSectionBySynthesis(this.state.idMenuSelected);
          this._getKickersByList(this.state.idMenuSelected);
        });
      }
    ).catch(
      err => {

      }
    );
  }

  _loadSectionBySynthesis = (menu_id) => {
    this.setState({
      synthesisSections: []
    }, () => {
      fetchCall("POST", "/synthesis/getsection", {
        menuId: menu_id
      }).then(response => {

        if (response && response.data && response.data.length > 0) {
          let aux = [];
          response.data.map(
            item => {
              if(item.sec_tipo==="Header"){
                item.idMenuSelected=this.state.idMenuSelected;
                this.context.toggleHeader(item);
              }
              if(item.sec_tipo==="Footer"){
                item.idMenuSelected=this.state.idMenuSelected;
                this.context.toggleFooter(item);
              }
              if (!noRender.includes(item.sec_tipo)) {
                aux.push(
                  {
                    label: item.sec_name,
                    value: item.sec_name
                  }
                )
              }
            }
          )
          this.setState(
            {
              availableSection: aux,
              idMenuSelected: menu_id
            }
          )
        }
      }).catch(err => console.log(err));
    });
  }

  _getKickersByList = (menu_id) => {
    fetchCall("GET", "/kicker/get").then(response => {
      this.setState({
        kicker: response.data.filter(x => (x.menu_id === menu_id && x.kicker_status === 1)).map(e => { return { label: e.kicker_name, value: `${e.kicker_name},${e.kicker_color}` } })
      });
    }).catch(err => console.log(err));
  }

  handleLoadSyntheis = (menu) => {
    this.setState({
      idMenuSelected: menu.menu_id,
      isLoading: true
    }, () => {
      window.location.href = `/app`;
      ls.set('menuSelected', this.state.idMenuSelected);
    })
  }

  //Open - Close drawer
  handleOnClose = (open) => {
    this.setState({ isOpen: open });
  }

  renderCard = (item) => {
    if (item.length > 0) {
      let type = [];
      let editorialCard = [];
      item.map(
        i => {
          if (i.menu_id === this.state.idMenuSelected && (!noRender.includes(item.sec_tipo))) {
            if (!type.filter(x => x.sec_tipo === i.sec_tipo && x.sec_name === i.sec_name)[0]) {
              let newData = [];
              let aux = item.filter(x => x.sec_tipo === i.sec_tipo && x.sec_name === i.sec_name && x.menu_id === this.state.idMenuSelected);
              aux.map(
                (item) => {
                  if (item.publish_date && !Number.isInteger(item.publish_date) && `${item.publish_date}`.indexOf('-') === -1) {
                    // let f = parseFloat(item.publish_date);
                    // let date = new Date(f);
                    item.publish_date = _getDateShort(parseFloat(item.publish_date))//date.toISOString().split('T')[0]
                  } else {
                    // let date = new Date(item.publish_date);
                    item.publish_date = _getDateShort(item.publish_date) //date.toISOString().split('T')[0]
                  }
                  item.cont_imagePath = item.cont_imagePath ? item.cont_imagePath : (item.cont_imagepath ? item.cont_imagepath : "")
                  if (item.publish_date)
                    newData.push(item);
                }
              );
              if (i.sec_tipo === "NotasEditoriales" || i.sec_tipo === "Card") {
                editorialCard =[...editorialCard,...newData] ;
              }
              type.push(
                {
                  sec_tipo: i.sec_tipo,
                  sec_color: i.sec_color,
                  sec_name: i.sec_name,
                  sec_icono: i.sec_icono,
                  content: newData
                }
              );
            }
          }
        }
      );
      if(editorialCard.length>0){
        type.push(
          {
            sec_tipo: "EditorialCard",
            content: editorialCard
          }
        );
      }
      return type.map(
        (i,k) => {
          if (i.content.length > 0) {
            switch (i.sec_tipo) {
              case "Relevante": return <SliderTap isSearching section={i} idMenuSelected={this.state.idMenuSelected} key={k}/>
              case "Portada": return (
                <div id={i.sec_name ? i.sec_name.toString().trim() : ""} key={i}>
                  <div style={{ backgroundColor: i.sec_color, paddingTop:25}}>
                    <PapperBlock secName={i.sec_name.toString().trim()} img={i.sec_icono} isVisible={true} title={i.sec_name} colorTitle={i.sec_color} colorMode>
                      <div className={'WrapperButtonSearch'}><CardButton isSearching type="Portada" section={i} idMenuSelected={this.state.idMenuSelected} key={k} /></div>
                    </PapperBlock>
                  </div>
                </div>
              )
              case "Notas": return (
                <div style={{ backgroundColor: i.sec_color }} key={k}>
                  <PapperBlock secName={i.sec_name.toString().trim()} img={i.sec_icono} title={i.sec_name} colorMode desc="" colorTitle={i.sec_color}>
                    <SliderCard isSearching section={i} idMenuSelected={this.state.idMenuSelected} />
                  </PapperBlock>
                </div>)
              case "NotasColumnas": return (
                <div style={{ backgroundColor: i.sec_color }} key={k}>
                  <PapperBlock secName={i.sec_name.toString().trim()} img={i.sec_icono} title={i.sec_name} colorMode desc="" colorTitle={i.sec_color}>
                    <SliderEditoriales isSearching section={i} idMenuSelected={this.state.idMenuSelected} autor />
                  </PapperBlock>
                </div>)
              case "EditorialCard":
                return (
                  <div id={i.sec_name ? i.sec_name.toString().trim() : ""} key={k}>
                    <div style={{ backgroundColor: i.sec_color, paddingTop:25 }}>
                      <div className='container WrapperButtonSearch paddingContentSearch'>
                        <CardButton isSearching type={i.sec_tipo} section={i} idMenuSelected={this.state.idMenuSelected} key={k} />
                      </div>
                    </div>
                  </div>
                )
              case "Radio": return (
                <div style={{ backgroundColor: i.sec_color }} key={k}>
                  <PapperBlock secName={i.sec_name.toString().trim()} img={i.sec_icono} title={i.sec_name} colorMode desc="">
                    <SliderRadio isSearching section={i} idMenuSelected={this.state.idMenuSelected} />
                  </PapperBlock>
                </div>)
              case "Evento": return (
                <div style={{ backgroundColor: i.sec_color }} key={k}>
                  <PapperBlock secName={i.sec_name.toString().trim()} img={i.sec_icono} title={i.sec_name} colorMode desc="">
                    <Event isSearching section={i} idMenuSelected={this.state.idMenuSelected} />
                  </PapperBlock>
                </div>)
              case "Portada":
                return <Portadas isSearching section={i} idMenuSelected={this.state.idMenuSelected} key={k} colorMode  />
              default:
            }
          }
        }
      )
    } else {
      return null;
    }
  }

  handleCloseNotification = () => {
    this.setState(
      {
        openNotification: false
      }
    )
  }

  query = (value, props) => {
    if(value){
      let start = new Date(value.start)
      let end = new Date(value.end)
      if(end<start){
        this.setState(
          {
            openNotification: true,
            messageNotification: `Selección de fechas incorrecto`,
            typeNotification: "warning",
          }
        );
      }
      start.setHours(24);
      end.setHours(24)
      end.setHours(end.getHours() + 23, 59)
      return {
        query: {
          range: { "publish_date": { "gte": start.getTime(), "lte": end.getTime() } }
        }
      }
    }
  }

  queryDataSearch = (value, props) => {
    if(value){
      let start = new Date(value.start)
      let end = new Date(value.end)
      start.setHours(24);
      end.setHours(24)
      end.setHours(end.getHours() + 23, 59)
      return {
        "query":{"bool":{"must":[{"bool":{"must":[{"bool":{"should":[{"multi_match":{"query":"Suspensión de Clases","fields":["menu_name","sec_name","cont_name","cont_description","cont_program"],"type":"cross_fields","operator":"and"}},{"multi_match":{"query":"Suspensión de Clases","fields":["menu_name","sec_name","cont_name","cont_description","cont_program"],"type":"phrase","operator":"and"}}],"minimum_should_match":"1"}},{"match":{"menu_name":"Negocios"}}]}}]}},"size":100,"_source":{"includes":["*"],"excludes":[]},"from":0,"sort":[{"sec_order":{"order":"asc"}}]
      }
    }
  }

  render() {
    const title = brand.name;
    const description = brand.desc;
    const { classes } = this.props;
    if (this.state.kicker.length>0&&this.state.availableSection.length>0)
     {

      return (
        <div>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
          </Helmet>
          <div className={classes.containerBack}>
            <Link to="/app">
              <Button className={classes.button}>
                <ArrowBack className={classes.leftIcon} />
                Regresar
              </Button>
            </Link>
          </div>
          <SintesisMenu loadSynthesis={(item) => { this.handleLoadSyntheis(item) }} synthesis={this.state.synthesisAvailable} selected={this.state.idMenuSelected} isSearching />
          <ReactiveBaseStyle
            url={process.env.REACT_APP_ELASTICBEHAVIOR}
            app={process.env.REACT_APP_ELASTICINDEX}
          >
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <div className={classes.wrapperForm}>
                  <AppBar
                    className={classNames(
                      classes.contentWidth,
                      classes.appSearch,
                      { [classes.contentWidthShift]: this.state.isOpen }
                    )}
                    position="static"
                    color="inherit"
                    elevation={0}>
                    <Toolbar className={classes.inputSearch}>
                      {/* <div className={classes.flex}>
                        <div className={classes.wrapper}> */}
                      <div className={classes.search}>
                        <SearchIcon />
                      </div>
                      <DataSearch
                        showIcon={false}
                        filterLabel="Buscar"
                        className={classes.input}
                        dataField={["menu_name", "sec_name", "cont_name", "cont_description", "cont_program"]}
                        componentId="SearchSensor"
                        innerClass={{
                          input: 'search-input'
                        }}
                        placeholder="Buscar"
                        queryFormat="and"
                        react={{
                          and: ['SectionSensor', "DateSensor", 'KickerSensor']
                        }}
                        onValueSelected={(value)=>this.setState({ searchValue: value })}
                        onValueChange={value => value==""?this.setState({ searchValue: value }):null}
                      />
                      {/* </div> */}
                      
                    </Toolbar>
                  </AppBar>
                  <div className={classes.contentFilters}>
                    <Button
                      onClick={() => this.handleOnClose(true)}
                      className={classes.filtroBtn}>
                      <FilterList /> Filtros
                    </Button>
                    <div className={classes.contentRange}>
                      <div className={classes.search}>
                        <Today />
                      </div>
                      <DateRange
                        componentId="DateSensor"
                        dataField="publish_date"
                        initialMonth={new Date()}
                        className={classes.inputDate}
                        innerClass={{
                          overlayWrapper: 'theme',
                        }}
                        filterLabel="Fecha"
                        react={{
                          and: ['SearchSensor', 'SectionSensor', 'KickerSensor']
                        }}
                        // defaultValue={this.state.date}
                        showClear={false}
                        inputProps={{
                          readOnly: true
                        }}
                        dayPickerInputProps={{
                          dayPickerProps: {
                            locale: 'es',
                            localeUtils: MomentLocaleUtils,
                          }
                        }}
                        placeholder={{
                          start: 'Fecha inicio',
                          end: 'Fecha fin'
                        }}
                        customQuery={
                          this.query
                        }
                        onValueChange={value => this.setState({ dateValue: value })}
                      />
                    </div>
                  </div>
                </div>
                
                <Drawer anchor="right" variant="persistent" open={this.state.isOpen} onClose={() => this.handleOnClose(false)} className={classes.drawer}>
                  <div className={classes.drawerHeader}>
                    <Typography variant="title" component="h5" className={classes.titleFil}>
                      Filtros
                    </Typography>
                    <IconButton onClick={() => this.handleOnClose(false)}>
                      {<KeyboardTab />}
                    </IconButton>
                  </div>
                  <Divider />
                  <div className={classes.filterContainer}>
                    <div className={classes.filterForm}>
                      <MultiDataList
                        componentId="SectionSensor"
                        dataField="sec_name.keyword"
                        data={this.state.availableSection}
                        title="Secciones disponibles"
                        // selectAllLabel="Seleccionar Todo"
                        showCheckbox={true}
                        showSearch={false}
                        filterLabel="Sección"
                        placeholder="Buscar Sección"
                        showFilter={true}
                        innerClass={{
                          list: classes.wraper,
                          count: classes.count,
                          checkbox: classes.checkFilterInput,
                          label: classes.checkFilterLabel
                        }}
                        react={{
                          and: ['SearchSensor', "DateSensor", 'KickerSensor']
                        }}
                        loader="Cargando..."
                        onValueChange={value => this.setState({ sectionValue: value })}
                      />
                      <MultiDataList
                        componentId="KickerSensor"
                        dataField="cont_program.keyword"
                        data={this.state.kicker}
                        title="Kicker"
                        selectAllLabe={false}
                        showCheckbox={true}
                        showSearch={false}
                        filterLabel="Kicker"
                        showFilter={true}
                        innerClass={{
                          list: classes.wraper,
                          count: classes.count,
                          checkbox: classes.checkFilterInput,
                          label: classes.checkFilterLabel
                        }}
                        react={{
                          and: ['SearchSensor', 'SectionSensor', "DateSensor"]
                        }}
                        loader="Cargando..."
                        onValueChange={value => this.setState({ kickerValue: value })}
                      />
                    </div>
                  </div>
                </Drawer>
              </Grid>


              <Grid item xs={12}>
                <SelectedFilters clearAllLabel="Limpiar filtros"  showClearAll={false}/>
              </Grid>
              {/* RESULTS */}
              <Grid item xs={12}>
                {(this.state.searchValue==""&&
                  this.state.sectionValue.length==0&&
                  this.state.kickerValue.length==0&&
                  this.state.dateValue==null)?
                  <div className={classes.contentNull}><label style={{ color: '#A9A9A9', fontSize: 14 }}>Buscar secciones y notas</label></div>: 
                <ReactiveList
                  className={classNames("right-col", classes.contentWidth, { [classes.contentWidthShift]: this.state.isOpen })}
                  componentId="SearchResult"
                  //dataField={["menu_name","sec_name","sec_tipo", "cont_name","cont_description"]}
                  dataField={"cont_name"}
                  size={100}
                  pagination={true}
                  loader="Cargando resultados..."
                  renderResultStats={
                    function (stats) {
                      return (
                        ``/*`${stats.numberOfResults} resultados encontrados en ${stats.time} ms`*/
                      )
                    }
                  }
                  defaultQuery={() => {
                    return {
                      query: {
                        match: {
                          menu_name: this.state.currentMenu
                        },
                      },
                      "sort": [
                        {
                          // "sec_order": {
                          //   "order": "asc"
                          // },
                          publish_id:{
                            order : "desc"
                          }
                        }
                      ]
                    }
                  }
                  }
                  renderNoResults={() => { return (<div className={classes.contentNull}><label style={{ color: '#A9A9A9', fontSize: 14 }}>Ningún ítem coincide con tu búsqueda</label></div>) }}
                  paginationAt="bottom"
                  innerClass={{
                    pagination: 'page',
                    active: 'activeBtn'
                  }}
                  react={{
                    and: ['SearchSensor', 'SectionSensor', "DateSensor", 'KickerSensor'],
                  }}
                  render={
                    ({ loading,
                      error,
                      data,
                      streamData,
                      promotedData,
                      rawData,
                      resultStats,
                      handleLoadMore,
                      triggerAnalytics }) => {

                      return this.renderCard(data);
                    }
                  }
                />}
              </Grid>
            </Grid>
          </ReactiveBaseStyle>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={this.state.openNotification}
            autoHideDuration={6000}
            onClose={this.handleCloseNotification}>
            <CustomNotification
              onClose={this.handleCloseNotification}
              variant={this.state.typeNotification}
              message={this.state.messageNotification} />
          </Snackbar>
          <Footer loadSynthesis={(item) => { this.handleLoadSyntheis(item) }} menu={this.state.synthesisAvailable} section={this.state.synthesisSections} />
        </div>
      );
    } else{
    return <LoaderFull/>
    }
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
};

Search.contextType = ThemeContext;

export default withStyles(styles)(Search);
