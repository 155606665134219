import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import styles from "./cardStyle-jss";
import Typography from "@material-ui/core/Typography";
import { S3Image } from "aws-amplify-react";
import { getUrlImage } from "../../service/s3Amplify";

const validateUrl = value =>
  value && /^(ftp|http|https):\/\/[^ "]+$/.test(value) ? "Invalid" : undefined;

class ColumnCard extends React.Component {

  state = {
    url: ""
  };

  componentWillReceiveProps(newProps) {
    if (!validateUrl(newProps.image)) {
      getUrlImage(newProps.image).then(response => {
        this.setState({
          url: response
        });
      });
    } else {
      this.setState({
        url: newProps.image
      });
    }
  }

  getColorTitle(secColor){
    if(secColor&& (secColor.includes("#")&&secColor.toLowerCase()!="#ffffff")){
      return "#FFFFFF"
    }
  }

  getColorButton(secColor){
    if(secColor.includes("#")){
      return secColor
    }else{
      return "#FFFFFF"
    }
  }
  
  render() {
    const {
      classes,
      title,
      autor,
      description,
      small,
      image,
      attachment,
      busqueda,
      publish_date,
      colorTitle,
      sendEvent
    } = this.props;

    return (
      <Card
        className={classNames(classes.cardColumn, busqueda && classes.busqueda)}
      >
        {/* CH 21/06/2019  Se valída si hay imágen */}
        {image && (
          <div className={classNames(
            classes.mediaColumn,
            small && classes.mediaColumnSmall
          )}>
          <S3Image
            imgKey={image}
          />
          </div>
        )}
        <CardContent className={classes.contenidoColumn}>
          {autor && (
            <Typography component="p" className={classes.kicker} 
            style={{color:this.getColorTitle(colorTitle)}}
            >
              {autor}
            </Typography>
          )}
          {publish_date && (
            <Typography component="small" className={classes.date}>
              {publish_date}
            </Typography>
          )}
          <Typography
            className={classNames(
              classes.mb1,
              small ? classes.title : classes.titleWrap2
            )}
            style={{color:this.getColorTitle(colorTitle)}}
          >
            {title}
          </Typography>
          
          <Typography component="p" className={classes.descriptionWrap8}>
            <span
              className={classes.textDescriptionWrap8}
              style={{ color: colorTitle&&colorTitle.includes("#")&&colorTitle.toLowerCase()!="#ffffff"?"white":"" }}
              dangerouslySetInnerHTML={{ __html: `${description}` }}
            />
          </Typography>
          <Button
            onClick={sendEvent}
            href={`/multimedia/${encodeURIComponent(attachment)}`}
            target="_blank"
            className={classNames(classes.colorPrimary, classes.button)}
            style={{backgroundColor:this.getColorTitle(colorTitle), color:this.getColorButton(colorTitle)}}
          >
            Leer
          </Button>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(ColumnCard);
