import { fade } from '@material-ui/core/styles/colorManipulator';
import { hexToDarkHex, hexToLightHex } from '../../utils/convertColor'

const styles = theme => {
  return {
  root:{
    display: 'flex',
    boxShadow: 'none !important',
    position: 'relative',
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    '& a':{
      color: 'inherit'
    }
  },
  column:{
    flexDirection: 'column',
  },
  contentLogo:{
    paddingLeft: 20,
    '& > div':{
      display: 'flex',
      alignItems: 'center'
    },
  },
  logo:{
    height: 65,
    marginLeft: 16,
  },
  logoM:{
    height: 65,
    marginLeft: 16,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      width: '100%',
      maxHeight: '52px',
      maxWidth: '140px',
      margin: 0,
    },
  },
  colorWhite:{
    color: 'white'
  },
  colorDark:{
    color: '#000000'
  },
  buttonHeader:{
    marginLeft: theme.spacing.unit,
    height: 30,
    fontSize: 11,
    '& svg':{
      fontSize: 18
    }
  },
  iconButtonHeader:{
    marginLeft: theme.spacing.unit,
    height: 32,
    width: 32,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '& svg':{
      fontSize: 18,
      fill: '#000000'
    }
  },
  avatarBlue: {
    backgroundColor: theme.palette.primary.main,
    '& svg': {
      fill: '#ffffff'
    }
  },
  appText:{
    color: theme.palette.primary.main,
    fontSize: '.75rem',
    marginLeft: theme.spacing.unit*2,
  },
  no440:{
    display: 'inline-block',
    marginRight: 24,
    '@media (max-width: 440px)': {
      display: 'none',
    }
  },
  appBar: {
    background: 'rgba(255,255,255,.0)',
    position: 'relative',
    zIndex: theme.zIndex.drawer + 1,
    height: 75,
    paddingTop: 15,
    color: theme.palette.common.black,
    transition: theme.transitions.create(['width', 'margin', 'background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none !important',
    '& ::-webkit-input-placeholder': { /* Chrome/Opera/Safari */
      color: 'rgba(0,0,0,.7)'
    },
    '& ::-moz-placeholder': { /* Firefox 19+ */
      color: 'rgba(0,0,0,.7)'
    },
    '& :-ms-input-placeholder': { /* IE 10+ */
      color: 'rgba(0,0,0,.7)'
    },
    '& :-moz-placeholder': { /* Firefox 18- */
      color: 'rgba(0,0,0,.7)'
    },
    '& $menuButton': {
      marginLeft: theme.spacing.unit * 2
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 75,
      height: 75
    },
  },
  flex: {
    flex: 1,
    textAlign: 'right'
  },
  menuButton: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
    }
  },
  hide: {
    display: 'none',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  wrapper: {
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    marginRight: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    borderRadius: 2,
    background: fade(theme.palette.common.white, 0.15),
    display: 'inline-block',
    '&:hover': {
      background: fade(theme.palette.common.white, 0.25),
    },
    '& $input': {
      transition: theme.transitions.create('width'),
      width: 180,
      '&:focus': {
        width: 350,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
  },
  search: {
    width: theme.spacing.unit * 12,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    font: 'inherit',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit * 8}px`,
    border: 0,
    display: 'block',
    verticalAlign: 'middle',
    whiteSpace: 'normal',
    background: 'none',
    margin: 0, // Reset for Safari
    color: 'inherit',
    width: '100%',
    '&:focus': {
      outline: 0,
    },
  },
  userMenu: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing.unit,
  },
  popperClose: {
    pointerEvents: 'none',
    zIndex: 2
  },
  darker: {
    background: theme.palette.primary.dark,
    '&:after': {
      content: '""',
      left: -240,
      width: 'calc(100% + 240px)',
      position: 'absolute',
    }
  },
  separatorV: {
    borderLeft: `1px solid #000`,
    height: 20,
    margin: '0 10px',
    opacity: 0.4
  },
  notifMenu: {
    paddingBottom: 0,
    width: 350,
    '& li': {
      height: 'auto',
      '& h3': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }
    },
    '& div[class*="MuiListSubheader"]': {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      color: '#272727',
      fontSize: 16,
    }
  },
  notifZindex: {
    zIndex: 999,
  },
  badgeMenu: {
    '& span': {
      backgroundColor: hexToDarkHex(theme.palette.primary.main, 50),
    }
  },
  badgeMenuLight: {
    '& span': {
      backgroundColor: hexToLightHex(theme.palette.primary.main, 80),
    }
  },
  badge: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    backgroundColor: '#08EE83',
    color: '#424242',
    minWidth: 24,
    height: 24,
    borderRadius: 12,
    textAlign: 'center'
  },
  textNotif: {
    paddingLeft: 11,
    '& > div div:last-child span':{
      fontSize: '0.8rem',
    },
    '& > div div:last-child p':{
      fontSize: '0.7rem',
    },
    '& span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  avatarMin: {
    border: '2px solid #FFFFFF',
    '& div':{
      width: '100%',
      height: '100%',
      '& img': {
        height: '100%',
        objectFit: 'cover'
      }
    }
  },
  avatar: {
    '& div':{
      width: '100%',
      height: '100%',
      '& img': {
        height: '100%',
        objectFit: 'cover'
      }
    }
  },
  email: {
    display: 'block',
    fontSize: 13,
    opacity: 0.8
  },
  datHeader: {
    display: 'inline-block',
    fontSize: 14,
    marginRight: 16,
    '& svg': {
      fontSize: 14,
      marginRight: 5
    },
    '& span': {
      whiteSpace: 'nowrap'
    }
  },
  borderB: {
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 10,
      left: 36,
      width: 200,
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  },
  noDecoration: {
    textDecoration: 'none'
  },
  noPaddingList: {
    padding: "0",
    '& li':{
      height: "18px"
    },
    '@media (max-width: 440px)': {
      marginTop: "-30px",
    }
  },
}};

export default styles;
