
export function hexToRgb(hex) {
    hex = hex.replace(/^#/, '');
  
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
  
    return { r, g, b };
  }
  
  export function darkenColor(rgb, percent) {
    const factor = 1 - percent / 100;
    const r = Math.floor(rgb.r * factor);
    const g = Math.floor(rgb.g * factor);
    const b = Math.floor(rgb.b * factor);
    return { r, g, b };
  }
  
  export function rgbToHex(rgb) {
    const toHex = value => {
      const hex = value.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
  
    const { r, g, b } = rgb;
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }


  export function hexToDarkHex(hex, percent) {
    hex = hex.replace(/^#/, '');
  
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
  
    const factor = 1 - percent / 100;
    const darkenedR = Math.floor(r * factor);
    const darkenedG = Math.floor(g * factor);
    const darkenedB = Math.floor(b * factor);
  
    const darkenedHex = `#${(darkenedR << 16 | darkenedG << 8 | darkenedB).toString(16).padStart(6, '0')}`;
  
    return darkenedHex;
  }

  export function hexToLightHex(hex, percent) {
    hex = hex.replace(/^#/, '');
  
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
  
    const factor = 1 + percent / 100;
    const lightenedR = Math.min(Math.floor(r * factor), 255);
    const lightenedG = Math.min(Math.floor(g * factor), 255);
    const lightenedB = Math.min(Math.floor(b * factor), 255);
  
    const lightenedHex = `#${(lightenedR << 16 | lightenedG << 8 | lightenedB).toString(16).padStart(6, '0')}`;
  
    return lightenedHex;
  }