import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

const styles = theme => ({
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ConfirmDialog extends React.Component {
  state = {
    open: false,
    openSlide: false,
  };

  render() {
    const { 
        title,
        message,
        textAccept,
        textCancel,
        funcAccept,
        funcCancel,
        open
    } = this.props;
    return (
      <div>
        <Grid container spacing={16}>
          <Grid item md={6}>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={funcCancel}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {title}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={funcCancel} color="primary">
                  {textCancel}
                </Button>
                <Button onClick={funcAccept} color="primary">
                  {textAccept}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ConfirmDialog.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    textAccept: PropTypes.string,
    textCancel: PropTypes.string,
    funcAccept: PropTypes.func,
    funcCancel: PropTypes.func,
    open: PropTypes.bool
};

export default withStyles(styles)(ConfirmDialog);