import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './sintesisMenu-jss';
import { ThemeContext } from '../../../utils/ThemeContext';
import {gaPageHit} from '../../../service/service_base'
import {_getDate} from '../../../utils/dateFormat'
function SintesisMenu(props) {
  const {classes, loadSynthesis, isSearching } = props;
  
  return (
    <ThemeContext.Consumer>
      {({toggleTheme}) => (
        <div>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid
              container
              className={classes.demo}
              alignItems="center"
              direction="row"
              justify="center"
            >
              <Grid item align="center">
                <div className={classes.inlineFlex}>
                {
                  props.synthesis.map((item, index) => {
                    return (
                      <span 
                        href={`#${item.menu_name}`} 
                        onClick={() => {
                          toggleTheme(item);
                          loadSynthesis(item);
                          gaPageHit(item.menu_name);
                        }} 
                        key={index.toString()} 
                        className={classNames(classes.link, props.selected === item.menu_id ? classes.active : '')}
                        id={item.menu_name}>
                          {item.menu_name}
                      </span>
                    )
                  })
                }
                </div>
              </Grid>
            </Grid>
          </Grid>
          {!isSearching?<Grid item xs={12} align="center" style={{fontSize:14,marginBottom:10}}>
              <span>{props.synthesis.find(e=>props.selected === e.menu_id)?
              `Publicado el ${_getDate(props.synthesis.find(e=>props.selected === e.menu_id).lastPublish).toLowerCase()}`:""}</span>
          </Grid>:""}
        </Grid>
      </div>
      )}
    </ThemeContext.Consumer>
  );
}

SintesisMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SintesisMenu);
