import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import classNames from 'classnames';
import styles from './cardStyle-jss';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {getUrlImage} from '../../service/s3Amplify'
import { S3Image } from 'aws-amplify-react';

const validateUrl = value => (
  value && /^(ftp|http|https):\/\/[^ "]+$/.test(value)
    ? 'Invalid'
    : undefined
);

class CartoonCard extends React.Component {

  state = {
    url : ''
  }

  componentWillReceiveProps(newProps){
    if(!validateUrl(newProps.image)){
      getUrlImage(newProps.image).then(
        response => {          
          this.setState(
            {
              url : response
            }
          )
        }
      )
    }else{
      this.setState(
        {
          url : newProps.image
        }
      )
    }
  }

  _transformDate = (publish_date) => {
    return "";
    // return new Date(publish_date).toString();
  }

  getColorTitle(secColor){
    if(secColor&& (secColor.includes("#")&&secColor.toLowerCase()!="#ffffff")){
      return "#FFFFFF"
    }else{
      return ""
    }
  }

 getColorButton(secColor){
    if(secColor.includes("#")){
      return secColor
    }else{
      return "#FFFFFF"
    }
  }

  render() {
    const {
      classes,
      title,
      image,
      attachment,
      busqueda,
      publish_date,
      sendEvent,
      colorTitle
    } = this.props;
    return (
      <Card className={classNames(classes.cardCartoon, classes.cardMedia, busqueda && classes.busqueda)}>
        
        <div className={classes.mediaCartoon}>
        <S3Image imgKey={image} />
        </div> 
        <CardContent className={classes.contenidoCartoon}>
          <Typography className={classes.title} style={{color:this.getColorTitle(colorTitle)}}>{title}</Typography>
          <Button onClick={sendEvent} href={`/multimedia/${encodeURIComponent(attachment)}`} target="_blank" className={classNames(classes.colorPrimary, classes.button)} 
          style={{backgroundColor:this.getColorTitle(colorTitle), color:this.getColorButton(colorTitle)}}>VER MÁS</Button>
        </CardContent>
            {publish_date && <Typography component="small" className={classes.date}>{publish_date}</Typography>}
      </Card>
    );
  }
}

export default withStyles(styles)(CartoonCard);
