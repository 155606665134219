import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import styles from './slider-jss'
import '../../styles/components/vendors/slick-carousel/slick-carousel.css';
import '../../styles/components/vendors/slick-carousel/slick.css';
import '../../styles/components/vendors/slick-carousel/slick-theme.css';
import { fetchCall } from '../../service/service_base';
// CARTS
import CartoonCard from '../CardPaper/CartoonCard';
import {gaEventHit} from '../../service/service_base';


class SliderCartones extends React.Component {

  state = {
    cards: [],
    menuname:null
  }
  componentDidMount() {
    if (!this.props.isSearching) {
      this._load();
    } else {
      if (this.props.section) {
        this.setState(
          {
            cards: []
          }, () => {
            this.setState(
              {
                cards: this.props.section.content
              }
            )
          }
        )
      }
    }
  }

  _load = () => {
    fetchCall("POST", "/synthesis/getcontentpublish", {
      sectionId: this.props.section.sec_id,
      menuId: this.props.idMenuSelected
    }).then(response => {
      let sm = JSON.parse(localStorage.getItem("session_menu"))
        const menuname = sm.find(x => x.menu_id === this.props.idMenuSelected);
      this.setState({ cards: response.data, menuname:menuname.menu_name });
    }).catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isSearching && nextProps.section) {
      this.setState({
        cards: nextProps.section.content
      });
    }
  }

  sendEvent=()=>{
    if(this.state.menuname)
      gaEventHit(this.state.menuname,this.props.section.sec_name)
  }

  render() {
    const { isSearching,section } = this.props;

    return (
      <div id={this.props.sec_name} className="container custom-arrow">
        <Grid container spacing={24}>
          {this.state.cards.map((item, key) => {
            if (item.cont_key)
              item.cont_imagePath = item.cont_key;
            return <Grid item lg={4} md={4} sm={6} xs={12} key={key}>
              <CartoonCard
                image={isSearching ? item.cont_imagePath : item.cont_key}
                title={item.cont_name}
                attachment={isSearching ? item.cont_attachment : item.cont_attachmentKey}
                publish_date={item.publish_date}
                colorTitle={section.sec_color}
                sendEvent={this.sendEvent}
              />
            </Grid>
          })}
        </Grid>
      </div>
    );
  }
}

SliderCartones.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SliderCartones);