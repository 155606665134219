import React from 'react';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import ls from 'local-storage';
import Snackbar from '@material-ui/core/Snackbar';

import brand from '../../../utils/brand';
import PapperBlock from '../../../components/PapperBlock/PapperBlock';
import SliderCard from '../../../components/Sliders/SliderCard';
import SliderEditoriales from '../../../components/Sliders/SliderEditoriales';
import SliderCartones from '../../../components/Sliders/SliderCartones';
import SliderRadio from '../../../components/Sliders/SliderRadio';
import Event from '../../../components/Event/Event';
import Portadas from '../../../components/Portadas/Portadas';
import CardButton from '../../../components/CardButton/CardButton';
import Banner from '../../../components/Banner';
import SintesisMenu from '../../../components/Header/SintesisMenu';
import TapMenu from '../../../components/Header/TapMenu';
import styles from './new-jss';
import SliderTap from '../../../components/Sliders/SliderTap';
import { fetchCall, gaPageHit } from '../../../service/service_base';
import LoaderFull from '../../../components/Loader/LoaderFull';
import '../../../styles/normalize.scss';
import '../../../styles/layout/_base.scss';
import Footer from '../../../components/Footer/Footer';
import { ThemeContext } from '../../../utils/ThemeContext';
import CustomNotification from '../../../components/Notification/CustomNotification';
import classNames from 'classnames';

class BlankPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idMenuSelected: ls.get('menuSelected') ? ls.get('menuSelected') : 8,
      synthesisAvailable: [],
      synthesisSections: [],
      isLoading: true,
      openNotification: false,
      messageNotification: '',
      typeNotification: "success"
    }
  }



  componentDidMount() {
    this._loadSynthesis();
  }

  handleCloseNotification = () => {
    this.setState(
      {
        openNotification: false
      }
    )
  }

  _loadSynthesis = () => {
    const synthesis=ls.get("session_menu");
        let available = [];
          if (synthesis) {
            let parse = JSON.parse(`[${ls.get('current_user').availableSynthesis}]`);
            if (parse) {
              synthesis.forEach(item => {
                let search = parse.find(x => parseInt(x.id) === parseInt(item.menu_id));
                if (search && item.lastPublish && item.menu_public==0)
                  available.push(item);
              });
            }
            if (available.length === 0) {
              this.setState(
                {
                  openNotification: true,
                  messageNotification: 'No existen síntesis publicadas',
                  typeNotification: "error",
                  isLoading: false
                }
              )
              setTimeout(() => {
                ls.clear();
                window.location.href = "";
              }, 2000);
            } else {
              this.setState({
                synthesisAvailable: available,
                _alreadyRenderPortrait: true,
                idMenuSelected: ls.get('menuSelected') ? ls.get('menuSelected') : available[0].menu_id
              }, () => {
                let t = available.find(x => x.menu_id === ls.get('menuSelected'));
                this.context.toggleTheme(t ? t : available[0]);
                this._loadSectionBySynthesis(this.state.idMenuSelected);
                gaPageHit(available[0].menu_name);
              });
            }
          }else{
            this.setState({
              messageNotification: "No se encontraron síntesis publicadas",
              typeNotification: 'error',
              openNotification: true,
              isLoading: false
            });
            window.location.href = "/";
          }
  }

  _loadSectionBySynthesis = (menu_id) => {
    this.setState({
      synthesisSections: []
    }, () => {
      fetchCall("POST", "/synthesis/getsection", {
        menuId: menu_id,
        portal: 'public'
      }).then(response => {
        let totalContent = 0;
        let sec_id = []
        let sec_orden = 0
        response.data.map((item) => {
          if (item.sec_tipo === "NotasEditoriales" || item.sec_tipo === "Card") {
            sec_orden = item.sec_orden
            sec_id.push(item.sec_id)
          }
          if(item.sec_tipo==="Portada"){
            item.sec_id=[item.sec_id]
          }
          totalContent += item.sec_totalContent;
          return null;
        });
        response.data.push({sec_tipo:"EditorialCard", sec_id, sec_orden })
        if (totalContent === 0) {
          this.setState(
            {
              openNotification: true,
              messageNotification: `No se encontró contenido para la síntesis ${this.state.synthesisAvailable.find(x => x.menu_id === this.state.idMenuSelected).menu_name}, serás redireccionado a la síntesis ${this.state.synthesisAvailable[0].menu_name}`,
              typeNotification: "error",
              isLoading: false,
              idMenuSelected: this.state.synthesisAvailable[0].menu_id
            }
          );

          setTimeout(() => {
            this.context.toggleTheme(this.state.synthesisAvailable[0]);
            this._loadSectionBySynthesis(this.state.synthesisAvailable[0].menu_id);
          }, 3000)

        } else {
          ls.set('menuSelected', this.state.idMenuSelected)
          this.setState({
            synthesisSections: response.data.sort((a,b)=>a.sec_orden - b.sec_orden),
            isLoading: false
          });
        }

      }).catch(err => console.log(err));
    });
  }

  handleLoadSyntheis = (menu) => {
    this.setState({
      idMenuSelected: menu.menu_id,
      isLoading: true
    }, () => {
      this._loadSectionBySynthesis(this.state.idMenuSelected);
    })
  }

  hideSection = (item) => {
    this.setState({
      [`haveContent_${item.sec_id}`]: false
    });
  }

  render() {

    const title = brand.name;
    const description = brand.desc;
    const {classes} = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>

        <SintesisMenu loadSynthesis={(item) => { this.handleLoadSyntheis(item) }} synthesis={this.state.synthesisAvailable} selected={this.state.idMenuSelected} />

        <TapMenu state={this.state} availableSection={this.state.synthesisSections} />

        {
          this.state.isLoading ? <LoaderFull /> :
            this.state.synthesisSections.map((item, key) => {
              switch (item.sec_tipo) {
                case "Header":
                  item.idMenuSelected=this.state.idMenuSelected;
                  this.context.toggleHeader(item);
                  return null;
                case "Banner":
                  return <Banner section={item} idMenuSelected={this.state.idMenuSelected} key={key} />
                case "Relevante":
                  return item.sec_totalContent > 0 ?
                    <SliderTap section={item} idMenuSelected={this.state.idMenuSelected} isVisible={item.sec_totalContent > 0} key={key} />
                    : ""
                case "Portada":
                  return (
                  <div id={item.sec_name ? item.sec_name.toString().trim() : ""} style={{ display: item.sec_totalContent > 0 ? 'block' : 'none' }} key={key}>
                    <div style={{ backgroundColor: item.sec_color, paddingTop:25}}>
                      <PapperBlock secName={item.sec_name.toString().trim()} img={item.sec_icono} isVisible={item.sec_totalContent > 0} title={item.sec_name} colorTitle={item.sec_color} colorMode>
                        {/* <div className={classes.WrapperButton}><CardButton type="Portada" section={item} idMenuSelected={this.state.idMenuSelected} key={key} /></div> */}
                        <Portadas section={item} idMenuSelected={this.state.idMenuSelected} key={key} />
                      </PapperBlock>
                    </div></div>
                  )
                case "Notas":
                  return (
                    <div id={item.sec_name ? item.sec_name.toString().trim() : ""} style={{ display: item.sec_totalContent > 0 ? 'block' : 'none' }} key={key}>
                      <div style={{ backgroundColor: item.sec_color, paddingTop: 25, marginTop:item.sec_color !== '#ffffff' && 25, paddingBottom: item.sec_color === '#ffffff' ? 0 : 50 }}>
                        <PapperBlock secName={item.sec_name.toString().trim()} img={item.sec_icono} isVisible={item.sec_totalContent > 0} title={item.sec_name} colorTitle={item.sec_color} colorMode>
                          <SliderCard section={item} idMenuSelected={this.state.idMenuSelected} />
                        </PapperBlock>
                      </div></div>
                  )
                case "NotasColumnas":
                  return (
                    <div id={item.sec_name ? item.sec_name.toString().trim() : ""} style={{ display: item.sec_totalContent > 0 ? 'block' : 'none' }} key={key}>
                      <div style={{ backgroundColor: item.sec_color, paddingTop:25 }}>
                        <PapperBlock secName={item.sec_name.toString().trim()} img={item.sec_icono} isVisible={item.sec_totalContent > 0} title={item.sec_name} colorTitle={item.sec_color} colorMode>
                          <SliderEditoriales section={item} idMenuSelected={this.state.idMenuSelected} autor />
                        </PapperBlock>
                      </div></div>
                  )
                case "EditorialCard":
                  return (
                    <div id="EditorialCard" key={key}>
                      <div style={{ backgroundColor: item.sec_color, paddingTop:25 }}>
                        <div className={classNames('container', classes.paddingContent, classes.WrapperButton)}>
                          <CardButton type={item.sec_tipo} section={item} idMenuSelected={this.state.idMenuSelected} key={key} />
                        </div>
                      </div>
                    </div>
                    )
                case "Radio":
                  return (
                    <div id={item.sec_name ? item.sec_name.toString().trim() : ""} style={{ display: item.sec_totalContent > 0 ? 'block' : 'none' }} key={key}>
                      <div style={{ backgroundColor: item.sec_color, paddingTop:25 }}>
                        <PapperBlock secName={item.sec_name.toString().trim()} img={item.sec_icono} isVisible={item.sec_totalContent > 0} title={item.sec_name} colorTitle={item.sec_color} colorMode>
                          <SliderRadio section={item} idMenuSelected={this.state.idMenuSelected} />
                        </PapperBlock>
                      </div></div>
                  )
                case "Evento":
                  return (
                    <div id={item.sec_name ? item.sec_name.toString().trim() : ""} style={{ display: item.sec_totalContent > 0 ? 'block' : 'none' }} key={key}>
                      <div style={{ backgroundColor: item.sec_color, paddingTop:25 }}>
                        <PapperBlock secName={item.sec_name.toString().trim()} img={item.sec_icono} isVisible={item.sec_totalContent > 0} title={item.sec_name} colorTitle={item.sec_color} colorMode>
                          <Event section={item} idMenuSelected={this.state.idMenuSelected} />
                        </PapperBlock>
                      </div></div>
                  )
                case "Footer":
                  item.idMenuSelected=this.state.idMenuSelected;
                  this.context.toggleFooter(item);
                  return null;
                default:
                  return ""
              }
            })
        }

        <Footer loadSynthesis={(item) => { this.handleLoadSyntheis(item) }} menu={this.state.synthesisAvailable} section={this.state.synthesisSections} selected={this.state.idMenuSelected} />

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
          <CustomNotification
            onClose={this.handleCloseNotification}
            variant={this.state.typeNotification}
            message={this.state.messageNotification} />
        </Snackbar>

      </React.Fragment>
    );
  }
}

BlankPage.contextType = ThemeContext;

export default withStyles(styles)(BlankPage);
