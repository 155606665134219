import { hexToDarkHex } from '../../utils/convertColor'

const styles = theme => ({
  
  CardButton: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  btnCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '10px 20px',
    fontSize: 20,
    gap: '10px',
    lineHeight: 1.1,
    borderRadius: 28,
    height: 56,
    textDecoration: 'none',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: hexToDarkHex(theme.palette.primary.main, 20),
      transition: theme.transitions.create('', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen * 2,
      }),
    },
    '& svg': {
      fontSize: 26,
      width: 26,
      height: 26,
    }
  },
  alignBtn: {
    width: '100%',
    textAlign: 'center',
    '& a': {
      display: 'inline-block',
      width: '100%',
      maxWidth: '290px',
      '& >div':{
        '& >div': {
          '& img': {
            height: 'auto',
            objectFit: 'contain',
            width: '100%',
          }
        }
      }
    }
  },
  date: {
    fontSize: '.8rem',
    fontFamily: "Roboto, Oswald",
  },
});

export default styles;
