import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Manager, Target, Popper } from 'react-popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Notification from '@material-ui/icons/Notifications';
import ExitToApp from '@material-ui/icons/ExitToApp';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import Badge from '@material-ui/core/Badge';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import dummy from '../../utils/dummyContents';
import styles from './header-jss';
import '../../styles/components/Messages.scss';
import ls from 'local-storage';
import { cognitoLogOut } from '../../service/cognitoService';
import { S3Image } from 'aws-amplify-react';
import ListItemText from '@material-ui/core/ListItemText';
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import { fetchCall } from '../../service/service_base';
import avatar from '../../../static/images/avatars/pp_boy4.svg';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ListSubheader } from '@material-ui/core';

const SessionDialog = props => {
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={props.open}>
      <DialogTitle id="alert-dialog-title">{"Tu sesión ha expirado"}</DialogTitle>
      <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Debes volver a ingresar tus credenciales para seguir en el portal
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.onClose()} color="primary">
            Ok
          </Button>
        </DialogActions>
    </Dialog>
  );
};
const SessionDialogWrapped = withStyles(styles)(SessionDialog);

class UserMenu extends React.Component {

  state = {
    openMenu: null,
    notifications: [],
    openSession:false
  };

  componentDidMount() {
    this.notifications();
    this.interval = setInterval(this.checkLastActivity, 10000);//10seg
    // this.intervalSession= setInterval(() => {
    //     clearInterval(this.interval);
    //    this.setState({openSession:true})
    // }, (1000 * 60 * 30));//30min
  }
  componentWillUnmount(){
    clearInterval(this.interval);
    // clearInterval(this.intervalSession);
  }
  checkLastActivity = () => {
      let currentTime = new Date().getTime(); 
      const lastTime = localStorage.getItem("last_activity"); 

      if(lastTime){
          let diff = (currentTime - lastTime)/1000; 
          diff = diff/60; 
          if(diff >= 30){
              this.setState({openSession:true})
              return true; 
          }else{
              this.notifications()
              return false; 
          }
      }
  }
  notifications() {
    fetchCall("POST", "/notification/get", {
      userId: ls.get('current_user').user_id,
    },true).then(
      response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.setState({ notifications: response.data })
          }
        }
      }
    ).catch(
      err => {
        this.setState(
          {
            openNotification: true,
            messageNotification: 'Ocurrió un error al obtener las notificaciones',
            typeNotification: "error"
          }
        )
      }
    );
  }


  handleMenu = menu => {
    this.setState({
      openMenu: this.state.openMenu === menu ? null : menu,
    });
  };

  handleLogOut = () => {
    cognitoLogOut().then(response => { console.log(response) }).catch(err => { console.log(err) });
    ls.clear();
    window.location.href = "/";
  }

  handleCloseToggle = () => {
    if (this.state.openMenu === "notification") {
      this.handleNotifications();
    }
    this.setState({ openMenu: null });
  };

  handleNotifications = () => {
    if (this.state.notifications.length > 0) {
      fetchCall("POST", "/notification/update", {
        userId: ls.get('current_user').user_id,
      }).then(
        response => {
          if (response.status === 200) {
            this.setState({ notifications: [] })
          }
        }
      ).catch(
        err => {
          this.setState(
            {
              openNotification: true,
              messageNotification: 'Ocurrió un error al eliminar las notificaciones',
              typeNotification: "error"
            }
          )
        }
      );
    }
  }

  

  render() {
    const { classes, urlPhoto, color, whiteHeader } = this.props;
    const { openMenu,openSession } = this.state;
    return (
      <div className={classes.userMenu}>
        <SessionDialogWrapped onClose={this.handleLogOut} open={openSession}/>
        {/* ----- Notification Dropdown Menu ----- */}
        <Manager>
          <Target>
            <div
              ref={node => {
                this.target1 = node;
              }}
            >
              <Tooltip title="Notificaciones">
                <IconButton
                  aria-haspopup="true"
                  onClick={() => this.handleMenu('notification')}
                  color={'inherit'}
                >
                  <Badge className={whiteHeader ? classes.badgeMenuLight : classes.badgeMenu} badgeContent={this.state.notifications.length}>
                    <Notification />
                  </Badge>
                </IconButton>
              </Tooltip>
            </div>
          </Target>
          <Popper
            placement="bottom-end"
            eventsEnabled={openMenu === 'notification'}
            className={classNames({ [classes.popperClose]: openMenu !== 'notification' })}
            style={{ zIndex: 1 }}
          >
            <ClickAwayListener onClickAway={this.handleCloseToggle}>
              <Grow in={openMenu === 'notification'} id="menu_notification" style={{ transformOrigin: '0 0 0' }}>
                <Paper className={classes.paper}>
                  <MenuList 
                    role="menu" 
                    className={classes.notifMenu}
                    subheader={<ListSubheader component="div">{this.state.notifications.length === 0 ? 'No existen notificaciones pendientes' : <>Notificaciones <span className={classes.badge}>{this.state.notifications.length}</span></>}</ListSubheader>}
                  >
                    {
                      this.state.notifications.map((item, index) => {
                        return (
                          <>
                          <MenuItem className={classes.textNotif} key={index}>
                            <div className="messageInfo">
                              <Avatar className="icon">
                                <LibraryAdd />
                              </Avatar>
                              <ListItemText primary="Se ha publicado una nueva síntesis" secondary={`${item.not_name} - ${item.not_date}`} />
                            </div>
                          </MenuItem>
                          <Divider />
                          </>
                        );
                      })
                    }
                  </MenuList>
                </Paper>
              </Grow>
            </ClickAwayListener>
          </Popper>

        </Manager>
        {/* ----- End Notification Dropdown Menu ----- */}
        {/* ----- User Setting Dropdown Menu ----- */}
        <Manager className="holaMundo">
          <Target>
            <div
              ref={node => {
                this.target1 = node;
              }}
            >
              <Button onClick={() => this.handleMenu('user-setting')}>
                <Avatar
                  className={classes.avatarMin}
                  alt={dummy.user.name}
                >
                  {urlPhoto && urlPhoto.trim().length > 0 ? <S3Image className={classes.avatarMin} imgKey={urlPhoto} /> : <img src={avatar} alt="avatar"/>}
                </Avatar>
              </Button>
            </div>
          </Target>
          <Popper
            placement="bottom-end"
            eventsEnabled={openMenu === 'user-setting'}
            className={classNames({ [classes.popperClose]: openMenu !== 'user-setting' })}
            style={{ zIndex: 1 }}
          >
            <ClickAwayListener onClickAway={this.handleCloseToggle}>
              <Grow in={openMenu === 'user-setting'} id="user_settint" style={{ transformOrigin: '0 0 0' }}>
                <Paper className={classes.paper} style={{ zIndex: 1 }}>
                  <MenuList role="menu" className={classes.noPaddingList}>
                    <MenuItem disabled style={{color:'#272727', opacity: 1, height: 'auto'}}>
                      <div>
                        {ls.get('current_user').user_name} {ls.get('current_user').user_lastname}
                        <span className={classes.email}>{ls.get('current_user').user_email}</span>
                      </div>
                    </MenuItem>
                    <Divider /> 
                    <MenuItem onTouchEnd={this.handleLogOut} onClick={this.handleLogOut} style={{color: '#272727'}}>
                      <ListItemIcon style={{color: '#272727'}}>
                        <ExitToApp />
                      </ListItemIcon>
                      Cerrar sesión
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Grow>
            </ClickAwayListener>
          </Popper>
        </Manager>
        {/* ----- End User Setting Dropdown Menu ----- */}
      </div>
    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  urlPhoto: PropTypes.string.isRequired
};

export default withStyles(styles)(UserMenu);
