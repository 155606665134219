const styles = theme => ({
  root:{
    backgroundColor: theme.palette.primary.main,
    margin: '4rem 0 0 0',
    '& div[class*="subMenu"]': {
      backgroundColor: 'rgba(0,0,0,.15)',
    },
    '& div[class*="sintesisContent"]': {
      backgroundColor: 'rgba(0,0,0,.30)',
      '& a': {
        padding: "6px",
      },
      '& svg': {
        display: 'none'
      }
    },
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      width: '100%',
      bottom: 0,
      margin: 0, 
      '& div[class*="subMenu"]': {
        display: 'none',
      },
      '& div[class*="customFooter"]': {
        display: 'none'
      },
      '& a[class*="labelIcon"]': {
        minHeight: 60,
        minWidth: 86,
        paddingTop: 8,
      },
      '& div[class*="sintesisContent"]': {
        display: 'flex',
        backgroundColor: theme.palette.primary.main,
        padding: 0,
        width: '100%',
        '& a': {
          display: 'inline-flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          flex: 1,
          alignItems: 'center',
          fontSize: 14,
          padding: "4px 12px",
          margin: 0,
          '& svg': {
            display: 'inline-block',
            height: 32,
            width: 32,
          }
        },
        '& a[class*="active"]': {
          backgroundColor: 'rgba(255,255,255,0.2)'
        }
      },
    },
  },
  activeTab:{
    backgroundColor: 'rgba(255,255,255,0.2)'
  },
  autoScrollable:{
    '& div[class*="scrollButtons"]':{
      flex: '0 0 0 !important',
      display: 'none !important',
    },
    '& button[tabindex*="-1"]':{
      flex: '0 0 32px'
    },
    '& a':{
      opacity: 1,
    },
  },

  link: {
    textDecoration: 'none',
    fontSize: 16,
    color: 'white',
    fontWeight: 300,
    cursor: 'pointer',
    fontFamily: "Roboto, Oswald",
    margin: 20,
    paddingBottom: 8,
    paddingTop: 8,
    '&:hover':{
        opacity: '.7'
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: 16,
        display: 'inline-block',
        marginTop: 0
    },
},
active: {
    color: "white",
    borderBottom: '2px solid',
    borderColor: "white",
}
});

export default styles;
