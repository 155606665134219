import ls from 'local-storage';
import Amplify, { Auth, API} from "aws-amplify";
import aws_exports from '../../aws-exports';
const oauth = {
    domain: process.env.REACT_APP_aws_cognito_domain,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: 'token' // puede ser code o token
};

Amplify.configure(aws_exports);
Auth.configure({oauth});

export const apiPOST = (path, body) => {
    return new Promise(
        async function (resolve, reject) {
            let myInit = {
                body: body,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                }
            }
            API.post(process.env.REACT_APP_aws_apigateway_name, path, myInit).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        })
}

export const cognitoSignIn = (username, password) => {
    return new Promise(
        function (resolve, reject) {
            Auth.signIn(username, password).then(user => resolve(user)).catch(err => reject(err));
        }
    );
}

export const cognitoConfirmSignUp = (username, code) => {
    return new Promise(
        function (resolve, reject) {
            Auth.confirmSignUp(username, code).then(user => resolve(user)).catch(err => reject(err));
        }
    );
}

export const cognitoCompletePassword = (cognitoUser, newPassword, userAttributes) => {
    return new Promise(
        function (resolve, reject) {
            Auth.completeNewPassword(cognitoUser, newPassword, userAttributes).then(response => resolve(response)).catch(err => reject(err));
        }
    );
}

export const cognitoChangePassword = (cognitoUser, oldPassword, newPassword) => {
    return new Promise(
        function (resolve, reject) {
            Auth.changePassword(cognitoUser, oldPassword, newPassword).then(response => resolve(response)).catch(err => reject(err));
        }
    );
}

export const cognitoForgotPassword = (username) => {
    return new Promise(
        function (resolve, reject) {
            Auth.forgotPassword(username).then(response => resolve(response)).catch(err => reject(err));
        }
    );
}

export const cognitoForgotPasswordSubmit = (username, code, password) => {
    return new Promise(
        function (resolve, reject) {
            Auth.forgotPasswordSubmit(username, code, password).then(response => resolve(response)).catch(err => reject(err));
        }
    );
}

export const cognitoResendSignUp = (username) => {
    return new Promise(
        function (resolve, reject) {
            Auth.resendSignUp(username).then(response => resolve(response)).catch(err => reject(err));
        }
    );
}

export const cognitoCurrentUser = () => {
    return new Promise(
        function (resolve, reject) {
            Auth.currentAuthenticatedUser().then(response => resolve(response)).catch(err => reject(err));
        }
    );
}

export const cognitoInfoCurrentUser = () => {
    return new Promise(
        function (resolve, reject) {
            Auth.currentUserInfo().then(response => resolve(response)).catch(err => reject(err));
        }
    )
}

export const cognitoUpdateUserAttributes = (cognitoUser, userAttributes) => {
    return new Promise(
        function (resolve, reject) {
            Auth.updateUserAttributes(cognitoUser, userAttributes).then(response => resolve(response)).catch(err => reject(err));
        }
    );
}

export const cognitoLogOut = () => {
    return new Promise(
        function (resolve, reject) {
            Auth.signOut()
                .then(data => resolve(data))
                .catch(err => reject(err));
        }
    );
}

/**
 * Logout 
 */
export function logOut() {
    Auth.signOut().then(
        data => {
            ls.clear();
            window.location.href = '/'
        }
    ).catch(
        err => {
            ls.clear();
            window.location.href = '/'
        }
    );
}