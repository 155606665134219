import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import '../../styles/components/vendors/slick-carousel/slick-carousel.css';
import '../../styles/components/vendors/slick-carousel/slick.css';
import '../../styles/components/vendors/slick-carousel/slick-theme.css';
import '../../styles/components/vendors/react-animated-slider/react-animated-slider.css';
import { Grid } from '@material-ui/core';
import RelevanteCard from '../CardPaper/RelevanteCard';
import { fetchCall } from '../../service/service_base';

const styles = theme => ({
  wraper: {
    padding: '2rem 1rem 2rem 3rem',
    backgroundColor: theme.palette.secondary.main,
  },
  relative: {
    position: 'relative',
  },
  textRotate: {
    color: 'white',
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: -85,
    fontSize: 24,
    transfromOrigin: 'center',
    transform: 'rotate(-90deg)'
  },

});


class SliderTap extends React.Component {
  state = {
    relevantes: []
  }
  componentDidMount() {
    if (!this.props.isSearching) {
      this._load();
    } else {
      if (this.props.section) {
        this.setState(
          {
            relevantes: []
          }, () => {
            this.setState(
              {
                relevantes: this.props.section.content
              }
            )
          }
        )
      }
    }
  }

  _load = () => {
    fetchCall("POST", "/synthesis/getcontentpublish", {
      sectionId: this.props.section.sec_id,
      menuId: this.props.idMenuSelected
    }).then(response => {
      console.log()
      this.setState({ relevantes: response.data });
    }).catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isSearching && nextProps.section) {
      this.setState({
        relevantes: nextProps.section.content
      });
    }
  }

  render() {
    const { classes, isVisible} = this.props;
    if(!isVisible){
      return <div></div>;
    }
    return (
      <div id={this.props.section.sec_name ? this.props.section.sec_name.toString().trim() : ''} style={{ paddingTop: 25 }}>
        <div style={{ backgroundColor: this.props.section.sec_color }} className={classes.wraper}>
          <div className={classNames(classes.relative, "container")}>
            <div className={classes.textRotate}>LO RELEVANTE</div>
            <Grid container spacing={24}>
              {this.state.relevantes.map((item, key) => {
                return <Grid item lg={4} md={4} sm={6} xs={12} key={key}>
                  <RelevanteCard
                    title={item.cont_name}
                    description={item.cont_description}
                    notes={item.cont_notas}
                    attachment={item.cont_attachment}
                    publish_date={item.publish_date}
                  />
                </Grid>
              })}
            </Grid>
          </div>
        </div></div>
    );
  }
}

SliderTap.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SliderTap);