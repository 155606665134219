import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import styles from "./cardStyle-jss";

class EventCard extends React.Component {
  render() {
    const {
      classes,
      title,
      description,
      day,
      month,
      publish_date,
      noDivider
    } = this.props;
    return (
      <Card className={classNames(
        classes.cardEvent, 
        classes.dividerLineResponsive,
        noDivider && classes.dividerLineHidden,
      )}>
        <div className={classNames(classes.headerEvent)}>
            <Typography  component="p" className={classes.stylesTextEvent}>{day} | {month}</Typography>
            <Typography component="i" className={classes.eventDescription}>
              {title}
            </Typography>
        </div>
        <CardContent className={classes.contenidoMedia}>
          <Typography className={classes.title}>
            <span
                className={classes.textDescriptionWrap8}
                dangerouslySetInnerHTML={{ __html: `${description}` }}
            />
          </Typography>
          {publish_date && (
            <Typography component="small" className={classes.date}>
              {publish_date}
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(EventCard);
